import React from 'react'
import { Box, Grid, Typography } from "@mui/material";
import mrf from "../../../../assets/our-services-image/header-image/myredfolder-header.png";
import macpic from "../../../../assets/MRF_macpic.png"
import androidplaystore from "../../../../assets/playstore-android.png"
import appleplaystore from "../../../../assets/appleplaystore.png"
import centerImage from "../../../../assets/our-services-images/MRF-plans-image.png"
import play_button from "../../../../assets/play_button.png"
import { styled } from "@mui/material/styles";
import '../../OurService/styles/services.css'

const MyRedFolderPlan = () => {

  

  return (
    <div>
    <Box>
    <Grid container sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)",  }}>
      <div className='plan-header-container'>
        <Grid container item xs={12} md={12} lg={12} >
        
          <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>

            <Typography className='primary-font-bold7'> MYREDFOLDER© PLAN</Typography>

          </Grid>
          <Grid item xs={6} md={6} lg={6}  className='plan-header-img' >
            <img src={mrf} alt="complex" style={{width:"100%",height:"100%"}} />
          </Grid>
        </Grid>
        </div>
      </Grid>
        <Grid container>
        <div style={{ width: '100%',}}  className='plan-top-container'>
          <Typography className='primary-font-bold13'>MYREDFOLDER© Plan</Typography>
        </div>
        </Grid>
      <div className='plan-header-container'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <p className='primary-font-normal-11 mrf_contant'>
          Meet the Johnsons, a typical family of four living in a peaceful suburban neighborhood. Life was generally predictable until one evening when an unexpected storm began to rage outside. The rain was relentless, the winds were howling, and before they knew it, their neighborhood was flooding rapidly. </p>
          <p className='primary-font-normal-11 mrf_contant'>
          As panic set in, the Johnsons rushed to gather their important documents, but chaos ensued. Valuable time was lost searching for passports, insurance policies, and medical records. The power went out, and they found themselves in the dark, overwhelmed, and disconnected from the outside world. They wished they had known about MYREDFOLDER® sooner.  </p>
          <p className='primary-font-normal-11 mrf_contant'> 
          Fast forward to a few months later, and the Johnsons had embraced MYREDFOLDER® as an essential part of their family's emergency preparedness plan. With this innovative tool, they experienced a transformation in how they navigated life's uncertainties. </p>
          <p className='primary-font-normal-11 mrf_contant'> Customized Emergency Plans: </p>
          <p className='primary-font-normal-11 mrf_contant'>Sarah Johnson, who has a medical condition, had created a tailored emergency plan within MYREDFOLDER® that included her specific needs. It was a relief knowing that her family was prepared to handle any medical emergency.</p>
          <p className='primary-font-bold-plan mrf_contant'> Real-time Alerts:</p>
          <p className='primary-font-normal-11 mrf_contant' > One day, as they were enjoying a sunny afternoon, they received a MYREDFOLDER® alert about an approaching storm. With this early warning, they had ample time to secure their home and gather essential supplies.</p>
          <p className='primary-font-bold-plan mrf_contant'>Communication Hub:</p>
          <p className='primary-font-normal-11 mrf_contant' > During the storm, when the power went out, they used the alternative communication protocols within MYREDFOLDER® to stay connected with their neighbors and family members, ensuring everyone's safety.</p>
          <p className='primary-font-bold-plan mrf_contant'> Resource Repository:</p>
          <p className='primary-font-normal-11 mrf_contant' > The app provided up-to-date information on evacuation routes and nearby shelters, giving them confidence in their ability to find safety quickly.</p>
          <p className='primary-font-bold-plan mrf_contant'> Integration with Emergency Services:</p>
          <p className='primary-font-normal-11 mrf_contant' > When Sarah had a minor medical issue, they used MYREDFOLDER® to locate the nearest medical facilities and access first-aid guidelines, ensuring she received prompt care.</p>
          <p className='primary-font-bold-plan mrf_contant'> User Control:</p>
          <p className='primary-font-normal-11 mrf_contant' >They designated trusted family members who could access their MYREDFOLDER® in case of emergencies. This ensured that help was always within reach.</p>
          <p className='primary-font-bold-plan mrf_contant'>Inventory Management:</p>
          <p className='primary-font-normal-11 mrf_contant' > Their home inventory, stored in MYREDFOLDER®, proved invaluable when dealing with an insurance claim after some of their possessions were damaged.</p>
          <p className='primary-font-bold-plan mrf_contant'> Legacy Preservation: </p>
          <p className='primary-font-normal-11 mrf_contant' >The Johnsons also used MYREDFOLDER® to digitally store cherished family photos and important heirlooms, ensuring that these precious items were preserved even if physical copies were lost or damaged. </p>
          <p className='primary-font-normal-11 mrf_contant'>Today, the Johnsons enjoy peace of mind knowing that MYREDFOLDER® has them covered in case of emergencies. They can't imagine facing uncertain situations without this invaluable tool. With MYREDFOLDER®, they've turned the unpredictable into the manageable, and their family is stronger and better prepared for whatever the future may bring.</p>
         
            <Grid  sx={{display:'flex', justifyContent:'center', borderRadius:'10px'}}>
              <img src={centerImage} alt="center-image"  className='center-image'style={{width:'59%',borderRadius:"10px"}}/>
            </Grid>

          <p className='primary-font-bold5 mrf_contant'> Features:</p>
          
          <p className='primary-font-bold5 mrf_contant'>Customized Emergency Plans:<span className='primary-font-normal-11 mrf_contant'> Tailor emergency plans to your family's specific needs, including medical conditions and special requirements. 
</span>  </p> 

          <p className='primary-font-bold5 mrf_contant'> Real-time Alerts:<span className='primary-font-normal-11 mrf_contant'> Receive immediate alerts and updates from official sources about weather conditions, disaster warnings, and critical information. </span> </p>
          <p className='primary-font-bold5 mrf_contant'>Communication Hub: <span className='primary-font-normal-11 mrf_contant'> Access alternative communication protocols and emergency contact lists to stay in touch during crises.
</span> </p>
          <p className='primary-font-bold5 mrf_contant'> Resource Repository: <span className='primary-font-normal-11 mrf_contant'> Find up-to-date information on evacuation routes, nearby shelters, and emergency services.</span> </p>
          <p className='primary-font-bold5 mrf_contant'>Integration with Emergency Services: <span className='primary-font-normal-11 mrf_contant'> Call for assistance directly from the app, locate the nearest medical facilities, and access first-aid guidelines.
</span> </p>
          <p className='primary-font-bold5 mrf_contant'>  User Control:<span className='primary-font-normal-11 mrf_contant'> Users have full control over their data and can manage access permissions. They can designate trusted family members or advisors who can access their MYREDFOLDER® in case of emergencies.</span> </p>
          <p className='primary-font-bold5 mrf_contant'>Accessibility from Anywhere: <span className='primary-font-normal-11 mrf_contant'> Users can access their MYREDFOLDER® accounts from any internet-connected device, providing them with immediate access to their critical documents and information, regardless of their location.</span> </p>
          <p className='primary-font-bold5 mrf_contant'> Benefits:</p>
          <p className='primary-font-bold5 mrf_contant'> Cloud Storage: <span className='primary-font-normal-11 mrf_contant'> Safeguard your home inventory, including purchase prices and replacement values, making insurance claims smoother. </span> </p>
          <p className='primary-font-bold5 mrf_contant'>Document Storage: <span className='primary-font-normal-11 mrf_contant'> Be prepared and confident in handling emergencies with a personalized plan and access to critical information.</span> </p>
          <p className='primary-font-bold5 mrf_contant'> Inventory Management: <span className='primary-font-normal-11 mrf_contant'> The platform also provides tools for users to create and maintain an inventory of their home's contents. This is invaluable when dealing with insurance claims or replacing lost or damaged items.</span> </p>
          <p className='primary-font-bold5 mrf_contant'>  Efficient Communication:<span className='primary-font-normal-11 mrf_contant'> Stay connected with family members and emergency services, ensuring effective coordination and support.
</span> </p>
           <p className='primary-font-bold5 mrf_contant'>  Resource Accessibility: <span className='primary-font-normal-11 mrf_contant'> Access vital information and resources, such as evacuation routes and nearby shelters, enhancing your family's safety</span> </p>
           <p className='primary-font-bold5 mrf_contant'> Quick Emergency Response: <span className='primary-font-normal-11 mrf_contant'> Streamline your response to emergencies with immediate alerts and a well-organized plan of action. </span> </p>
           <p className='primary-font-bold5 mrf_contant'> Legacy Preservation:  <span className='primary-font-normal-11 mrf_contant'> MYREDFOLDER® offers a unique feature for legacy preservation. Users can store important family documents, photographs, and heirlooms digitally, ensuring that these precious items are preserved even if physical copies are lost or damaged.</span> </p>
           <p className='primary-font-bold5 mrf_contant'> Data Security:  <span className='primary-font-normal-11 mrf_contant'> Enjoy the peace of mind that comes with knowing your sensitive information is encrypted and protected.</span> </p>
           <p className='primary-font-bold5 mrf_contant'> Comprehensive Preparedness:  <span className='primary-font-normal-11 mrf_contant'> With the MYREDFOLDER® Plan, you have a comprehensive tool to ensure your family's readiness and resilience in times of crisis. 
</span> </p>
           <p className='primary-font-bold5 mrf_contant'>Customization:  <span className='primary-font-normal-11 mrf_contant'> Tailor your emergency plans and information to your family's unique circumstances, ensuring a personalized and effective response. 
</span> </p>
           <p className='primary-font-bold5 mrf_contant'> Regular Updates: <span className='primary-font-normal-11 mrf_contant'> Stay current with the latest emergency protocols and features to continuously enhance your family's preparedness.</span> </p>
          <p className='primary-font-normal-11 mrf_contant'> The MYREDFOLDER® Plan combines essential features and benefits to provide your family with the tools and knowledge needed to effectively navigate emergencies and ensure their safety and well-being.</p>

         
          {/* <Grid
        container
        sx={{ py: "50px" }}
        className="individual-container"
       
      >
        <Grid></Grid>
        <div className='bigsize'>
        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        
        <div class="video-container bigsize" >
        <h2 style = {{textAlign:"center"}}>MYREDFOLDER Importance</h2>
                   <Img src={macpic}
            alt="Image"
            class="background-image"
            style={{
              background:
                "transparent url('assets/getInTouch_pic.png') 0% 0% no-repeat padding-box",
              opacity: 1}}>
          </Img>
          <iframe src="https://player.vimeo.com/video/270231514?h=8d37473ca6&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="video"></iframe>
          </div>
          <div class="video-container bigsize">
          <h2 style = {{textAlign:"center"}}>MYREDFOLDER | FAMILY</h2>
                   <Img src={macpic}
            alt="Image"
            class="background-image"
            style={{
              background:
                "transparent url('assets/getInTouch_pic.png') 0% 0% no-repeat padding-box",
              opacity: 1}}>
          </Img>
          <iframe src="https://player.vimeo.com/video/136259404?h=0719844c70&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="video"></iframe>
          </div>
          <div class="video-container">
          <h2 style = {{textAlign:"center"}}>Now My Family is Prepared!</h2>
                   <Img src={macpic}
            alt="Image"
            class="background-image"
            style={{
              background:
                "transparent url('assets/getInTouch_pic.png') 0% 0% no-repeat padding-box",
              opacity: 1}}>
          </Img>
          <iframe src="https://player.vimeo.com/video/112245946?h=05eb48f86d&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="video"></iframe>
          </div>
          </Grid>
          </div>
          
<div className='smallsize'>
<Grid container justifyContent="center" alignItems="center">
          <div class="video-container" >
          <h2 style = {{textAlign:"center"}}>MYREDFOLDER Importance</h2>
                   <Img src={macpic}
            alt="Image"
            class="background-image"
            style={{
              background:
                "transparent url('assets/getInTouch_pic.png') 0% 0% no-repeat padding-box",
              opacity: 1}}>
          </Img>
          <iframe src="https://player.vimeo.com/video/270231514?h=8d37473ca6&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="videos"></iframe>
          </div>
          <div class="video-container">
          <h2 style = {{textAlign:"center"}}>MYREDFOLDER | FAMILY</h2>
                   <Img src={macpic}
            alt="Image"
            class="background-image"
            style={{
              background:
                "transparent url('assets/getInTouch_pic.png') 0% 0% no-repeat padding-box",
              opacity: 1}}>
          </Img>
          <iframe src="https://player.vimeo.com/video/136259404?h=0719844c70&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="videos"></iframe>
          </div>
          <div class="video-container">
          <h2 style = {{textAlign:"center"}}>Now My Family is Prepared!</h2>
                   <Img src={macpic}
            alt="Image"
            class="background-image"
            style={{
              background:
                "transparent url('assets/getInTouch_pic.png') 0% 0% no-repeat padding-box",
              opacity: 1}}>
          </Img>
          <iframe src="https://player.vimeo.com/video/112245946?h=05eb48f86d&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="videos"></iframe>
          </div>

        </Grid>
        </div>
        <Grid ></Grid>
      </Grid>  */}
               {/* <p className='primary-font-normal-11 mrf_contant'>
          This extraordinary app includes an intuitive user interface, offering an interactive experience that guides families through the creation of personalized emergency plans. The MYREDFOLDER® effortlessly integrates crucial elements such as emergency contacts, evacuation routes, designated meeting points, communication protocols, and essential supplies. Its advanced communication features ensure seamless connectivity during emergencies, enabling families to stay connected with loved ones and receive vital updates.
          </p>
          <p className='primary-font-normal-11 mrf_contant'>
          The MYREDFOLDER® APP transcends traditional emergency planning by offering a sophisticated emergency interactive communication tool. The app's comprehensive resources library encompasses a vast array of educational materials, instructional videos, and step-by-step guides, delivering unparalleled expertise directly to users' fingertips. 
          </p>
          <p className='primary-font-normal-11 mrf_contant'>
          Furthermore, MYREDFOLDER® goes beyond emergency planning and extends its features to facilitate efficient response and recovery efforts. The app seamlessly integrates with local, state, and federal emergency management systems, ensuring swift access to assistance and resources when needed. Its project management capabilities enable families to track recovery progress, document damages, and access valuable post-disaster support programs and funding opportunities.
          </p>
          <p className='primary-font-normal-11 mrf_contant'>
          In summary, the MYREDFOLDER® APP is a paradigm shift in emergency preparedness, response, and recovery. With its advanced AI technology, blockchain level security, personalized guidance, real-time data analysis, interactive features, and comprehensive resources, families gain an unparalleled level of self-reliance and resilience. This groundbreaking app surpasses anything currently available on the market, delivering the pinnacle of emergency management knowledge and capabilities directly to families' hands. With the MYREDFOLDER®, families can confidently navigate any emergency or disaster, ensuring their safety, well-being, and long-term recovery.
          </p> */}
        </Grid>
        </Grid>
         </div>
         <Grid sx={{background:"#FAFAFB"}}  >
         <div className='plan-header-container'>
        <Grid container className='bottom-container' >
        {/* <Grid container  item xs={12} md={12} lg={12}  > */}
          <Grid item xs={12} md={7}  sm={7} >
            <Typography className='primary-font-medium-7 bottom-text'  sx={{ width: "100%", color: "#2B303E",letterSpacing:'0px',}}>Get the App for FREE with your Premium MYREDFOLDER® Plan</Typography>
          </Grid>
          <Grid container spacing={1} item xs={12} md={5} sm={5} className="appstore-container" sx={{ marginY: "auto" }} >
            <Grid item xs={6} md={6} sm={6} >
              <img className='playstore_icons' src={appleplaystore} alt="text" />
            </Grid>
            <Grid item  className='icon-img' xs={6} md={6} sm={6} >
              <img className='playstore_icons' src={androidplaystore} alt="text" sx={{py:'10px'}} />
            </Grid>
          </Grid>
        {/* </Grid> */}
        </Grid>
        </div>
        </Grid>
        </Box>
    </div>
  )
}

export default MyRedFolderPlan;