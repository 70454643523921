// import React, { useState } from "react";

import React, { useEffect, useRef, useState } from 'react';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import AboutUsImage from "../../../../assets/about-us.png";
// import playnextred from "../../../../assets/icons/play-next-red-icon.svg"
// import playprevred from "../../../../assets/icons/play-prev-red-icon.svg"
// import playnextwhite from "../../../../assets/icons/play-next-white-icon.svg"
// import playprevwhite from "../../../../assets/icons/play-prev-white-icon.svg"
import { SkipPrevious, SkipNext, Home } from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import "./AboutUs.css";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const VideoPlayer = ({ videoUrls }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [autoplay, setAutoplay] = useState("");
  const [title, setTitle] = useState("")

  useEffect(() => {
    if (currentVideoIndex == 0) {
      setTitle("MYREDFOLDER-WEBSITE")
      setAutoplay(false);
    }
    else if (currentVideoIndex == 1) {
      setAutoplay(true);
      setTitle("MYREDFOLDER-APP")
    } else if (currentVideoIndex == 2) {
      setTitle("ACTIVESHOOTER")
      setAutoplay(true);
    }
  }, [currentVideoIndex]);

  const NextVideo = () => {
    setCurrentVideoIndex((currentVideoIndex + 1) % videoUrls.length);
  };
  const prevVideo = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex((currentVideoIndex - 1) % videoUrls.length);
    }
  };
  const HomeVideo = () => {
    setCurrentVideoIndex(0)
  }


  return (
    <div style={{borderRadius:'10px !important'}}>
      <div className="Aboutus intro-Video-container " style={{borderRadius:'10px !important'}} >
        <ReactPlayer
          url={videoUrls[currentVideoIndex]}
          controls
          className="aboutus  intro-Video"
          playing={autoplay}
          onEnded={NextVideo}
          playsinline
          sx={{borderRadius:'10px !important'}}
        />
      </div>
      {/* <div style={{ display: "flex", justifyContent: "space-evenly", background: "red", height: "40px", marginTop: "-0px", }}>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
          <div onClick={HomeVideo} style={{ display: "flex", alignItems: "center" }}>
            <Loop sx={{ color: "white !important", background: "red" }} />
          </div>
          <Typography className='primary-font-medium-8' sx={{ color: "white", paddingLeft: "30px" }}> {title}</Typography>
       
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}  >
         
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor:"pointer" }} onClick={prevVideo}>
            <SkipPrevious sx={{ color: "white !important", background: "red" ,cursor:"pointer"  }} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
            <Typography className="primary-font-normal-12" sx={{ color: "white", mx: "20px" }}>   {currentVideoIndex + 1}/{videoUrls.length}</Typography>
          </div>
    

          <div onClick={NextVideo} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" ,cursor:"pointer" }}>
            <SkipNext sx={{ color: "white !important", background: "red",cursor:"pointer" , }} />
          </div>
          <div onClick={HomeVideo} style={{ marginLeft:"10px", display: "flex", alignItems: "center" }}>
            <ReplayIcon  sx={{ color: "white !important", background: "red" }} />
          </div>

        </div>
      </div> */}
      <br />

    </div>
  );
};







const AboutUs = () => {
  const [showContent, setShowContent] = useState(false);
  const  [videolink ,setVideolink] =useState([

    "https://player.vimeo.com/video/923207845?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    "https://player.vimeo.com/video/922424005?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    "https://player.vimeo.com/video/922426701?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  ])
  const { t, i18n } = useTranslation('common');
  useEffect(() => {
    if (i18n.language === 'en') {
    setVideolink([ "https://player.vimeo.com/video/923207845?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/922424005?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/922426701?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"])
    }
    else if(i18n.language === "sp"){
      setVideolink([  "https://player.vimeo.com/video/929799008?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" ,
      "https://player.vimeo.com/video/929798550?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      "https://player.vimeo.com/video/929789047?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"])
    }
    else if(i18n.language === "fr"){
      setVideolink("")
    }
  }, [i18n.language]);
  const videoUrls = [

    "https://player.vimeo.com/video/923207845?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    "https://player.vimeo.com/video/922424005?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    "https://player.vimeo.com/video/922426701?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  ]
  const handleReadMore = () => {
    setShowContent(true);
  };
  return (
    <div className="container-home-aboutus">
      <Grid container className="topcontainer-home-aboutus" >
        {/* <Grid item xs={2} md={1} lg={1}></Grid> */}
        <Grid item xs={12} >

          <div className="readmore-leftalign">
            <Typography className="AboutUS primary-font-bold-heading">About Us</Typography>
            <Typography className="subheading1_content primary-font-normal-18 ">
              The National Emergency Planning and Training Association (NEPTA) is an
              association dedicated to the systematic process of preparation and
              recovery in the event of a disaster, emergency, or unforeseen
              occurrence. With over a combined 65 years of emergency
            </Typography>
            <a className="subheading1_Readmore_link" href="/aboutUs/our-history"><b>Read More </b></a>

          </div>
          {/* <b className="subheading1_Readmore_link ">Read More</b> */}
          <div style={{marginTop:'20px'}}>
            <Grid container spacing={3} >
              <Grid item lg={6} xs={12} md={6} >
                <Typography className="subheading1  primary-font-bold20 ">Our Values</Typography>
                <Typography className="subheading1_content primary-font-normal-18 ">
                  We value the lives, welfare, and property of our communities, our
                  fellowman, our families, and ourselves. We proactively help
                  protect families
                </Typography>
                <a className="subheading1_Readmore_link" href="/aboutUs/our-values"><b>Read More </b></a>
                {/* <b className="subheading1_Readmore_link  ">Read More</b> */}
                <Typography className="subheading1 primary-font-bold20 ">Our Mission</Typography>
                <Typography className="subheading1_content primary-font-normal-18 ">
                  As the pioneer in preparedness and recovery for families,
                  communities, and businesses, we make it our primary goal to
                  fulfill the unmet needs of
                </Typography>
                <a className="subheading1_Readmore_link" href="/aboutUs/our-values"><b>Read More </b></a>
                {/* <b className="subheading1_Readmore_link  ">Read More</b> */}
              </Grid>
              <Grid item
                lg={6} xs={12} md={6} sx={{ justifyContent: "end" }}
              >
                {/* <div className="Aboutus intro-Video-container " > 
                <iframe id="iFrame" loading="lazy"   className="aboutus  intro-Video"  src= "https://player.vimeo.com/video/922424005?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" ></iframe> */}
                {/* <iframe id="iFrame" loading="lazy"   className="aboutus  intro-Video"  src="https://player.vimeo.com/video/922426701?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0"> </iframe> */}
                {/* <iframe id="iFrame" loading="lazy"   className="aboutus  intro-Video" src="https://player.vimeo.com/video/889049214?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
                {/* <iframe src="https://player.vimeo.com/video/513378018?title=1&amp;transparent=1&amp;playsinline=1&amp;muted=0&amp;controls=1&amp;loop=false&amp;autopause=true&amp;autoplay=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe> */}

                {/* </div> */}
                <VideoPlayer videoUrls={videolink} />

              </Grid>

            </Grid>

          </div>

        </Grid>
        {/* <Grid item xs={2} md={1} lg={1}></Grid> */}
      </Grid>
    </div>
  );
};

export default AboutUs;
