import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import headerImage from "../../../../assets/knowledge-base-assets/knowledge-base-header-images/blog.png";
import { Button, Grid, TextField, Divider, useMediaQuery } from "@mui/material";
import { data, CommentsData, Months } from '../data/BlogData';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { hover } from '@testing-library/user-event/dist/hover';
import BlogsPagination from './BlogsPagination';
import "../styles/blogs.css"

const Blog = () => {

  const [goBackState, setGoBackState] = useState(false);
  const [cardData, setCardData] = useState();
  const [filteredData, setFilteredData] = useState(data);
  const [searchText, setSearchText] = useState();
  const [expanded, setExpanded] = useState('');
  const [accordianData, setAccordianData] = useState();
  const [currentAndNextMonth, setCurrentAndNextMonth] = useState();
  const initialized = useRef(false);
  const [totalPostValue, setTotalPostValue] = useState(data.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(5);

  let isMobile = useMediaQuery('(max-width: 767px)');
  let isTablet = useMediaQuery('(min-width: 768px) and (max-width:999px)')

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;

  let headings = [];
  let currentmonth = [];

  useEffect(() => {

    if (!initialized.current) {
      initialized.current = true

      const currentDate = new Date();
      const currentMonthIndex = currentDate.getMonth();
      const nextMonthIndex = (currentMonthIndex + 1) % 12;
      const afterMonthIndex = (currentMonthIndex + 2) % 12;

      currentmonth.push(Months[currentMonthIndex]);
      currentmonth.push(Months[nextMonthIndex]);
      currentmonth.push(Months[afterMonthIndex]);

      data.filter((item) => {
        if (item.updatedDate.toLowerCase().includes(Months[currentMonthIndex]?.month.toLowerCase()))
          headings.push(item)
      })
      setAccordianData(headings);
      setCurrentAndNextMonth(currentmonth);
      setExpanded(Months[currentMonthIndex].panel);
      setFilteredData(data)
      setCurrentPage(1);
    }
  }, []);



  const handleChange = (panel, month) => (event, newExpanded) => {
   
    setExpanded(false);
    setExpanded(newExpanded ? panel : false);
    data.filter((item) => {
      if (item.updatedDate.toLowerCase().includes(month.toLowerCase()))
        headings.push(item)
    })
    setAccordianData(headings);

  };


  const DetailedCard = () => {
    return <div>
      <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between" }} rowGap={'70px'}>
        <Grid sx={{ display: "flex", flexDirection: 'column' }}>
        <div className='detailcard'>
            <Grid  className='blogs-detailed-image'>
              <img src={cardData?.imagesrc} style={{ borderRadius: "30px" }} width={'100%'} height={'100%'} />
            </Grid>
          </div>

          <Grid sx={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
            <Grid container xs={8.5} lg={8.5} md={8.5} sx={{ marginTop: "-100px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Card sx={{ borderRadius: '10px', boxShadow: '0px 2px 40px #0000001A' }}>
                <Grid padding={'30px 30px 0px 20px'}>
                  <Typography className='primary-font-bold14'>
                    {cardData?.heading}
                  </Typography>
                </Grid>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between', padding: "10px 15px 0px 20px" }}>
                  <Grid className='primary-font-normal-1' sx={{ color: "#FF8620" }}> {cardData?.updatedBy} </Grid>
                  <Grid className='primary-font-normal-1' sx={{ color: "#FF8620" }}> {cardData?.updatedDate} </Grid>
                  <Grid xs={3}></Grid>
                </Grid>
                <Grid sx={{ display: 'flex', padding: "10px 15px 20px 20px" }}>
                  <Grid> <span dangerouslySetInnerHTML={{ __html: cardData?.content ? cardData?.content : "No data" }} /> </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>

          {(cardData?.Comment) ?  < Typography sx={{ mt: '30px', mb: "20px" }} className='primary-font-bold1'> Comments </Typography> : <></>}
          <Grid>
            {
              (cardData?.Comment) ? (cardData?.Comment?.map((item, i) => {
                return <>
                  <Feedback key={i} single={item} /> </>
              })) : (<div></div>)

            }
          </Grid>

          <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
            <Grid padding={'10px'}>
              <Typography className='primary-font-bold1' >Leave Comments</Typography>
            </Grid>
            <Grid>
              <Grid width='100%' padding={'10px'}>
                <TextField sx={{ padding: '5px', width: "100%" }} variant='outlined' placeholder='First Name' style={{ fontFamily: 'Roboto', fontStyle: ' italic', border: "1px solid #0035" }} />
              </Grid>
              <Grid width='100%' padding={'10px'} >
                <TextField sx={{ padding: '5px', width: "100%" }} variant='outlined' placeholder='Email' style={{ fontFamily: 'Roboto', fontStyle: ' italic', border: "1px solid #0035" }} />
              </Grid>
            </Grid>

            <Grid width='100%' height="203px" padding={'10px'}>
              <TextField multiline rows={9} sx={{ padding: '5px', width: "100%", height: "200px" }} variant='outlined' placeholder='Message' style={{ fontFamily: 'Roboto', fontStyle: ' italic', border: "1px solid #0035" }} />
            </Grid>
            <Grid width='100%' marginTop={"10px"} borderRadius="30px" padding={'10px 0px 10px 10px'} display="flex" justifyContent="center" alignItems="center">
              <Button className='pricing-btn' type="submit" variant="outlined" sx={{ width: '200px' }}>Submit</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>


  }

  const MobileDetailedCard = () => {
    return <div  >
      <Grid sx={{ display: 'flex', flexDirection: 'column', mt: '20px' }}>
        <Grid sx={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
          <Grid container xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12} md={12} >
              <Card sx={{ borderRadius: '10px', boxShadow: '0px 2px 40px #0000001A', width: "100% !important" }}>
                <Grid item xs={12}>
                  <img src={cardData?.imagesrc} style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", width: '100%' }} />
                </Grid>
                <Grid padding={'30px 30px 0px 20px'}>
                  <Typography className='primary-font-bold-blogs'>
                    {cardData?.heading}
                  </Typography>
                </Grid>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between', padding: "10px 15px 0px 20px" }}>
                  <Grid className='primary-font-normal-1' sx={{ color: "#FF8620" }}> {cardData?.updatedBy} </Grid>
                  <Grid className='primary-font-normal-1' sx={{ color: "#FF8620" }}> {cardData?.updatedDate} </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', padding: "10px 15px 20px 20px" }}>
                  <Grid sx={{ width: '100%' }}> <span dangerouslySetInnerHTML={{ __html: cardData?.content ? cardData?.content : "No data" }} /> </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column', mt: '20px' }}>

          {(cardData?.Comment) ? <Typography sx={{ mt: '20px', mb: '10px' }} className='primary-font-bold1'> Comments </Typography> : <></>}
          <Grid>
            {
              (cardData?.Comment) ? (cardData?.Comment?.map((item, i) => {
                return <>
                  <MobileFeedback key={i} single={item} /> </>
              })) : (<div></div>)

            }
          </Grid>

          <Grid sx={{ width: '100%' }} padding={'0px 0px 40px 0px'}>
            <Grid padding={'10px 10px 10px 0px'}>
              <Typography className='primary-font-bold1' >Leave Comments</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Grid width='100%' padding={'10px 10px 10px 0px'}>
                <TextField sx={{ width: "100%" }} variant='outlined' placeholder='First Name' style={{ fontFamily: 'Roboto', fontStyle: ' italic', border: '1px solid #0035' }} />
              </Grid>
              <Grid width='100%' padding={'10px 10px 10px 0px'} >
                <TextField sx={{ width: "100%" }} variant='outlined' placeholder='Email' style={{ fontFamily: 'Roboto', fontStyle: ' italic', border: '1px solid #0035' }} />
              </Grid>
            </Grid>

            <Grid width='100%' height="80px" padding={'10px 10px 10px 0px'}>
              <TextField multiline rows={5} sx={{ width: "100%", height: "160px" }} variant='outlined' placeholder='Message' style={{ fontFamily: 'Roboto', fontStyle: ' italic', border: '1px solid #0035' }} />
            </Grid>
          </Grid>
          <Grid sx={{ display: 'flex', justifyContent: 'center' }} width='100%' borderRadius="30px" marginTop='60px' >
            <Button className='pricing-btn' type="submit" variant="outlined" sx={{ width: '120px' }}>Submit</Button>
          </Grid>
        </Grid>
      </Grid>
    </div>


  }


  const MobileFeedback = ({ key, single }) => {

    return <div>
      <Grid sx={{ display: 'flex', flexDirection: 'column', rowGap: '50px' }}>
        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid sx={{ float: "left" }}>
            <Grid sx={{ backgroundColor: '#D8D8D8', borderRadius: '50%', height: '50px', width: '50px' }}></Grid>
          </Grid>
          <Grid sx={{ paddingLeft: '30px' }}>
            <Grid> <Typography className='primary-font-normal-text'> {single.message} </Typography></Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'column', paddingTop: '25px' }} >
              <Typography className='primary-font-bold1'>{single.name} </Typography>
              <Typography className='primary-font-normal-1' sx={{ color: "#FF8620" }}> {single.date} </Typography>
              <Typography className='primary-font-normal-1' sx={{ color: '#70C2CB' }}> Reply</Typography>
            </Grid>
          </Grid>
        </Grid>
        {
          (single?.reply) ? (<div>
            <Grid sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '35px' }}>
              <Grid sx={{ float: "left" }}>
                <Grid sx={{ backgroundColor: '#D8D8D8', borderRadius: '50%', height: '50px', width: '50px' }}></Grid>
              </Grid>
              <Grid sx={{ paddingLeft: '30px' }}>
                <Grid> <Typography className='primary-font-normal-text'> {single.reply[0].message} </Typography></Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'column', paddingTop: '25px' }}>
                  <Typography className='primary-font-bold1'> {single.reply[0].name} </Typography>
                  <Typography className='primary-font-normal-1' sx={{ color: "#FF8620" }}> {single.reply[0].date} </Typography>
                  <Typography className='primary-font-normal-1' sx={{ color: '#70C2CB' }}> Reply</Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>) : (<div></div>)
        }

      </Grid>
    </div>
  }

  const Feedback = ({ key, single }) => {

    return <div>
      <Grid sx={{ display: 'flex', flexDirection: 'column', rowGap: '50px' }}>
        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid sx={{ float: "left" }}>
            <Grid sx={{ backgroundColor: '#D8D8D8', borderRadius: '50%', height: '95px', width: '95px' }}></Grid>
          </Grid>
          <Grid sx={{ paddingLeft: '60px' }}>
            <Grid> <Typography className='primary-font-normal-text'> {single.message} </Typography></Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'column', paddingTop: '25px' }} >
              <Typography className='primary-font-bold1'>{single.name} </Typography>
              <Typography className='primary-font-normal-1' sx={{ color: "#FF8620" }}> {single.date} </Typography>
              <Typography className='primary-font-normal-1' sx={{ color: '#70C2CB' }}> Reply</Typography>
            </Grid>
          </Grid>
        </Grid>
        {
          (single?.reply) ? (<div>
            <Grid sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '70px' }}>
              <Grid sx={{ float: "left" }}>
                <Grid sx={{ backgroundColor: '#D8D8D8', borderRadius: '50%', height: '95px', width: '95px' }}></Grid>
              </Grid>
              <Grid sx={{ paddingLeft: '60px' }}>
                <Grid> <Typography className='primary-font-normal-text'> {single.reply[0].message} </Typography></Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'column', paddingTop: '25px' }}>
                  <Typography className='primary-font-bold1'> {single.reply[0].name} </Typography>
                  <Typography className='primary-font-normal-1' sx={{ color: "#FF8620" }}> {single.reply[0].date} </Typography>
                  <Typography className='primary-font-normal-1' sx={{ color: '#70C2CB' }}> Reply</Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>) : (<div></div>)
        }

      </Grid>
    </div>
  }

  const TabletItem = ({ key, singleCard }) => {

    return <Grid>
      <div onClick={() => { setCardData(singleCard); setGoBackState(true) }}>
        <div className='singlecard mobile-blogs-card '  >
          <Grid sx={{ display: "flex", flexDirection: 'column', marginBottom: '30px', marginTop: '20px' }}>
            <Card sx={{ height: '500px', borderRadius: "10px", boxShadow: "0px 2px 40px #0000001A", border: '1px solid #000' }}>
              <Grid sx={{ width: '385px', height: '215px' }}  >
                <img src={singleCard.imagesrc} style={{ borderTopLeftRadius: "10px", borderTopRightRadius: '10px', width: '385px', height: '215px' }} />
              </Grid>
              <Grid sx={{ padding: "20px" }}>
                <Typography className='primary-font-blogs-tablet'   >
                  {singleCard.heading}
                </Typography>
                <Grid sx={{ display: 'flex', justifyContent: "space-between", marginTop: "8px" }}>
                  <Typography className='primary-font-normal-4' sx={{ color: "#FF8620" }}>
                    {singleCard.updatedBy}
                  </Typography>
                  <Typography className='primary-font-normal-4' sx={{ color: "#FF8620" }}>
                    {singleCard.updatedDate}
                  </Typography>
                </Grid>
                <Grid sx={{ marginTop: "18px" }}>
                  <Typography className='primary-font-normal-15' sx={{ color: "#000" }}>
                    {singleCard.Comments}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </div>
      </div>
    </Grid>
  };

  const MobileItem = ({ key, singleCard }) => {

    return <div onClick={() => { setCardData(singleCard); setGoBackState(true) }}>
      <div className='singlecard mobile-blogs-card '  >
        <Grid container xs={12} md={12} sx={{ display: "flex", flexDirection: 'column', marginBottom: '30px', marginTop: '20px' }}>
          <Grid item xs={12}>
            <Card sx={{ borderRadius: "10px", boxShadow: "0px 2px 40px #0000001A", border: '1px solid #000' }}>
              <Grid item xs={12} >
                <img src={singleCard.imagesrc} style={{ borderTopLeftRadius: "10px", borderTopRightRadius: '10px' }} width="100%" />
              </Grid>
              <Grid sx={{ padding: "30px 70px 29px 30px" }}>
                <Typography className='primary-font-bold1'   >
                  {singleCard.heading}
                </Typography>
                <Grid sx={{ display: 'flex', justifyContent: "space-between", marginTop: "8px" }}>
                  <Typography className='primary-font-normal-4' sx={{ color: "#FF8620" }}>
                    {singleCard.updatedBy}
                  </Typography>
                  <Typography className='primary-font-normal-4' sx={{ color: "#FF8620" }}>
                    {singleCard.updatedDate}
                  </Typography>
                  <Grid lg={2} md={2} xs={2}></Grid>
                </Grid>
                <Grid sx={{ marginTop: "18px" }}>
                  <Typography className='primary-font-normal-15' sx={{ color: "#000" }}>
                    {singleCard.Comments}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  };

  const Item = ({ key, singleCard }) => {

    return <div onClick={() => {
      setCardData(singleCard);
      window.scrollTo(0, 0);
    }}

    >
      <div className='singlecard '>
        <Grid container lg={8} xs={8} md={8} sx={{ display: "flex", marginBottom: '50px' }}>
          <Grid lg={6} xs={6} md={6}>
            <img src={singleCard.imagesrc} style={{ borderRadius: "10px" }} width="380px" height="303px" />
          </Grid>
          <Grid lg={2} xs={2} md={2} sx={{ marginTop: "35px" }}>
            <Card sx={{ width: "400px", borderRadius: "10px", boxShadow: "0px 2px 40px #0000001A" }}>
              <Grid sx={{ padding: "30px 70px 29px 30px" }}>
                <Typography className='primary-font-bold1'   >
                  {singleCard.heading}
                </Typography>
                <Grid sx={{ display: 'flex', justifyContent: "space-between", marginTop: "8px" }}>
                  <Typography className='primary-font-normal-4' sx={{ color: "#FF8620" }}>
                    {singleCard.updatedBy}
                  </Typography>
                  <Typography className='primary-font-normal-4' sx={{ color: "#FF8620" }}>
                    {singleCard.updatedDate}
                  </Typography>
                  <Grid lg={2} md={2} xs={2}></Grid>
                </Grid>
                <Grid sx={{ marginTop: "18px" }}>
                  <Typography className='primary-font-normal-15' sx={{ color: "#000" }}>
                    {singleCard.Comments}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  };

  const Comments = ({ key, singleComment }) => {
    return <Grid>
      <Grid sx={{ height: "255px", marginTop: "30px", display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <Grid sx={{ borderRadius: '8px', height: "198px", backgroundColor: "#D8D8D8" }}>
        </Grid>

        <Grid><Typography sx={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "13px" }}>{singleComment.heading}</Typography></Grid>
        <Grid><Typography sx={{ color: '#ff8620', fontFamily: 'Roboto', fontSize: '11px' }}> {singleComment.date} </Typography></Grid>

      </Grid>
    </Grid>
  }

  const handleSearch = (event) => {

    let filteredResults = [];

    if (event.target.value.trim() === '') { filteredResults = data; setCurrentPage(1); }
    else {
      filteredResults = data.filter((item) => {
        return item.heading.toLowerCase().includes(event.target.value.toLowerCase()) || item.updatedDate.toLowerCase().includes(event.target.value.toLowerCase());
      }
      );
    }
    setFilteredData(filteredResults);
    setTotalPostValue(filteredResults.length);
  }


  return (
    <div >
      <Grid container sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)", }}>
        <div className='blogs-header'>
          <Grid container item xs={12} md={12} lg={12} >

            <Grid item xs={6} md={6} lg={6}  className={goBackState ? "blogs-top-header-back" : "blogs-top-header"}
>

              <Typography className='pricing-heading-font' >BLOG</Typography>
              <div onClick={() => {
                setGoBackState(false);
              }} style={{ display: goBackState ? "block" : "none" }}>
                {isMobile || isTablet ? <div className='blogs-back-container'>
                  <ArrowBackIcon className='blogs-back-icon' />
                  <Typography className='primary-font-bold1'>Go Back</Typography>
                </div> : <div
                  style={{ display: "flex", flexDirection: "row", marginTop: "18px", cursor: "pointer", position: 'absolute' }}>
                  <ArrowBackIcon className='blogs-back-icon' sx={{ marginTop: "1px", marginRight: "10px", color: "#000000" }} />
                  <Typography className='primary-font-bold1'>Go Back</Typography>
                </div>}


              </div>


            </Grid>
            <Grid item xs={6} md={6} lg={6} className='blogs-header-img' >
              <img src={headerImage} alt="text" style={{ width: "100%", height: "100%" }} />
            </Grid>

          </Grid>
        </div>
      </Grid>
      {/* mobile view and laptop view */}
      {isTablet ? <div>
        <Grid container xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <div className='blogs-container' style={{ width: '100%' }} >
            <Grid item xs={12} style={{ borderRadius: "10px", border: "#FF3535 1px solid", marginTop: '20px' }}>
              <TextField value={searchText} onChange={(e) => { handleSearch(e); setGoBackState(false); }} fullWidth style={{ padding: "10px", color: "#FAFAFB" }} InputProps={{ disableUnderline: true }} variant='standard' placeholder='Search' />
            </Grid>
          </div>
          <Grid item lg={12} md={12} xs={12} style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
            <Grid className='blogs-container1' sx={{ display: 'flex', justifyContent: 'center', ml:'0px' }} container spacing={2} md={12} xs={12}>
              {
                (goBackState === true) ? <div style={{ width: '100%', marginTop:'10px' }}> <MobileDetailedCard /> </div> : (<>{(filteredData?.slice(firstPostIndex, lastPostIndex))?.map((item, i) => {
                  return <Grid item md={6} xs={6} sx={{ cursor: 'pointer', pr:'16px' }}> <TabletItem key={i} singleCard={item} /> </Grid>
                })}</>)
              }
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
              {!goBackState && <BlogsPagination isMobile={isMobile || isTablet} totalPosts={totalPostValue} postPerPage={postPerPage} setCurrentPage={setCurrentPage} />}
            </Grid>
          </Grid>

          <Grid className='blogs-container' sx={{ display: 'flex', width: '100% !important', flexDirection: 'column', mb: '20px !important' }}>
            <Grid sx={{ marginBottom: '20px' }}>
              <Typography className='primary-font-bold1'>Quick Links</Typography>
            </Grid>

            {currentAndNextMonth?.map((month, index, array) => (
              <Accordion
                elevation={0}
                key={month.panel}
                expanded={expanded === month.panel}
                onChange={handleChange(month.panel, month.month)}
                sx={{
                  width: '100% !important',
                  marginBottom: index === array.length - 1 ? "30px" : '0px',
                  marginTop: "15px", borderRadius: '10px !important', border: '1px solid #FF3535 ', backgroundColor: 'white',
                  '&:before': { display: 'none', }
                }}>
                <AccordionSummary
                  expandIcon={expanded === month.panel ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  expanded={expanded === month.panel}
                  sx={{
                    cursor: "pointer", borderBottom: expanded === month.panel ? '1px solid #707070' : 'red'
                  }} >
                  <Typography className='primary-font-bold1'>{month.month} </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography > */}
                  <ul className='bullet-list'>
                    {
                      accordianData?.map((data, index, array) => {
                        if (index === array.length - 1) {
                          return <><div style={{ display: 'flex', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setCardData(data); window.scrollTo(0, 0); setGoBackState(true) }} >
                            <div> <li className='list' /></div>
                            <div className='primary-font-normal-9'>{data.heading}</div>
                          </div></>
                        }
                        else {
                          return <>
                            <div style={{ display: 'flex', marginBottom: '10px', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setCardData(data); window.scrollTo(0, 0); setGoBackState(true) }} >
                              <div> <li className='list' /></div>
                              <div className='primary-font-normal-9'>{data.heading}</div>
                            </div>
                            <Divider width="100%" color='#AEAEAE' />
                          </>
                        }

                      })
                    }
                  </ul>
                  {/* </Typography> */}
                </AccordionDetails>
              </Accordion>

            ))}
          </Grid>
        </Grid>
      </div>
        : isMobile ? <div>
          <Grid container xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <div className='blogs-container' style={{ width: '100%' }} >
              <Grid item xs={12} style={{ borderRadius: "10px", border: "#FF3535 1px solid", marginTop: '20px' }}>
                <TextField value={searchText} onChange={(e) => { handleSearch(e); setGoBackState(false); }} fullWidth style={{ padding: "10px", color: "#FAFAFB" }} InputProps={{ disableUnderline: true }} variant='standard' placeholder='Search' />
              </Grid>
            </div>
            <Grid item lg={12} md={12} xs={12} style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
              <div className='blogs-container'>
                {
                  (goBackState === true) ? <div style={{ width: '100%' }}> <MobileDetailedCard /> </div> : (<div>{(filteredData?.slice(firstPostIndex, lastPostIndex))?.map((item, i) => {
                    return <div style={{ cursor: 'pointer' }}> <MobileItem key={i} singleCard={item} /> </div>
                  })}</div>)
                }
              </div>

              <Grid sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                {!goBackState && <BlogsPagination isMobile={isMobile || isTablet} totalPosts={totalPostValue} postPerPage={postPerPage} setCurrentPage={setCurrentPage} />}
              </Grid>
            </Grid>

            <Grid className='blogs-container' sx={{ display: 'flex', width: '100% !important', flexDirection: 'column', mb: '20px !important' }}>
              <Grid sx={{ marginBottom: '20px' }}>
                <Typography className='primary-font-bold1'>Quick Links</Typography>
              </Grid>

              {currentAndNextMonth?.map((month, index, array) => (
                <Accordion
                  elevation={0}
                  key={month.panel}
                  expanded={expanded === month.panel}
                  onChange={handleChange(month.panel, month.month)}
                  sx={{
                    width: '100% !important',
                    marginBottom: index === array.length - 1 ? "30px" : '0px',
                    marginTop: "15px", borderRadius: '10px !important', border: '1px solid #FF3535 ', backgroundColor: 'white',
                    '&:before': { display: 'none', }
                  }}>
                  <AccordionSummary
                    expandIcon={expanded === month.panel ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    expanded={expanded === month.panel}
                    sx={{
                      cursor: "pointer", borderBottom: expanded === month.panel ? '1px solid #707070' : 'red'
                    }} >
                    <Typography className='primary-font-bold1'>{month.month} </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Typography > */}
                    <ul className='bullet-list'>
                      {
                        accordianData?.map((data, index, array) => {
                          if (index === array.length - 1) {
                            return <><div style={{ display: 'flex', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setCardData(data); window.scrollTo(0, 0); setGoBackState(true) }} >
                              <div> <li className='list' /></div>
                              <div className='primary-font-normal-9'>{data.heading}</div>
                            </div></>
                          }
                          else {
                            return <>
                              <div style={{ display: 'flex', marginBottom: '10px', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setCardData(data); window.scrollTo(0, 0); setGoBackState(true) }} >
                                <div> <li className='list' /></div>
                                <div className='primary-font-normal-9'>{data.heading}</div>
                              </div>
                              <Divider width="100%" color='#AEAEAE' />
                            </>
                          }

                        })
                      }
                    </ul>
                    {/* </Typography> */}
                  </AccordionDetails>
                </Accordion>

              ))}
            </Grid>
          </Grid>
        </div> : <div className="blogs-container ">
          <Grid container lg={12} md={12} xs={12} style={{ marginTop: "30px" }}>
            <Grid item lg={8} md={8} xs={8} style={{ display: "flex", flexDirection: 'column' }}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {

                  setGoBackState(true)
                }}>
                {
                  (goBackState === true) ? <DetailedCard /> : (<div>{(filteredData?.slice(firstPostIndex, lastPostIndex))?.map((item, i) => {
                    return <Item key={i} singleCard={item} />
                  })}</div>)
                }
              </div>
              <Grid sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                {!goBackState && <BlogsPagination totalPosts={totalPostValue} postPerPage={postPerPage} setCurrentPage={setCurrentPage} />}
              </Grid>
            </Grid>
            <Grid lg={4} md={4} xs={4}>
              <Grid>
                <Grid style={{ borderRadius: "10px", border: "#FF3535 1px solid" }}>
                  <TextField value={searchText} onChange={(e) => { handleSearch(e); setGoBackState(false); }} fullWidth style={{ padding: "18px", color: "#FAFAFB" }} InputProps={{ disableUnderline: true }} variant='standard' placeholder='Search' />
                </Grid>
              </Grid>

              <Grid sx={{ marginTop: '30px', marginBottom: '20px' }}>
                <Typography className='primary-font-bold1'>Quick Links</Typography>
              </Grid>
              <Grid sx={{ width: '100%' }}>
                {currentAndNextMonth?.map((month, index, array) => (
                  <Accordion
                    elevation={0}
                    key={month.panel}
                    expanded={expanded === month.panel}
                    onChange={handleChange(month.panel, month.month)}
                    sx={{

                      marginBottom: index === array.length - 1 ? "30px" : '0px',
                      marginTop: "15px", borderRadius: '10px !important', border: '1px solid #FF3535 ', backgroundColor: 'white',
                      '&:before': { display: 'none', }
                    }}>
                    <AccordionSummary
                      expandIcon={expanded === month.panel ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      expanded={expanded === month.panel}
                      sx={{
                        cursor: "pointer", borderBottom: expanded === month.panel ? '1px solid #707070' : 'red'
                      }} >
                      <Typography className='primary-font-bold1'>{month.month} </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className='primary-font-normal-9'>
                        <ul className='bullet-list'>
                          {
                            accordianData?.map((data, index, array) => {
                              if (index === array.length - 1) {
                                return <><div style={{ display: 'flex', marginBottom: '15px', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setCardData(data); window.scrollTo(0, 0); setGoBackState(true) }}>
                                  <div> <li className='list' /></div>
                                  <div>{data.heading}</div>
                                </div></>
                              }
                              else {
                                return <>
                                  <div style={{ display: 'flex', marginBottom: '15px', marginTop: '10px', cursor: 'pointer' }} onClick={() => { setCardData(data); window.scrollTo(0, 0); setGoBackState(true) }}>
                                    <div> <li className='list' /></div>
                                    <div>{data.heading}</div>
                                  </div>
                                  <Divider width="100%" color='#AEAEAE' />
                                </>
                              }

                            })
                          }
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>}
    </div>
  )
}

export default Blog