import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../styles/RiskBasedOnLocation.css"
import { useState } from 'react';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        borderTop: 'none !important',
      }}
    >
      {value === index && (
        <Grid sx={{ paddingTop: 0 }}>
          <Typography>{children}</Typography>
        </Grid>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RiskBasedOnLocationDetail(propsone) {

  const location = useLocation();
  const datavalue = location.state && location.state;
  const navigate = useNavigate();


const[data,setData]=useState(datavalue)
console.log(data,"data");
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }
  const [expanded, setExpanded] = React.useState(false);

  const tabHandleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    // window.scroll({
    //   top: 500,
    //   left: 0

    // });
  };
  return (
    <Grid container sx={{ marginBottom: "60px" }}>


      {
        data.status ? (
          data.status === "1" ? (
            <Grid container>

              <Grid container item xs={12} md={12} className='ourservices-header-img'>
                {/* <Grid item xs={1} md={1} lg={1}></Grid> */}

                <Grid item xs={12} md={12} lg={12} sx={{ my: "auto " }} >
                  <div className='ourservices-header-container'>

                    <Typography className='primary-font-bold7  diaster-title' sx={{ textAlign: "start", }}>DISASTER RESOURCE LIBRARY</Typography>
                    <div onClick={() => {
                      navigate(-1);

                    }}
                      style={{ cursor: "pointer" }}className='back-icon-container'>
                      <ArrowBackIcon  className='back-icon'  />
                      <Typography className='primary-font-bold1'>Go Back</Typography>
                    </div>
                  </div>
                </Grid>

                {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
              </Grid>

              <Grid container item xs={12} md={12} className='risk-location-top-container'>
                <div className='ourservices-header-container'>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ marginBottom: "30px" }} className='primary-font-bold8'>{data.name}</Typography>
                    {
                      data.content ? (<img src={data.image ? data.image : data.naturalimage} className='ourservices-detail-img ' alt="text" />) : (<img src={data.image ? data.image : data.naturalimage} className='ourservices-detail-img1 ' alt="text" />)
                    }
                    {/* <img src={data.image ? data.image : data.naturalimage} style={{ height: "290px", width: "540px", float: "right", marginLeft: "30px", marginBottom: "33px" }} alt="text" /> */}



                    <Typography className='primary-font-normal-5'>  <span dangerouslySetInnerHTML={{ __html: data.content ? data.content : "" }} /></Typography>
                  </Grid>
                </div>
              </Grid>
              <Grid container className='ourservices-header-container' >
                <Grid className='laptop-view'>
                  
                    <Grid container lg={12} md={12} xs={12} sx={{ display: 'flex', flexDirection: 'column' }} >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { background: "red" } }}
                        aria-label="basic tabs"
                        sx={{
                          '& .MuiTabs-indicator': {
                            display: 'none',
                          },
                        }}
                      >
                        <Tab
                          sx={{
                            width: "100%",
                            height: "80px",
                            color: value === 0 ? 'red !important' : '#000',
                            backgroundColor: value === 0 ? 'white' : '#F8F8F8',
                            border: '1px solid #C7CBCF',
                            // borderRadius: '10px 10px 0px 0px',
                            // borderBottom: value === 0 ? 'none' : '1px solid #C7CBCF',
                            // mr: '10px',
                          }}
                          label={<Typography className='primary-font-bold4' sx={{ textAlign: "center" }} textTransform="none"> {data.tabTitleOne || "Before"} </Typography>}
                          {...a11yProps(0)}
                        />
                        <Tab
                          sx={{
                            width: "100%",
                            height: "80px",
                            color: value === 1 ? 'red !important' : '#000',
                            backgroundColor: value === 1 ? 'white' : '#F8F8F8',
                            border: '1px solid #C7CBCF',
                            // borderRadius: '10px 10px 0px 0px',
                            // borderBottom: value === 1 ? 'none' : '1px solid #C7CBCF',
                            // mr: '10px',
                          }}
                          label={<Typography className='primary-font-bold4' sx={{ textAlign: "center" }} textTransform="none">{data.tabTitleTwo || "During"} </Typography>}
                          {...a11yProps(1)}
                        />
                        <Tab
                          sx={{
                            width: "100%",
                            height: "80px",
                            color: value === 2 ? 'red !important' : '#000',
                            backgroundColor: value === 2 ? 'white' : '#F8F8F8',
                            border: '1px solid #C7CBCF',
                            // borderRadius: '10px 10px 0px 0px',
                            // borderBottom: value === 2 ? 'none' : '1px solid #C7CBCF',
                            // mr: '10px',
                          }}
                          label={<Typography className='primary-font-bold4' sx={{ textAlign: "center" }} textTransform="none">{data.tabTitleThree || "After"}</Typography>}
                          {...a11yProps(2)}
                        />
                        {data.moreinfo && (
                          <Tab
                            sx={{
                              width: "100%x",
                              height: "80px",
                              color: value === 3 ? 'red !important' : '#000',
                              backgroundColor: value === 3 ? 'white' : '#F8F8F8',
                              border: '1px solid #C7CBCF',
                              // borderRadius: '10px 10px 0px 0px',
                              // borderBottom: value === 3 ? 'none' : '1px solid #C7CBCF',
                            }}
                            label={<Typography className='primary-font-bold4' sx={{ textAlign: "center" }} textTransform="none">More Info</Typography>}
                            {...a11yProps(3)}
                          />
                        )}
                      </Tabs>

                      <Grid className='laptop-view'>
                        <Grid item xs={12} md={12} sx={{ border: 1, borderTop: 'none', borderColor: ' #C7CBCF', borderRadius: "0px 0px 10px 10px" }}>
                          <CustomTabPanel value={value} index={0}>
                            <Grid sx={{ background: '#fff', border: '1px solid #C7CBCF', padding: '10px' }}>
                              <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography className='primary-font-normal-11' component="div">
                                  <span dangerouslySetInnerHTML={{ __html: data.before ? data.before : "No data" }} />
                                </Typography>
                              </Grid>
                            </Grid>
                          </CustomTabPanel>
                          <CustomTabPanel value={value} index={1}>
                            <Grid sx={{ background: '#fff', border: '1px solid #C7CBCF', padding: '10px' }}>
                              <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                                <Typography className='primary-font-normal-11' component="div">
                                  <span dangerouslySetInnerHTML={{ __html: data.during ? data.during : "No data" }} />
                                </Typography>
                              </Grid>
                            </Grid>
                          </CustomTabPanel>
                          <CustomTabPanel value={value} index={2}>
                            <Grid sx={{ background: '#fff', border: '1px solid #C7CBCF', padding: '10px' }}>
                              <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography className='primary-font-normal-11' component="div">
                                  <span dangerouslySetInnerHTML={{ __html: data.after ? data.after : "No data" }} />
                                </Typography>
                              </Grid>
                            </Grid>
                          </CustomTabPanel>
                          {data.moreinfo && (
                            <CustomTabPanel value={value} index={3}>
                              <Grid sx={{ background: '#fff', border: '1px solid #C7CBCF', padding: '10px' }}>
                                <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                                  <Typography className='primary-font-normal-11' component="div">
                                    <span dangerouslySetInnerHTML={{ __html: data.moreinfo ? data.moreinfo : '<span style="color: #8B8B8B;">No Date</span>', }} />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CustomTabPanel>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                
                </Grid>
                <div className='mobile-view'>
               
                    <Accordion sx={{
                      '&.MuiPaper-root.MuiAccordion-root.Mui-expanded': {
                        margin: "0px !important"
                      }

                    }} expanded={expanded === 'panel1'} onChange={tabHandleChange('panel1')}>
                      <AccordionSummary
                        expandIcon={
                          expanded === 'panel1' ? (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr: "-24px"
                              }}
                            >
                              <RemoveIcon sx={{ color: 'white' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr: "-24px"
                              }}
                            >
                              <AddIcon sx={{ color: 'white' }} />
                            </IconButton>
                          )
                        } >
                        <Typography className='primary-font-bold4' sx={{ textTransform: "none !important" }}>Before</Typography>
                      </AccordionSummary>
                      <AccordionDetails >
                        <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                          <Typography className='primary-font-normal-11' component="div">
                            <span dangerouslySetInnerHTML={{ __html: data.before ? data.before : "No data" }} />
                          </Typography>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                      '&.MuiPaper-root.MuiAccordion-root.Mui-expanded': {
                        margin: "0px !important"
                      }

                    }} expanded={expanded === 'panel2'} onChange={tabHandleChange('panel2')}>
                      <AccordionSummary
                        expandIcon={
                          expanded === 'panel2' ? (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr: "-24px"
                              }}
                            >
                              <RemoveIcon sx={{ color: 'white' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr: "-24px"
                              }}
                            >
                              <AddIcon sx={{ color: 'white' }} />
                            </IconButton>
                          )
                        } >
                        <Typography className='primary-font-bold4' sx={{ textTransform: "none !important" }}>During</Typography>
                      </AccordionSummary>
                      <AccordionDetails >
                        <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                          <Typography className='primary-font-normal-11' component="div">
                            <span dangerouslySetInnerHTML={{ __html: data.during ? data.during : "No data" }} />
                          </Typography>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{
                      '&.MuiPaper-root.MuiAccordion-root.Mui-expanded': {
                        margin: "0px !important"
                      }

                    }} expanded={expanded === 'panel3'} onChange={tabHandleChange('panel3')}>
                      <AccordionSummary
                        expandIcon={
                          expanded === 'panel3' ? (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr: "-24px"
                              }}
                            >
                              <RemoveIcon sx={{ color: 'white' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr: "-24px"
                              }}
                            >
                              <AddIcon sx={{ color: 'white' }} />
                            </IconButton>
                          )
                        } >
                        <Typography className='primary-font-bold4' sx={{ textTransform: "none !important" }}>After</Typography>
                      </AccordionSummary>
                      <AccordionDetails >
                        <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                          <Typography className='primary-font-normal-11' component="div">
                            <span dangerouslySetInnerHTML={{ __html: data.after ? data.after : "No data" }} />
                          </Typography>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {data.moreinfo && <Accordion sx={{
                      '&.MuiPaper-root.MuiAccordion-root.Mui-expanded': {
                        margin: "0px !important"
                      }

                    }} expanded={expanded === 'panel4'} onChange={tabHandleChange('panel4')}>
                      <AccordionSummary
                        expandIcon={
                          expanded === 'panel4' ? (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr: "-24px"
                              }}
                            >
                              <RemoveIcon sx={{ color: 'white' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr: "-24px"
                              }}
                            >
                              <AddIcon sx={{ color: 'white' }} />
                            </IconButton>
                          )
                        } >
                        <Typography className='primary-font-bold4' sx={{ textTransform: "none !important" }}>More Info</Typography>
                      </AccordionSummary>
                      <AccordionDetails >
                        <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                          <Typography className='primary-font-normal-11' component="div">
                            <span dangerouslySetInnerHTML={{ __html: data.moreinfo ? data.moreinfo : "No data" }} />
                          </Typography>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>}
                  </div>
               
                  </Grid>
            </Grid>
          ) : (
            <Grid container>

              <Grid container item xs={12} md={12} sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)", height: "200px" }}>
                {/* <Grid item xs={1} md={1} lg={1}></Grid> */}

                <Grid item xs={12} md={12} lg={12} sx={{ my: "auto " }} >
                  <div className='ourservices-header-container'>

                    <Typography className='primary-font-bold7' sx={{ textAlign: "start", }}>RISKS BASED ON MY LOCATION</Typography>
                    <div onClick={() => {
                      navigate(-1);

                    }}
                      style={{ display: "flex", flexDirection: "row", marginTop: "38px", cursor: "pointer" }}>
                      <ArrowBackIcon className='back-icon'  />
                      <Typography className='primary-font-medium-2'>Go Back</Typography>
                    </div>
                  </div>
                </Grid>

                {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
              </Grid>

              <Grid container item xs={12} md={12} className='risk-location-top-container'>
                <div className='ourservices-header-container'>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ marginBottom: "30px" }} className='primary-font-bold8'>{data.name}</Typography>
                    {
                      data.content ? (<img src={data.image ? data.image : data.naturalimage} className='ourservices-detail-img ' alt="text" />) : (<img src={data.image ? data.image : data.naturalimage} className='ourservices-detail-img1 ' alt="text" />)
                    }
                    {/* <img src={data.image ? data.image : data.naturalimage} style={{ height: "290px", width: "540px", float: "right", marginLeft: "30px", marginBottom: "33px" }} alt="text" /> */}



                    <Typography className='primary-font-normal-5'>  <span dangerouslySetInnerHTML={{ __html: data.content ? data.content : "" }} /></Typography>
                  </Grid>
                </div>
              </Grid>

            </Grid>
          )
        ) : null
      }

    </Grid>
  );
}