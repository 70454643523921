import React from 'react'
import ContentWrapper from '../../../../common/components/ContentWrapper';
import PrivacyPolicy from './PrivacyPolicy';
export default function PrivacyPolicyIndex() {
  return (
    <>
    <ContentWrapper contenview={<PrivacyPolicy/>} />
    
</>
  )
}
