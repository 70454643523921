import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../OurService/styles/RiskBasedOnLocation.css";

export default function Security() {
  const navigate = useNavigate();
  // const listStyle = {
  //   paddingLeft: "24px",

  //   marginTop: "10px",
  // };
  // const Uliststyle = {
  //   listStyleType: "disc",
  //   marginBlockStart: "0px",
  //   marginBlockEnd: "0px",

  //   paddingLeft: "15px",
  // };
  return (
    <Grid container>
        <Grid container item xs={12} md={12} className='ourservices-header-img'>
                <Grid item xs={12} md={12} lg={12} sx={{ my: "auto " }} >
                  <div className='ourservices-header-container'>

                    <Typography className='primary-font-bold7  secure-title' sx={{ textAlign: "start", }}>SECURITY</Typography>
                    <div onClick={() => {
                      navigate(-1);

                    }}
                      style={{ cursor: "pointer" }}className='back-icon-container'>
                      <ArrowBackIcon  className='back-icon'  />
                      <Typography className='primary-font-medium-2'>Go Back</Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
      <Grid container className="privacy-middle-container">
        <div className="ourservices-header-container">
        <Grid container item xs={12} md={12} lg={12}>
          <Box>
            <Box>
            <Typography className='primary-font-bold11 privacy-title' >Introduction</Typography>
             
              <Typography
                className="primary-font-normal-2 privacy-content"
                sx={{ marginTop: "10px" }}
              >
                Welcome to the MYREDFOLDER® (www.myredfolder.com) website
                ("we," "us," or "our"). Security is of paramount
                importance to us, and we are dedicated to ensuring the utmost
                protection of your personal information, digital assets,
                financial information, and all other data you entrust to us.
                This Security Policy outlines the comprehensive security
                measures we have implemented to safeguard your information.{" "}
              </Typography>
              <Typography className="primary-font-normal-2 privacy-content">
                {" "}
                By accessing or using the MYREDFOLDER® / www.myredfolder.com
                website, you acknowledge that you have read, understood, and
                agreed to the terms of this Security Policy. If you do not agree
                with our security practices, please do not use the MYREDFOLDER®
                / www.myredfolder.com website{" "}
              </Typography>
              
            </Box>
            <Box>
              <Typography
                className="primary-font-bold11 privacy-title"
                sx={{ marginTop: "20px", marginBottom: "7px" }}
              >
                Security Measures
              </Typography>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Blockchain Technology:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    The MYREDFOLDER® / www.myredfolder.com website leverages
                    blockchain technology to secure the integrity and
                    immutability of your digital assets. Transactions and data
                    stored on the blockchain are cryptographically protected
                    against tampering
                  </Typography>
                {/* </ul> */}
              </Box>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Secure Socket Layer (SSL):
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    We use SSL certificates to encrypt data transmitted between
                    your device and the MYREDFOLDER® / www.myredfolder.com
                    website protecting it from unauthorized access during
                    transmission.
                  </Typography>
                {/* </ul> */}
              </Box>

              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  Multi-Factor Authentication (MFA):
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    To enhance access security, the MYREDFOLDER® /
                    www.myredfolder.com website utilizes MFA methods, requiring
                    additional verification steps beyond a password, such as a
                    one-time code sent to your registered email or phone.
                  </Typography>
                {/* </ul> */}
              </Box>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Quantum Layer Security:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    Our system is fortified with quantum-resistant encryption to
                    safeguard against potential quantum-based attacks.
                  </Typography>
                {/* </ul> */}
              </Box>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  Redundant Servers:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    We maintain or lease multiple, geographically distributed
                    servers to ensure redundancy, data backup, and continuous
                    service availability.
                  </Typography>
                {/* </ul> */}
              </Box>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Firewall Protection:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    The MYREDFOLDER® / www.myredfolder.com website is protected
                    by firewalls that monitor and control incoming and outgoing
                    network traffic, preventing unauthorized access and
                    potential threats.
                  </Typography>
                {/* </ul> */}
              </Box>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Vulnerability Assessments and Penetration Testing:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    Regular vulnerability assessments and penetration testing
                    are conducted to identify and address potential security
                    weaknesses proactively.
                  </Typography>
                {/* </ul> */}
              </Box>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  Data Encryption:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    All sensitive data, including your personal information and
                    financial details, is encrypted both at rest and in transit
                    to prevent unauthorized access.
                  </Typography>
                {/* </ul> */}
              </Box>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Access Control:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    Access to your account and sensitive information is strictly
                    controlled based on role-based access permissions.
                  </Typography>
                {/* </ul> */}
              </Box>
            </Box>

            <Box>
              <Typography
                className="primary-font-bold11 privacy-title"
                sx={{ marginTop: "10px", marginBottom: "7px" }}
              >
                {" "}
                Compliance and Auditing
              </Typography>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Legal and Regulatory Compliance:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    The MYREDFOLDER® / www.myredfolder.com website adheres to
                    all applicable laws, regulations, and industry standards
                    related to data protection and privacy.
                  </Typography>
                {/* </ul> */}
              </Box>

              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  Security Audits:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    Periodic security audits are conducted to evaluate the
                    effectiveness of our security measures and identify areas
                    for improvement.
                  </Typography>
                {/* </ul> */}
              </Box>
            </Box>

            <Box>
              <Typography
                className="primary-font-bold11"
                sx={{ marginTop: "10px", marginBottom: "7px" }}
              >
                {" "}
                Incident Response{" "}
              </Typography>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Incident Reporting:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    If a security incident occurs, the MYREDFOLDER® IT security
                    team follows a detailed incident response plan to promptly
                    detect, mitigate, and remediate the impact.
                  </Typography>
                {/* </ul> */}
              </Box>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  User Notification:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    In the event of a data breach or security incident that may
                    compromise your personal information or digital assets, we
                    will notify you promptly in accordance with applicable laws.
                  </Typography>
                {/* </ul> */}
              </Box>
            </Box>
            <Box>
              <Typography 
                className="primary-font-bold11 privacy-title"
                sx={{ marginTop: "10px", marginBottom: "7px" }}
              >
                {" "}
                Employee Training{" "}
              </Typography>
              <Box>
                <Typography
                  className="primary-font-bold15 privacy-para"
                  sx={{ marginTop: "10px" }}
                >
                  {" "}
                  Security Awareness:
                </Typography>
                {/* <ul style={Uliststyle}> */}
                  <Typography className="primary-font-normal-2 privacy-content">
                    {" "}
                    All employees undergo regular security awareness training to
                    stay updated on best practices, security protocols, and
                    potential threats.
                  </Typography>
                {/* </ul> */}
              </Box>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                className="primary-font-bold11 privacy-title"
              >
                {" "}
                Third-Party Services{" "}
              </Typography>
              <Typography
                className="primary-font-normal-2 privacy-content"
              >
                The MYREDFOLDER® / www.myredfolder.com website may integrate
                with third-party services or websites. We perform due diligence
                to ensure their security practices align with our standards
                before integrating with them.
              </Typography>
            </Box>

            <Box sx={{ marginTop: "10px" }}>
              <Typography
                
                className="primary-font-bold11 privacy-title"
              >
                {" "}
                Change Management
              </Typography>
              <Typography className="primary-font-normal-2 privacy-content">
                Any changes to security measures will be carefully planned,
                documented, and tested before implementation to minimize any
                potential impact on service or security.
              </Typography>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                className="primary-font-bold11 privacy-title"
              >
                {" "}
                User Responsibilities{" "}
              </Typography>
              <Typography
                className="primary-font-normal-2 privacy-content"
              >
                We encourage you to maintain the security of your
                MYREDFOLDER® account, including your login credentials, and
                promptly report any suspicious activity.{" "}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                className="primary-font-bold11 privacy-title"
              >
                Contact Us
              </Typography>
              <Typography
                className="primary-font-normal-2 privacy-para"
              >
                {" "}
                If you have any questions, concerns, or feedback about this
                Security Policy or the MYREDFOLDER® / www.myredfolder.com
                website security measures, please contact our support team at
                info@myredfolder.com Thank you for entrusting the MYREDFOLDER®,
                MW Concepts and Marketing, LLC, and the National Emergency
                Planning and Training Association, LLC with your personal and
                digital assets. We are committed to upholding the highest
                security standards to protect your information."
              </Typography>
            </Box>
          </Box>
        </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
