import compassion from "../../../../assets/aboutus-assets/compassion-values.png";
import resilience from "../../../../assets/aboutus-assets/resilience-values.png";
import integrity from "../../../../assets/aboutus-assets/integrity-values.png";
import collaboration from "../../../../assets/aboutus-assets/collaboration-values.png";
import excellence from "../../../../assets/aboutus-assets/excellence-values.png";
import community from "../../../../assets/aboutus-assets/community-Impact-values.png";
export const data = [
    {
      image: compassion,
      title: "Compassion",
      content: "We deeply care about the well-being of individuals and families impacted by disasters. We approach our work with empathy, understanding the unique challenges they face and providing support and assistance with genuine compassion."
    },
    {
      image: resilience,
      title: "Resilience",
      content: "We believe in the strength and resilience of individuals and families. We are dedicated to empowering them to overcome adversity, providing the tools, resources, and guidance needed to build resilience and bounce back from the effects of disasters."
    },
    {
      image: integrity,
      title: "Integrity",
      content: "We conduct our business with the highest level of integrity, placing honesty, transparency, and ethical practices at the core of everything we do. We strive to build trust with our customers and stakeholders by consistently delivering on our promises and upholding the values that define us."
    },
    {
      image: collaboration,
      title: "Collaboration",
      content: "We recognize that addressing the needs of individuals and families during and after a disaster requires a collective effort. We actively collaborate with our customers, partners, and communities, fostering strong relationships and working together to find innovative solutions and create a supportive network."
    },
    {
      image: excellence,
      title: "Excellence",
      content: "We are committed to excellence in all aspects of our work. From the quality of our products and services to the expertise of our team, we strive for continuous improvement and aim to exceed expectations. We embrace innovation, staying at the forefront of industry advancements to provide the best possible support to those we serve."
    },
    {
      image: community,
      title: "Community Impact",
      content: "We understand the interconnectedness of communities and the importance of collective well-being. We actively contribute to the betterment of the communities we serve, engaging in initiatives that promote preparedness, resilience, and the overall safety and welfare of individuals and families."
    }
  ]