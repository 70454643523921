import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import contact from "../../../../assets/aboutus-assets/aboutus-header-imges/contact-us.png";
import getItTouch from "../../../../assets/getInTouch_pic.png";
import email from "../../../../assets/icons/email_icon.svg";
import phone from "../../../../assets/icons/phone_icon.svg";
import location from "../../../../assets/icons/location_icon.svg";
import { styled } from "@mui/material/styles";
import Alert from '@mui/material/Alert';
import "../styles/Aboutus.css"
import { Link } from "react-router-dom";
import { sendContactUsMessage } from "../redux/actions";

const ContactUsComponent = () => {

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });

  const [formData, setFormData] = useState({
    name: '',
    emailId: '',
    subject: '',
    message: '',
  });

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s']+$/;
    return nameRegex.test(name);
  };

  const validateEmail = (email) => {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
  };


  const handleSubmit = (event) => {
    event.preventDefault();

    const isNameValid = validateName(formData.name);
    const isEmailValid = validateEmail(formData.emailId);

    if (formData.name === "" && formData.emailId === "") {
      setNameError('This is required');
      setEmailError('This is required');
      return;
    }
    // else if (!isNameValid) {
    //   setNameError('Please enter a valid name.');
    //   return;
    // }

    else if (!isEmailValid) {
      setEmailError('Please enter a valid email address');
      return;
    }
  

// window.scrollTo(0,0)
//       setSuccessMessage('Message Sent Successfully! Our Team will reach you shortly');
//       setTimeout(() => {
//         setSuccessMessage('');
//       }, 5000);
//       event.target.reset();
    

    sendContactUsMessage(formData)
      .then((apiData) => {
        if (apiData && apiData.status === 200) {
          setErrorMessage('');
    window.scrollTo(0,0)
          setSuccessMessage('Message Sent Successfully! Our Team will reach you shortly');
          setTimeout(() => {
            setSuccessMessage('');
          }, 5000);
          event.target.reset();
        }
        if (apiData && apiData.status !== 200) {
          setSuccessMessage('')
          setErrorMessage(apiData.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'name') {
      setNameError('')
    } else if (name === 'emailId') {
      setEmailError('')
    }
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  return (
    <div>
      {successMessage && (
         <Box className="contact-form-alert-popup">
          <Alert variant="filled" severity="success" style={{ }}>
            {successMessage}
          </Alert>
        </Box>
      ) }
        <div>
          {/* <Grid container  >
       

        <div   className="container-aboutus-contactus">
           <Grid container item xs={12} md={12} lg={12}  className="contactUs_topgrid" sx={{ my: "auto", justifyContent: "space-between", display: 'flex', flexDirection: "row" }}>
            <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>
              <Typography className='primary-font-bold7'></Typography>
              CONTACT US
            </Grid>
            <Grid item xs={6} md={6} lg={6} className='contactus-header-img' >
              <img alt="complex" src={contact} style={{ width: "100%", height: "100%" }} />
            </Grid>
            </Grid>

        </div>
      </Grid> */}
          <Grid container sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)", }}>
            <div className='container-aboutus-contactus'>
              <Grid container item xs={12} md={12} lg={12} >

                <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>

                  <Typography className='primary-font-bold7'>  CONTACT US</Typography>

                </Grid>
                <Grid item xs={6} md={6} lg={6} className='contactus-header-img' >
                  <img src={contact} alt="supplykits" style={{ width: "100%", height: "100%" }} />
                </Grid>

              </Grid>
            </div>
          </Grid>
          < div className="container-aboutus-contactus">
            <Grid container className="contactus-middle-container"  >
              <Grid item xs={12} md={6} className="contactUs_mapPic-container" >
                <Typography sx={{}} className="primary-font-bold9 contactus-middle-title">Get In touch with us</Typography>
                <div>
                  <img
                    src={getItTouch}
                    alt="getItTouch"
                    className="contactUs_mapPic"
                  />
                </div>


                <Box
                  component="ul"
                  className="list-component"
                  aria-labelledby="category-a"
                  sx={{ pl: 2 }}
                >
                  <li className="li-icon-alignment" style={{ cursor: 'default' }}>
                    <div>
                      <img src={location} alt="address" className="icon_styles" />
                    </div>
                    <Typography
                      className="primary-font-normal-7"
                      style={{ display: "flex", alignItems: "center", cursor: 'default' }}
                    >
                      255 Primera Blvd. Ste, 160 Lake Mary, FL 32746
                    </Typography>
                  </li>
                  <li className="li-icon-alignment" style={{ cursor: 'default' }}>
                    <div>
                      <img src={phone} alt="phone number" className="icon_styles" />
                    </div>
                    <Typography
                      className="primary-font-normal-7"
                      style={{ display: "flex", alignItems: "center", cursor: 'default' }}
                    >
                      + (888) 267 9990
                    </Typography>
                  </li>
                  <li className="li-icon-alignment">
                    <div>
                      <img src={email} alt="email id" className="icon_styles" />
                    </div>
                    <Link
                      to='#'
                      style={{ borderBottom: "none", textDecoration: "none" }}
                      onClick={(e) => {
                        window.location.href = 'mailto:info@nepta.us';
                        e.preventDefault();
                      }}
                    >
                      <Typography
                        className="primary-font-normal-7 contact-info"

                      >
                        info@nepta.us
                      </Typography>
                    </Link>

                  </li>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                < Typography className="primary-font-bold9 contactus-middle-title1 ">Feel free to contact us</Typography>
                <form onSubmit={handleSubmit} >

                  {/* {errorMessage && (
                <Alert variant="filled" severity="error" style={{ marginBottom: 10 }}>
                  {errorMessage}
                </Alert>
              )} */}
                  <Typography className='font-normal-small error-message'>{nameError}</Typography>
                  <div style={{ marginBottom: 10, }}>
                    <label>
                      Name<span className="required-asterisk">*</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    name="name"
                    // placeholder="(Required Field)"
                    className={`formStyles ${nameError !== '' ? 'err-input' : ''}`}
                    onChange={handleChange}
                  />
                  <div>
                    <Typography className='font-normal-small error-message'>{emailError}</Typography>
                    <div style={{ marginBottom: 10, }}>
                      <label>
                        E-mail<span className="required-asterisk">*</span>
                      </label>
                    </div>

                    <input
                      type="text"
                      name="emailId"
                      // placeholder="(Required Field)"
                      className={`formStyles ${emailError !== '' ? 'err-input' : ''}`}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ marginBottom: 10, }}>
                    <label>
                      Subject
                    </label>
                  </div>
                  <input
                    type="text"
                    name="subject"
                    // placeholder=" Enter Subject"
                    className="formStyles"
                    onChange={handleChange}
                  />
                  <div style={{ marginBottom: 10, }}>
                    <label>
                      Message
                    </label>
                  </div>
                  <textarea
                    type="textarea"
                    name="message"
                    id="text-contact"
                    // placeholder="Your Message"
                    className="formStyles"
                    style={{ height: '100px', }}
                    onChange={handleChange}
                  />
                  <div style={{ marginBottom: 10, }}>
                    <div className="contactus-btn-container">
                      <Button variant="outlined"

                        className='contactus-pricing-btn' type="submit">
                        Send Message
                      </Button>
                    </div>
                  </div>
                </form>
              </Grid>

            </Grid>
          </div>
        </div>
      
    </div>
  );
};

export default ContactUsComponent;