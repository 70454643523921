import React from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import androidplaystore from "../../../../assets/playstore-android.png"
import appleplaystore from "../../../../assets/appleplaystore.png"
import { naturalDisasters, manualDiaster } from "../data/naturalDisasterdata";
import "../styles/RiskBasedOnLocation.css"
export default function RiskBasedOnLocation(props) {
  return (
    <Grid container>

      <Grid container item xs={12} md={12} className='ourservices-header-img'>
        <Grid item xs={12} md={12} lg={12} sx={{ my: "auto " }} >
          <div className='ourservices-header-container'>
            <Typography className='primary-font-bold7' sx={{ textAlign: "start", }}>DISASTER RESOURCE LIBRARY</Typography>
          </div>
        </Grid>
      </Grid>
      <div className='ourservices-header-container'>
        <Grid container item xs={12} md={12} className='disaster-heading'>
          <Grid item xs={12} md={12} lg={12}>
            <Typography className="primary-font-bold-heading" sx={{ textAlign: "center" }}>Natural Disasters</Typography>
          </Grid>
        </Grid>

        <Typography className='primary-font-normal-11' sx={{ textAlign: "center" }}>What are the typical risks that could occur in your community?</Typography>

        <Grid container item xs={12} md={12} className='disaster-middle-container'>
          <Grid container item spacing={4} xs={12} md={12} lg={12}>

            {naturalDisasters.map((item, i) => (

              <Natural key={i} item={item} />

            ))}

          </Grid>
        </Grid>
      </div>




      <div className='ourservices-header-container'>
        <Grid container item xs={12} md={12} className='disaster-heading1'>
          <Grid item xs={12} md={12} lg={12}>
            <Typography className="primary-font-bold-heading" sx={{ textAlign: "center" }}>Man-Made Disasters</Typography>
          </Grid>
        </Grid>

        <Typography className='primary-font-normal-11' sx={{ textAlign: "center" }}>These can be divided into different categories and they include technological hazards, sociological hazards and transportation hazards among others.</Typography>



        <Grid container item xs={12} md={12} className='disaster-middle-container'>
          <Grid container item spacing={4} xs={12} md={12} lg={12}>

            {manualDiaster.map((item, i) => (
              <Manual key={i} item={item} />
            ))}
          </Grid>
        </Grid>
      </div>
      <Grid container sx={{ background: "#FAFAFB" }} >
        <div className='ourservices-header-container'>
          <Grid container item xs={12} md={12} lg={12}  >
            <Grid item xs={12} md={7} sm={7} >
              <Typography className='primary-font-medium-7 bottom-text' sx={{ width: "100%", color: "#2B303E", letterSpacing: '0px', }}>Get the App for FREE with your Premium MYREDFOLDER® Plan</Typography>
            </Grid>
            <Grid container spacing={1} item xs={12} md={5} sm={5} className="appstore-container" sx={{ marginY: "auto" }} >
              <Grid item xs={6} md={6} sm={6} >
                <img className='playstore_icons' src={appleplaystore} alt="text" />
              </Grid>
              <Grid item className='icon-img' xs={6} md={6} sm={6} >
                <img className='playstore_icons' src={androidplaystore} alt="text" sx={{ py: '10px' }} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>


    </Grid>


  );
}


function Natural(props) {

  const navigate = useNavigate();
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{}} >
      <Box sx={{ width: "100%", ":hover": { boxShadow: 10 }, borderRadius: "10px" }} >
        <img style={{ width: '100%', cursor: "pointer", borderRadius: "10px" }} src={props.item.naturalimage} alt="text" onClick={() => {
          navigate("/knowledgeBase/risk-by-location-detail", { state: props.item })
            ;
        }} />
        <Box sx={{ opacity: "0.7", background: "#000000BA 0% 0% no-repeat padding-box", marginTop: "-49px", borderRadius: "0px 0px 10px 10px", width: '100%' }}>
          <Typography className='primary-font-bold5' sx={{ color: "#FFFFFF", textAlign: 'center', width: '100%', py: "7px", opacity: "1" }}> {props.item.name} </Typography>
        </Box>
      </Box>
    </Grid>


  )
}
function Manual(props) {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{}} >
      <Box sx={{ width: "100%", ":hover": { boxShadow: 10 }, borderRadius: "10px" }} >
        <img style={{ width: '100%', cursor: "pointer", borderRadius: "10px" }} src={props.item.image} alt="text" onClick={() => {
          navigate("/knowledgeBase/risk-by-location-detail", { state: props.item })
            ;
        }} />
        <Box sx={{ opacity: "0.7", background: "#000000BA 0% 0% no-repeat padding-box", marginTop: "-49px", borderRadius: "0px 0px 10px 10px", width: '100%' }}>
          <Typography className='primary-font-bold5' sx={{ color: "#FFFFFF", textAlign: 'center', width: '100%', py: "7px", opacity: "1" }}> {props.item.name} </Typography>
        </Box>
      </Box>
    </Grid>

  )
}