import axios from 'axios';
import constants from '../../../../config/config';

export const  govermentform = async (data) => {
 
    const endpoint = "v1/enquiry/govt/create"
    try {

        const response = await axios.post(`${constants.endPointUrl[constants.ENVIRONMENT]}${endpoint}`, data);
        return response.data;

      } catch (error) {
        return error;
      }
}

export const  businessform = async (data) => {
  const endpoint = "v1/enquiry/business/create"
  try {

      const response = await axios.post(`${constants.endPointUrl[constants.ENVIRONMENT]}${endpoint}`, data);
      return response.data;

    } catch (error) {
      return error;
    }
}

export const  earlysignupform = async (data) => {
  const headers = {

    "Access-Control-Allow-Headers" : "*",
    "Access-Control-Allow-Origin": "*",
    "Accept": "application/json"
  }
  const endpoint = "v1/enquiry/contactUs/create"
  try {

      const response =await axios.post(`${constants.endPointUrl[constants.ENVIRONMENT]}${endpoint}`, data,{headers});
      return response.data;

    } catch (error) {
      return error;
    }
}