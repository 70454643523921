import React, { useEffect, useState } from 'react';
import Router from "./Routes/Router";
import { BrowserRouter} from "react-router-dom";
import './App.css';
import InitializeModal from '../Pages/Home/components/InitializeModal';
import offerbtn from "../../assets/offer-logo.png"
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { config as i18nextConfig } from '../../translations';
i18n.init(i18nextConfig);

const App = () => {
  localStorage.setItem("offerpopup", true);
 
  return (
    <div>
       <I18nextProvider i18n={i18n}>
      <BrowserRouter>{Router}</BrowserRouter>
        </I18nextProvider> 
    </div>
  )
}

export default App
