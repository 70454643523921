import React from 'react'
import { Box, Grid, Typography, Button } from "@mui/material";
import androidplaystore from "../../../../assets/playstore-android.png"
import appleplaystore from "../../../../assets/appleplaystore.png"
import "../../OurService/styles/services.css"
import { Link } from 'react-router-dom';

const SaftyOfFamilyMembers = () => {


  return (
    <Grid container>
      <Grid container item xs={12} md={12}>
        <Grid container item xs={12} md={12} sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)", height: "200px" }}>
          <Grid item xs={1} md={1} lg={1}></Grid>
          <Grid item xs={10} md={10} lg={10} sx={{ marginY: "auto" }}>
            <Typography className='primary-font-bold7' sx={{ textAlign: "start" }}>SAFETY OF FAMILY MEMBERS</Typography>
          </Grid>
          <Grid item xs={1} md={1} lg={1}></Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}><h3 className='primary-font-bold13 title_style'>Plan to Protect Yourself and Your Family</h3></Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={1} md={1} lg={1}></Grid>
        <Grid item xs={10} md={10} lg={10}><p className='primary-font-normal-11 mrf_contant'>Disasters can strike anywhere, anytime, and without notice. Have you ever thought of how to survive if services such as electricity, gas, food and communications were cut off? How would you react? What is your survival plan?</p>
          <p className='primary-font-normal-11 mrf_contant'>Based on the scenario above, given all the panic and chaos that will surely come, it is imperative to have an Emergency Family Plan - <strong>MYREDFOLDER®</strong> that is quickly accessible and one that is practiced periodically. This will make the difference between families that quickly recover from a disaster and those that do not. It is a documented fact that some families that didn't take the time to prepare are so traumatized by a disaster that they never return to their neighborhood.</p>
        <p className='primary-font-normal-11 mrf_contant'><strong>NEPTA</strong> has taken all of this into consideration and as result developed a cloud-based Emergency Family Plan - <strong>MYREDFOLDER®</strong> that can help you and your family be prepared and recover from disasters.</p>
        </Grid>
        <Grid item xs={1} md={1} lg={1}></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}><h3 className='primary-font-bold13 title_style'>Prepare and Practice</h3></Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={1} md={1} lg={1}></Grid>
        <Grid item xs={10} md={10} lg={10}><p className='primary-font-normal-11 mrf_contant'>Disasters/Emergencies can occur quickly and without warning. It can force you to evacuate your community or confine you to your home. What would you do if basic services such as water, gas, electricity, or telephones were cut off for 72 hours or more? Rescue and relief workers will be on the scene soon enough, but they cannot reach everyone right away. </p>
          <p className='primary-font-normal-11 mrf_contant'>Where will your family be when disaster strikes? How will you find each other? How will you know if your loved ones are safe? Answering these questions is a step in the right direction, but when you do it on your own and a calamity does occur, the scenario you might have in your mind can be totally different from the grim reality. </p>
          <p className='primary-font-normal-11 mrf_contant'>The good news is NEPTA has spent years interviewing insurance companies, firefighters, first responders, and families before and after disasters to ensure that we were developing an emergency plan that would provide complete preparedness, response, and recovery. NEPTA’s (Cloud Based) Emergency Family Continuity Plan (MYREDFOLDER®-Plan), IOS/ANDROID APP (MYREDFOLDER®-APP), and Go KitS, are all expertly designed and work together for the entire families complete preparedness and after disaster recovery </p>
          <p className='primary-font-normal-11 mrf_contant'>In our years of experience, we have proven that an action plan that is quickly accessible and practiced periodically makes the difference between families that quickly recover from a disaster and those that do not. Part of this action plan is having contingency supplies that can mean the very survival of your family. </p>
          <p className='primary-font-normal-11 mrf_contant'>YOUR PETS ARE FAMILY ALSO! This plan wouldn't be complete without considering your pets....after all, pets are family also. Your MYREDFOLDER® plan has a "pet management" section where you can store the photo, name, veterinarian info, medication, diet, microchip information, and more. In the event your pet is missing, you can download or print this information and pass it along to friends, neighbors, and the authorities in order for everyone to assist in location efforts. This is a must-have for pet owners.

          </p>
        </Grid>
        <Grid item xs={1} md={1} lg={1}></Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={1} md={1} lg={1}></Grid>
        <Grid item xs={10} md={10} lg={10}>
          <div style={{marginTop:'40px'}}>
            <Button variant="outlined" style={{ display: "flex", alignItems: 'center', margin:'auto'}} className='order-btn' component={Link}
  to="https://www.thereadystore.com/" target = "_blank" >
            Order your Go Kit and Emergency Food Supplies
          </Button>
          </div></Grid>
        <Grid item xs={1} md={1} lg={1}></Grid>
      </Grid>

      <Grid container item xs={12} md={12} sx={{ height: "150px", background: '#FAFAFB', marginTop: '64px' }} >
        <Grid item xs={1} md={1} lg={1}></Grid>
        <Grid container spacing={3} item xs={10} md={10} lg={10} >
          <Grid item xs={7} md={7} sx={{ marginTop: "61px" }}>
           
            <Typography className='primary-font-medium-7' sx={{ width: "100%", color: "#2B303E",py:"15px", letterSpacing:'0px'}}>Get the App for FREE with your Premium MYREDFOLDER® Plan</Typography>
         
          </Grid>
          <Grid container spacing={3} item xs={5} md={5} className="appstore-container" sx={{ marginY: "auto" }} >
            <Grid item xs={6} md={6}>
              <img className='playstore_icons' src={appleplaystore} alt="text" />
            </Grid>
            <Grid item xs={6} md={6} >
              <img className='playstore_icons' src={androidplaystore} alt="text" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={1} lg={1}></Grid>
      </Grid>



    </Grid>
  )
}

export default SaftyOfFamilyMembers