import React from 'react'
import { Box, Grid, Typography } from "@mui/material";
import mrf from "../../../../assets/our-services-image/header-image/myredfolder-header.png";
import macpic from "../../../../assets/MRF_macpic.png"
import androidplaystore from "../../../../assets/playstore-android.png"
import appleplaystore from "../../../../assets/appleplaystore.png"
import play_button from "../../../../assets/play_button.png"
import { styled } from "@mui/material/styles";
import '../../OurService/styles/services.css'

const MyRedFolder = () => {

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    width: "405px",
    height: "317px"
  });

  return (
    <div>
      <Box>
        <Grid container sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)", }}>
          <div className='plan-header-container'>
            <Grid container item xs={12} md={12} lg={12} >

              <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>

                <Typography className='primary-font-bold7'> MYREDFOLDER© APP</Typography>

              </Grid>
              <Grid item xs={6} md={6} lg={6} className='plan-header-img' >
                <img src={mrf} alt="complex" style={{ width: "100%", height: "100%" }} />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid container>
          <div style={{ width: '100%', }} className='plan-top-container'>
            <Typography className='primary-font-bold13'>MYREDFOLDER© App</Typography>
          </div>
        </Grid>
        <div className='app-header-container'>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <p className='primary-font-normal-11 mrf_contant'>
                Meet the Smith family. They were a typical American family living in a quiet suburban neighborhood. One evening, as they were enjoying dinner together, a sudden storm hit their area. The power went out, and they could hear the wind howling outside. It was clear that this was no ordinary storm; it was a nearby tornado.</p>
              <p className='primary-font-normal-11 mrf_contant'>
                As the night progressed, the situation became more dire as flash flood warnings were issued. The Smiths realized they needed to evacuate their home for safety. In the midst of chaos, they grabbed their essentials but had no clear plan. They were anxious, unsure of where to go or what to do next. That's when they remembered the MYREDFOLDER® App they had recently downloaded.</p>
              <p className='primary-font-normal-11 mrf_contant'>With trembling fingers, they opened the app and quickly accessed the "Emergency Plan" section. There, they found step-by-step instructions on what to do during a hurricane evacuation, including evacuation routes, designated meeting points, and a checklist of essential supplies. </p>
              <p className='primary-font-normal-11 mrf_contant'>Using the app, they were able to locate the nearest shelter and confirm its availability. They also received real-time updates on the storm's progress and important alerts from local authorities. The MYREDFOLDER® App transformed their chaotic situation into a well-organized and informed response.</p>
              <Grid
                container
                sx={{ pt: "30px", display:'flex', flexDirection:'row', justifyContent:'center',    width: "-webkit-fill-available" }}
             >
                <div className='laptop-video'>
                  <Grid container sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <div style={{ display: 'flex', flexDirection: 'column',marginBottom:'20px' }} >
                      <Typography className="primary-font-bold-disater" style={{marginLeft:'10px', marginBottom:'10px', textAlign: "left !important" }}>MYREDFOLDER Importance </Typography>
                      <div class="video-container">
                        <iframe src="https://player.vimeo.com/video/270231514?h=8d37473ca6&title=0&byline=0&portrait=0" frameborder="0" class="videos" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column',marginBottom:'20px' }}  >
                      <Typography className="primary-font-bold-disater" style={{marginLeft:'10px', marginBottom:'10px', textAlign: "left !important" }}>MYREDFOLDER | FAMILY </Typography>
                      <div class="video-container">
                        <iframe src="https://player.vimeo.com/video/136259404?h=0719844c70&title=0&byline=0&portrait=0" frameborder="0" class="videos" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>
                      </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column',marginBottom:'10px' }}  >
                      <Typography className="primary-font-bold-disater" style={{marginLeft:'10px', marginBottom:'10px', textAlign: "left !important" }}>Now My Family is Prepared! </Typography>
                      <div class="video-container">
                        <iframe src="https://player.vimeo.com/video/112245946?h=05eb48f86d&title=0&byline=0&portrait=0" frameborder="0" class="videos" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>
                      </div>
                    </div>

                  </Grid>
                </div>

                <div className='mobile-video'>
                  <Grid container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <div style={{ display: 'flex', flexDirection: 'column',marginBottom:'20px' }} >
                      <Typography className="primary-font-bold-disater" style={{marginLeft:'10px', marginBottom:'10px', textAlign: "left" }}>MYREDFOLDER Importance </Typography>
                      <div class="video-container">
                        <iframe src="https://player.vimeo.com/video/270231514?h=8d37473ca6&title=0&byline=0&portrait=0" frameborder="0" class="videos" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column',marginBottom:'20px' }}  >
                      <Typography className="primary-font-bold-disater" style={{marginLeft:'10px', marginBottom:'10px', textAlign: "left" }}>MYREDFOLDER | FAMILY </Typography>
                      <div class="video-container">
                        <iframe src="https://player.vimeo.com/video/136259404?h=0719844c70&title=0&byline=0&portrait=0" frameborder="0" class="videos" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>
                      </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column',marginBottom:'10px' }}  >
                      <Typography className="primary-font-bold-disater" style={{marginLeft:'10px', marginBottom:'10px', textAlign: "left" }}>Now My Family is Prepared! </Typography>
                      <div class="video-container">
                        <iframe src="https://player.vimeo.com/video/112245946?h=05eb48f86d&title=0&byline=0&portrait=0" frameborder="0" class="videos" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen ></iframe>
                      </div>
                    </div>

                  </Grid>
                </div>

              </Grid>
              <p className='primary-font-bold5 mrf_contant'>Features and Benefits of the MYREDFOLDER® App:</p>

              <p className='primary-font-bold5 mrf_contant'>Customized Emergency Plans: <span className='primary-font-normal-11 mrf_contant'> Tailor emergency plans to your family's specific needs, including medical conditions and special requirements.</span></p>
              <p className='primary-font-bold5 mrf_contant'>Real-time Alerts: <span className='primary-font-normal-11 mrf_contant'>Receive immediate alerts and updates from official sources about weather conditions, disaster warnings, and critical information.</span></p>
              <p className='primary-font-bold5 mrf_contant'>Family Locator: <span className='primary-font-normal-11 mrf_contant'>Locate and connect with family members in real-time, ensuring everyone's safety and well-being. </span></p>
              <p className='primary-font-bold5 mrf_contant'> Communication Hub:<span className='primary-font-normal-11 mrf_contant'> Access alternative communication protocols and emergency contact lists to stay in touch during crises</span></p>
              <p className='primary-font-bold5 mrf_contant'>  Resource Repository: <span className='primary-font-normal-11 mrf_contant'>Find up-to-date information on evacuation routes, nearby shelters, and emergency services. </span></p>
              <p className='primary-font-bold5 mrf_contant'> Integration with Emergency Services:<span className='primary-font-normal-11 mrf_contant'> Call for assistance directly from the app, locate the nearest medical facilities, and access first-aid guidelines.</span></p>
              <p className='primary-font-bold5 mrf_contant'> Cloud Storage:<span className='primary-font-normal-11 mrf_contant'> Safeguard your home inventory, including purchase prices and replacement values, making insurance claims smoother.</span></p>
              <p className='primary-font-bold5 mrf_contant'> User-Friendly Interface: <span className='primary-font-normal-11 mrf_contant'>Navigate with ease, even in high-stress situations, ensuring quick access to vital features.</span></p>
              <p className='primary-font-bold5 mrf_contant'>Document Storage: <span className='primary-font-normal-11 mrf_contant'> Securely store important documents such as passports, insurance policies, and medical records in the app, ensuring quick access when needed.</span></p>
              <p className='primary-font-bold5 mrf_contant'>Emergency Services Integration: <span className='primary-font-normal-11 mrf_contant'> Easily call 911 or other emergency services directly from the app, with your location information readily available for a faster response.</span></p>
              <p className='primary-font-bold5 mrf_contant'>Family Communication: <span className='primary-font-normal-11 mrf_contant'>Set up a private family communication network within the app, allowing real-time updates and messages during emergencies. </span></p>
              <p className='primary-font-bold5 mrf_contant'>Emergency Contacts: <span className='primary-font-normal-11 mrf_contant'> Maintain a list of emergency contacts, including neighbors, friends, and family members, for quick and easy outreach.</span></p>
              <p className='primary-font-bold5 mrf_contant'> Checklists and Reminders:  <span className='primary-font-normal-11 mrf_contant'>Create and customize checklists for emergency supplies, ensuring that you have everything you need when disaster strikes.</span></p>
              <p className='primary-font-bold5 mrf_contant'> Offline Access: <span className='primary-font-normal-11 mrf_contant'>Access critical information and plans even without an internet connection, ensuring functionality in low-connectivity situations.</span></p>
              <p className='primary-font-bold5 mrf_contant'>  Multi-Platform Compatibility:<span className='primary-font-normal-11 mrf_contant'> Enjoy the convenience of the MYREDFOLDER® App on various devices, including smartphones, tablets, and desktop computers.</span></p>
              <p className='primary-font-bold5 mrf_contant'> Secure Data Encryption:<span className='primary-font-normal-11 mrf_contant'>Rest assured that your sensitive information is encrypted and protected, ensuring the highest level of security for your family's data.</span></p>
              <p className='primary-font-bold5 mrf_contant'> Updates and Enhancements: <span className='primary-font-normal-11 mrf_contant'> Benefit from regular app updates and enhancements to stay current with the latest emergency protocols and features.</span></p>
              <p className='primary-font-bold5 mrf_contant'>Educational Resources: <span className='primary-font-normal-11 mrf_contant'> Access educational materials, articles, and videos on emergency preparedness, enhancing your family's knowledge and readiness.</span></p>
              <p className='primary-font-bold5 mrf_contant'> Peace of Mind: <span className='primary-font-normal-11 mrf_contant'>
                Above all, the MYREDFOLDER® App offers peace of mind, knowing that you have a comprehensive tool at your fingertips to navigate and manage emergencies effectively, keeping your family safe and secure.</span></p>
              <p className='primary-font-normal-11 mrf_contant'> The MYREDFOLDER® App empowers families like the Smiths to be prepared, connected, and informed during emergencies. It's the ultimate tool for ensuring your family's safety and well-being, providing peace of mind in times of crisis.</p>
            </Grid>
          </Grid>
        </div>
        <Grid sx={{background:"#FAFAFB"}}  >
         <div className='plan-header-container'>
        <Grid container className='bottom-container' >
        {/* <Grid container  item xs={12} md={12} lg={12}  > */}
          <Grid item xs={12} md={7}  sm={7} >
            <Typography className='primary-font-medium-7 bottom-text'  sx={{ width: "100%", color: "#2B303E",letterSpacing:'0px',}}>Get the App for FREE with your Premium MYREDFOLDER® Plan</Typography>
          </Grid>
          <Grid container spacing={1} item xs={12} md={5} sm={5} className="appstore-container" sx={{ marginY: "auto" }} >
            <Grid item xs={6} md={6} sm={6} >
              <img className='playstore_icons' src={appleplaystore} alt="text" />
            </Grid>
            <Grid item  className='icon-img' xs={6} md={6} sm={6} >
              <img className='playstore_icons' src={androidplaystore} alt="text" sx={{py:'10px'}} />
            </Grid>
          </Grid>
        {/* </Grid> */}
        </Grid>
        </div>
        </Grid>

      </Box>
    </div>
  )
}

export default MyRedFolder;