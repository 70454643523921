import React from 'react'
import ContentWrapper from '../../../../common/components/ContentWrapper';
import RiskBasedOnLocation from '../../OurService/components/RiskBasedOnMyLocation';


export default function RiskbasedlocationIndex() {
  return (
    <div>
    <ContentWrapper contenview={<RiskBasedOnLocation/>} />
    </div>
  )
}
