import React from 'react'
import OurTeamComponent from './OurTeamComponent'
const OurTeamsComponent = () => {
  return (
    <div>
    <OurTeamComponent/>
    </div>
  )
}

export default OurTeamsComponent
