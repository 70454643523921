import React from 'react';
import { Grid, Typography, CardContent, Card, CardActionArea, Button, Box, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import emergencySupply from "../../../../assets/our-services-image/go-kit.png";
import storageFood from "../../../../assets/our-services-image/storage-food.png";
import gokit from "../../../../assets/our-services-image/supply-kits.png";
import headerImage from "../../../../assets/our-services-image/header-image/supplykits-header.png";
import androidplaystore from "../../../../assets/playstore-android.png"
import appleplaystore from "../../../../assets/appleplaystore.png"
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import "../styles/supplyKits.css"


const SupplyKits = () => {

  const navigate = useNavigate();

  const emergencyKits = ["Cash ($150 if possible)",
    "Bottled Water (We recommend 8-10 bottles depending on the size of each family member's Go Kit bag/backpack)", "Flashlight", "Additional Medication",
    "First Aid Kit Blanket / Sleeping Bag", "Rain Gear", "Baseball or Winter Cap",
    "Extra set of clothing or two", "Special needs supplies", "Childcare supplies",
    "Dried fruit and nuts", "Enough canned goods to last 72 hours", "A can opener",
    "Comfortable shoes and extra socks", "Personal Hygiene items", "Passport, ID, or a copy of each",
    "Whistle", "City/County Map", "Notebook, Pencil", "Multi-function tool / Knife",
    "Matches, compass, Candles"];
  const supplyKits = [
    "Battery Operated AM/FM Radio", "Flash Lights", "First Aid Kit",
    "Personal Hygiene Items", "Cell Phone", "Special Needs Supplies",
    "Child Care Supplies", "One Gallon of water per person per day",
    "Bleach – use for purification of water.", "Enough Food for Two Weeks – can goods, dried foods, etc",
    "Water Purification Tablets", "Matches, Candles", "Games for Kids, Deck of Playing Cards"];


  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    window.scroll({
      top:200,
       left:0
   
    });
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{
          borderTop: 'none !important',
        }}
      >
        {value === index && (
          <Grid sx={{ paddingTop: 0 }}>
            <Typography>{children}</Typography>
          </Grid>
        )}
      </div>
    );
  }

  return (
    <div>

      <Grid container sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)", }}>
        <div className='ourservices-header-container'>
          <Grid container item xs={12} md={12} lg={12} >

            <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>

              <Typography className='primary-font-bold7'>    GO KITS / SUPPLY KITS</Typography>

            </Grid>
            <Grid item xs={6} md={6} lg={6} className='ourservices-header-img' >
              <img src={headerImage} alt="supplykits" style={{ width: "100%", height: "100%" }} />
            </Grid>

          </Grid>
        </div>
      </Grid>
      <Grid>
        <Grid container lg={12} md={12} xs={12}>
          <div className='ourservices-header-container'>
            <Grid lg={12} md={12} xs={12}>
              <Grid className='emergency-title'>
                <Typography style={{ textAlign: "center" }} className='primary-font-bold12'>Family Emergency Kit</Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid>
        <Grid container lg={12} md={12} xs={12} style={{ }} className='emergency-middle-content'>
          <div className='ourservices-header-container'>
            <Grid lg={12} md={12} xs={12} >
              <Typography className='primary-font-normal-11' >
                Emergency GO Kits, Supply Kits, and emergency food storage are essential components of any family's preparedness strategy. They serve as the backbone of your ability to weather unforeseen disasters and emergencies. These items are not just important but truly critical for every family:
              </Typography>

            </Grid>
          </div>
        </Grid>

        <Grid container style={{ backgroundColor: "" }}>
          <div className='ourservices-header-container'>

            <Grid container lg={12} md={12} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}  >
              <Grid 
              className='laptop-view'
              >
                <Tabs
                  value={value}
                  onChange={handleChange1}
                  variant="fullWidth"
                  TabIndicatorProps={{ style: { background: "red" } }}
                  aria-label="basic tabs"

                >
                  <Tab
                    sx={{
                      width: "100%",
                      height: "80px",
                      color: value === 0 ? 'red !important' : '#000',
                      backgroundColor: value === 0 ? 'white' : '#F8F8F8',
                      border: '1px solid #C7CBCF',
                      // borderRadius: '10px 10px 0px 0px',
                      // borderBottom: value === 0 ? 'none' : '1px solid #C7CBCF',
                      // mr: '10px',
                    }}
                    label={<Typography className='primary-font-bold4' sx={{ textAlign: "center", }} textTransform="none"> Emergency GO Kits </Typography>} />
                  <Tab
                    sx={{
                      width: "100%",
                      height: "80px",
                      color: value === 1 ? 'red !important' : '#000',
                      backgroundColor: value === 0 ? 'white' : '#F8F8F8',
                      border: '1px solid #C7CBCF',
                      // borderRadius: '10px 10px 0px 0px',
                      // borderBottom: value === 0 ? 'none' : '1px solid #C7CBCF',
                      // mr: '10px',
                    }}
                    label={<Typography className='primary-font-bold4' sx={{ textAlign: "center", }} textTransform="none"> Supply Kits </Typography>} />
                  <Tab
                    sx={{
                      width: "100%",
                      height: "80px",
                      color: value === 2 ? 'red !important' : '#000',
                      backgroundColor: value === 0 ? 'white' : '#F8F8F8',
                      border: '1px solid #C7CBCF',
                      // borderRadius: '10px 10px 0px 0px',
                      // borderBottom: value === 0 ? 'none' : '1px solid #C7CBCF',
                      // mr: '10px',
                    }}
                    label={<Typography className='primary-font-bold4' sx={{ textAlign: "center", }} textTransform="none"> Emergency Food  Storage </Typography>} />
                </Tabs>
              </Grid>
              <Grid
               className='laptop-view'
               >
                <CustomTabPanel value={value} index={0}>
                  <Grid sx={{ background: '#fff', border: '1px solid #C7CBCF', padding: '10px' }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                      <Grid>
                        <img src={gokit} alt="emerengy-kits" className="emergency-img" />
                      </Grid>
                      <Typography className='primary-font-bold12 tab-middle-title'>Emergency GO Kits</Typography>
                    </Grid>
                    <Grid sx={{ marginBottom: '10px' }}>
                      <Typography sx={{ mt: "10px" }} className='primary-font-medium-1'>Imagine a scenario where you and your family need to evacuate your home quickly due to a wildfire, hurricane, or other imminent threat. In such situations, time is of the essence, and having a well-prepared GO Kit can make all the difference:</Typography>
                      <Typography sx={{ mt: "10px" }} className='primary-font-bold4'>Critical Components:</Typography>
                      <ul className='bullet-list'>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Essential Supplies: GO Kits typically contain necessities like water, non-perishable food, clothing, and first-aid items. These supplies are vital to sustaining your family's immediate needs during the initial hours of evacuation.</div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Important Documents: Copies of important documents such as passports, IDs, insurance policies, and medical records should be readily available in case you need to prove your identity or access medical care.</div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Communication Tools: Having a charged mobile phone, a portable charger, and a list of emergency contacts can help you stay in touch and receive critical updates.</div>
                        </div>
                      </ul>

                      <Typography className='primary-font-bold4'>Benefits:</Typography>
                      <ul className='bullet-list'>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Quick Response: GO Kits enable your family to respond swiftly to evacuation orders, reducing the risk of being caught unprepared. </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Self-Reliance: By having essential supplies on hand, you become less reliant on external aid during the initial stages of an emergency.</div>
                        </div>
                      </ul>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Grid sx={{ background: '#fff', border: '1px solid #C7CBCF', padding: '10px' }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                      <Grid>
                        <img src={emergencySupply} alt="emerengy-kits" className="emergency-img" />
                      </Grid>
                      <Typography className='primary-font-bold12 tab-middle-title'> Supply Kits</Typography>
                    </Grid>
                    <Grid sx={{ marginBottom: '10px' }}>
                      <Typography sx={{ mt: "10px" }} className='primary-font-medium-1'>Supply Kits go beyond the immediate needs covered by GO Kits. They are designed to sustain your family's well-being for a more extended period if you find yourselves cut off from regular resources:</Typography>
                      <Typography sx={{ mt: "10px" }} className='primary-font-bold4'>Critical Components:</Typography>
                      <ul className='bullet-list'>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Additional Supplies: Supply Kits include a broader range of supplies, such as tools, hygiene products, cooking utensils, and more extensive first-aid kits.</div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'> Shelter: Tents or tarps, sleeping bags, and warm clothing can help protect your family from the elements in case you can't return home.</div>
                        </div>
                      </ul>

                      <Typography className='primary-font-bold4'>Benefits:</Typography>
                      <ul className='bullet-list'>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Sustained Comfort: Supply Kits ensure your family's comfort and well-being during longer-term disruptions, such as power outages or extended evacuations.</div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'> Resourcefulness: With a well-equipped Supply Kit, you can adapt to changing circumstances and challenges with greater ease.</div>
                        </div>
                      </ul>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Grid sx={{ background: '#fff', border: '1px solid #C7CBCF', padding: '10px' }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                      <Grid>
                        <img src={storageFood} alt="emerengy-kits" className="emergency-img" />
                      </Grid>
                      <Typography className='primary-font-bold12 tab-middle-title'>  Emergency Food  Storage</Typography>
                    </Grid>
                    <Grid sx={{ marginBottom: '10px' }}>
                      <Typography sx={{ mt: "10px" ,}} className='primary-font-medium-1'>During extended emergencies or disasters, access to food may become limited or uncertain. Emergency food storage is your safety net:</Typography>
                      <Typography sx={{ mt: "10px" }} className='primary-font-bold4'>Critical Components:</Typography>
                      <ul className='bullet-list'>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Long-Term Food Supply: Emergency food storage typically consists of freeze-dried or dehydrated foods that have a long shelf life and provide the necessary nutrition for your family. </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Variety: These storage options often come in a variety of meal choices, ensuring that you maintain a balanced diet even in crisis situations.</div>
                        </div>
                      </ul>

                      <Typography className='primary-font-bold4'>Benefits:</Typography>
                      <ul className='bullet-list'>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Food Security: With a stocked emergency food supply, you reduce the risk of going hungry during extended emergencies. </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div className='primary-font-medium-1'>Peace of Mind: Knowing you have a stable food source allows you to focus on other aspects of survival and recovery.</div>
                        </div>
                      </ul>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
              </Grid>
            </Grid>
          </div>

        </Grid>
        <div className='mobile-view'>
          <div className='ourservices-header-container'>
            <Accordion sx={{
              '&.MuiPaper-root.MuiAccordion-root.Mui-expanded':{
                margin:"0px !important"
              }

            }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                        expandIcon={
                          expanded === 'panel1' ? (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr:"-24px"
                              }}
                            >
                              <RemoveIcon sx={{ color: 'white' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{
                                backgroundColor: 'black !important',
                                width: '25px',
                                height: '25px',
                                mr:"-24px"
                              }}
                            >
                              <AddIcon sx={{ color: 'white' }} />
                            </IconButton>
                          )
                        } >
                <Typography className='primary-font-bold4' sx={{textTransform:"none !important"}}>Emergency GO Kits</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px 10px 5px !important" }}>
       
                <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                  <Grid>
                    <img src={gokit} alt="emerengy-kits" className="emergency-img" />
                  </Grid>
                  <Typography className='primary-font-bold12'>Emergency GO Kits</Typography>
                </Grid>
                <Grid sx={{ marginBottom: '10px' }}>
                  <Typography sx={{ mt: "10px" }} className='primary-font-medium-1'>Imagine a scenario where you and your family need to evacuate your home quickly due to a wildfire, hurricane, or other imminent threat. In such situations, time is of the essence, and having a well-prepared GO Kit can make all the difference:</Typography>
                  <Typography sx={{ mt: "10px" }} className='primary-font-bold4'>Critical Components:</Typography>
                  <ul className='bullet-list'>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Essential Supplies: GO Kits typically contain necessities like water, non-perishable food, clothing, and first-aid items. These supplies are vital to sustaining your family's immediate needs during the initial hours of evacuation.</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Important Documents: Copies of important documents such as passports, IDs, insurance policies, and medical records should be readily available in case you need to prove your identity or access medical care.</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Communication Tools: Having a charged mobile phone, a portable charger, and a list of emergency contacts can help you stay in touch and receive critical updates.</div>
                    </div>
                  </ul>

                  <Typography className='primary-font-bold4'>Benefits:</Typography>
                  <ul className='bullet-list'>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Quick Response: GO Kits enable your family to respond swiftly to evacuation orders, reducing the risk of being caught unprepared. </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Self-Reliance: By having essential supplies on hand, you become less reliant on external aid during the initial stages of an emergency.</div>
                    </div>
                  </ul>
                </Grid>
       
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{
               '&.MuiPaper-root.MuiAccordion-root.Mui-expanded':{
                margin:"0px !important"
              } 
         
              }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
      
                expandIcon={
                  expanded === 'panel2' ? (
                    <IconButton
                      sx={{
                        backgroundColor: 'black !important',
                        width: '25px',
                        height: '25px',
                        mr:"-24px"
                      }}
                    >
                      <RemoveIcon sx={{ color: 'white' }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{
                        backgroundColor: 'black !important',
                        width: '25px',
                        height: '25px',
                        mr:"-24px"
                      }}
                    >
                      <AddIcon sx={{ color: 'white' }} />
                    </IconButton>
                  )
                } 


                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className='primary-font-bold4' sx={{textTransform:"none"}}> Supply Kits</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px 10px 5px !important" }}>
         
                <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <Grid>
                    <img src={emergencySupply} alt="emerengy-kits" className="emergency-img" />
                  </Grid>
                  <Typography className='primary-font-bold12'> Supply Kits</Typography>
                </Grid>
                <Grid sx={{ marginBottom: '10px' }}>
                  <Typography sx={{ mt: "10px" }} className='primary-font-medium-1'>Supply Kits go beyond the immediate needs covered by GO Kits. They are designed to sustain your family's well-being for a more extended period if you find yourselves cut off from regular resources:</Typography>
                  <Typography sx={{ mt: "10px" }} className='primary-font-bold4'>Critical Components:</Typography>
                  <ul className='bullet-list'>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Additional Supplies: Supply Kits include a broader range of supplies, such as tools, hygiene products, cooking utensils, and more extensive first-aid kits.</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'> Shelter: Tents or tarps, sleeping bags, and warm clothing can help protect your family from the elements in case you can't return home.</div>
                    </div>
                  </ul>

                  <Typography className='primary-font-bold4'>Benefits:</Typography>
                  <ul className='bullet-list'>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Sustained Comfort: Supply Kits ensure your family's comfort and well-being during longer-term disruptions, such as power outages or extended evacuations.</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'> Resourcefulness: With a well-equipped Supply Kit, you can adapt to changing circumstances and challenges with greater ease.</div>
                    </div>
                  </ul>
                </Grid>
            
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{
                 '&.MuiPaper-root.MuiAccordion-root.Mui-expanded':{
                  margin:"0px !important"
                }
           
               }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
              
                expandIcon={
                  expanded === 'panel3' ? (
                    <IconButton
                      sx={{
                        backgroundColor: 'black !important',
                        width: '25px',
                        height: '25px', 
                        mr:"-24px"  
                      }}
                    >
                      <RemoveIcon sx={{ color: 'white' }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{
                        backgroundColor: 'black !important',
                        width: '25px',
                        height: '25px',
                        mr:"-24px"
                      }}
                    >
                      <AddIcon sx={{ color: 'white' }} />
                    </IconButton>
                  )
                } 
                

                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className='primary-font-bold4' sx={{textTransform:"none"}}>  Emergency Food  Storage</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px 10px 5px !important" }}>
   
                <Grid sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                  <Grid>
                    <img src={storageFood} alt="emerengy-kits" className="emergency-img" />
                  </Grid>
                  <Typography className='primary-font-bold12'>  Emergency Food  Storage</Typography>
                </Grid>
                <Grid sx={{ marginBottom: '10px' }}>
                  <Typography sx={{ mt: "10px" }} className='primary-font-medium-1'>During extended emergencies or disasters, access to food may become limited or uncertain. Emergency food storage is your safety net:</Typography>
                  <Typography sx={{ mt: "10px" }} className='primary-font-bold4'>Critical Components:</Typography>
                  <ul className='bullet-list'>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Long-Term Food Supply: Emergency food storage typically consists of freeze-dried or dehydrated foods that have a long shelf life and provide the necessary nutrition for your family. </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Variety: These storage options often come in a variety of meal choices, ensuring that you maintain a balanced diet even in crisis situations.</div>
                    </div>
                  </ul>

                  <Typography className='primary-font-bold4'>Benefits:</Typography>
                  <ul className='bullet-list'>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Food Security: With a stocked emergency food supply, you reduce the risk of going hungry during extended emergencies. </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div> <li className='list' /> </div>
                      <div className='primary-font-medium-1'>Peace of Mind: Knowing you have a stable food source allows you to focus on other aspects of survival and recovery.</div>
                    </div>
                  </ul>
                </Grid>
      
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className='ourservices-header-container'>
          <Grid container lg={12} md={12} xs={12}>

            <Grid lg={12} md={12} xs={12} sx={{ mt: "40px" }}>

              <Typography className='primary-font-normal-11'>Emergency GO Kits, Supply Kits, and emergency food storage are critical because they provide the means for your family to respond swiftly and sustainably during crises. They offer not only immediate relief but also long-term security, ensuring that you're prepared for whatever challenges may arise. These preparations are an investment in your family's safety and well-being during uncertain times.</Typography>

            </Grid>
          </Grid>
          <Grid container lg={12} md={12} xs={12} style={{ backgroundColor: "" }}>
            <Grid lg={12} md={12} xs={12} className='btn-container'>

              <Button variant="outlined" style={{ display: "flex", alignItems: 'center', margin: 'auto' }} className='order-btn' component={Link}
                to="https://www.thereadystore.com/" target='_blank'>
                ORDER NOW – CLICK HERE
              </Button>
              
            </Grid>

          </Grid>
        </div>
        <Grid sx={{background:"#FAFAFB"}}  >
         <div className='plan-header-container'>
        <Grid container className='bottom-container' >
        {/* <Grid container  item xs={12} md={12} lg={12}  > */}
          <Grid item xs={12} md={7}  sm={7} >
            <Typography className='primary-font-medium-7 bottom-text'  sx={{ width: "100%", color: "#2B303E",letterSpacing:'0px',}}>Get the App for FREE with your Premium MYREDFOLDER® Plan</Typography>
          </Grid>
          <Grid container spacing={1} item xs={12} md={5} sm={5} className="appstore-container" sx={{ marginY: "auto" }} >
            <Grid item xs={6} md={6} sm={6} >
              <img className='playstore_icons' src={appleplaystore} alt="text" />
            </Grid>
            <Grid item  className='icon-img' xs={6} md={6} sm={6} >
              <img className='playstore_icons' src={androidplaystore} alt="text" sx={{py:'10px'}} />
            </Grid>
          </Grid>
        {/* </Grid> */}
        </Grid>
        </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SupplyKits;