import React ,{useEffect, useState}from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import CarouselSlider from './CarouselSlider';
import AboutUs from './AboutUs';
import OurServices from './OurServices';
import Events from './Events';
import Plans from './Plans';
import NaturalDisasters from './NaturalDisasters';
import InitializeModal from './InitializeModal';
import AsSeenOn from './AsSeenOn';
// import AppVideo  from "./AppVideo"

const HomeComponent = () => {
  return (
    <>
      <CarouselSlider />
      <AboutUs />
      <OurServices />
      {/* <AppVideo /> */}
      <Events />
      <Plans />
      <NaturalDisasters />
      <AsSeenOn/>
    </>
  )
}

export default HomeComponent
