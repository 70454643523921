import React, { useState } from 'react';
// import Grid from "@mui/material/Unstable_Grid2";

import "./Footer.css";
// import '../../../../containers/App/App.css';

import MYREDFOLDER from "../../../../assets/MyRedFolderLogo.png";
import TrustedSite from "../../../../assets/trusted-site@2x.png";
import MCAFEE from "../../../../assets/mcafee secure logo@2x.png";
// import GoDaddy from "../../../../assets/godaddy logo.png";
import email from "../../../../assets/icons/email-contact-icon.svg";
import phone from "../../../../assets/icons/phone-icon.svg";
import location from "../../../../assets/icons/location-icon.svg";

import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Typography, IconButton, Collapse, Box, Grid } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';




const Footer = () => {
  const navigate = useNavigate();
  const myredfolder = [
    {
      text: "About Us",
      path: "/aboutUs/our-history",
    },
    {
      text: "Pricing",
      path: "/pricing",
    }
    ,
    {
      text: "Contact Us",
      path: "/aboutUs/contact-us",

    }
  ]
  const ourServices = [

    {

      text: "MYREDFOLDER© APP",
      path: "/ourServices/my-mrf-app",
    },
    {

      text: "MYREDFOLDER® Plan",
      path:'/ourServices/my-mrf-plan'
    },
    {

      text: "Go Kits / Supply Kits",
      path: "/ourServices/supply-kits",
    },
  ]
  const knowledgeBase = [
    {
      text: "Disaster Resource Library",
      path:'/knowledgeBase/risk-by-location',
    },
    {
      text: "Blog",
      path: "/knowledgeBase/blog",
    }, {
      text: "FAQ",
      path: "/knowledgeBase/faq",
    },
    {
      text: "MYREDFOLDER® Tribe",
      path:'/knowledgeBase/myredfolder-tribe'
    }

  ]
  return (
    <>
      <Box sx={{ background: "#e9e9e9" }}>
        <div className="container-footer">

          <Grid container spacing={2} className="footer__topbar">
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
            <Grid

              xs={12}
              md={12}
              lg={12}
              className="footer topbar-imgcontainer"
            // style={{ display: "flex", justifyContent: "space-between" }}
            >

              <img
                src={TrustedSite}
                alt="text"
                className="footer trustedsite-img"
              // style={{ width: "350px", height: "58px" }}
              />

              <img
                src={MCAFEE}
                alt="text"
                className="footer mcafee-img"
              // style={{ width: "194px", height: "70px" }}
              />

              {/* <div>
            <img
              src={GoDaddy}
              alt="text"
              style={{ width: "297px", height: "70px" }}
            />
          </div> */}
            </Grid>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
          </Grid>
        </div>
      </Box>

      <Box >
        <div className="container-footer">

          <Grid container>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className="footer topbarmenu-container"

            // style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className='footer  footer-middle-container' >
                <Box
                  id="category-a"
                  sx={{ fontSize: "12px", textTransform: "uppercase", pt: "30px" }}
                >
                  <img src={MYREDFOLDER} alt="myredfolderlogo" id="mrflogo-footer" />
                </Box>
                <Box
                  component="ul"
                  className="list-component"
                  aria-labelledby="category-a"
                  sx={{ pl: 2 }}
                >
                  <li className="li-icon-alignment" style={{ maxWidth: '235px' }}>
                    <div>
                      <img src={location} alt="address" style={{ marginLeft: "10px", marginTop: "-5px" }} />
                    </div>
                    <Typography className="primary-font-normal-2">
                      255 Primera Blvd. Ste, 160 Lake Mary, FL 32746
                    </Typography>
                  </li>
                  <li className="li-icon-alignment">
                    <div>
                      <img src={phone} alt="phone number" style={{ marginLeft: "10px", marginTop: "-5px" }} />
                    </div>
                    <Typography className="primary-font-normal-2">
                      + (888) 267 9990
                    </Typography>
                  </li>
                  <li className="li-icon-alignment">
                    <div>
                      <img src={email} alt="email id" style={{ marginLeft: "10px", marginTop: "-5px" }} />
                    </div>
                    <Typography className="primary-font-normal-2">
                      info@nepta.us
                    </Typography>
                  </li>
                </Box>
              </div>
              <Box className="small-size" sx={{ mb: "30px", ml: "10px" }}>
                <ExpandableSection title="MYREDFOLDER" subtitle={myredfolder} />

                <ExpandableSection title="Our Services" subtitle={ourServices} />
                <ExpandableSection title="Knowledge Base" subtitle={knowledgeBase} />
              </Box>


              <div className='big-size' style={{ paddingTop: "40px" }}>
                <Box id="category-b">
                  <Typography className="footer-services-title primary-font-medium-2">
                    MYREDFOLDER
                  </Typography>
                  <hr className="footer-services-title-hr" />
                </Box>
                <Box
                  component="ul"
                  aria-labelledby="category-b"
                  sx={{ pl: 2 }}
                  style={{ paddingLeft: "0px" }}
                >
                  <li className="li-text-alignment primary-font-normal-1"
                   onClick={()=>{
                     navigate("/aboutUs/our-history")
                      }}
                  >About Us</li>
                  <li className="li-text-alignment primary-font-normal-1"
                   onClick={()=>{
                     navigate("/pricing")
                   }}
                  >Pricing </li>
                  <li className="li-text-alignment primary-font-normal-1"
                   onClick={()=>{
                     navigate("/aboutUs/contact-us")}}
                  >Contact Us</li>
                </Box>
              </div>
              <div className='big-size' style={{ paddingTop: "40px" }}>
                <Box id="category-c">
                  <Typography className="footer-services-title primary-font-medium-2">
                    Our Services
                  </Typography>
                  <hr className="footer-services-title-hr" />
                </Box>
                <Box
                  component="ul"
                  aria-labelledby="category-c"
                  sx={{ pl: 2 }}
                  style={{ paddingLeft: "0px" }}
                >
                  <li className="li-text-alignment primary-font-normal-1"
                   onClick={()=>{navigate("/ourServices/my-mrf-app") }}
                  >MYREDFOLDER© App</li>
                  <li className="li-text-alignment primary-font-normal-1"
                   onClick={()=>{navigate("/ourServices/my-mrf-plan") }}
                  >MYREDFOLDER© Plan</li>
                  <li className="li-text-alignment primary-font-normal-1"
                   onClick={()=>{navigate("/ourServices/supply-kits") }}
                  >Go Kits /Supply Kits</li>
                </Box>
              </div>
              <div className='big-size' style={{ paddingTop: "40px" }}>
                <Box id="category-c">
                  <Typography className="footer-services-title primary-font-medium-2">
                    Knowledge Base
                  </Typography>
                  <hr className="footer-services-title-hr" />
                </Box>
                <Box
                  component="ul"
                  aria-labelledby="category-c"
                  sx={{ pl: 2 }}
                  style={{ paddingLeft: "0px" }}
                >
                  <li className="li-text-alignment primary-font-normal-1"
                    onClick={()=>{navigate("/knowledgeBase/risk-by-location")  }}
                  >Disaster Resource Library </li>
                  <li className="li-text-alignment primary-font-normal-1"
                    onClick={()=>{navigate("/knowledgeBase/blog")  }}
                  >Blog </li>
                  <li className="li-text-alignment primary-font-normal-1"
                   onClick={()=>{navigate("/knowledgeBase/faq")  }}
                  >FAQ</li>
                  <li className="li-text-alignment primary-font-normal-1"
                    onClick={()=>{navigate("/knowledgeBase/myredfolder-tribe")  }}
                  >MYREDFOLDER® Tribe </li>
                </Box>
              </div>
            </Grid>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
          </Grid>
        </div>
      </Box>
      <Box sx={{ background: "#1B1B1B" }}>
        <div className="container-footer">
          <Grid container className="footer__bottom">
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
            <Grid container
              xs={12} md={12} lg={12}
              className="footer footer-bottom-endcontainer">



              <Grid
                //  item xs={12} md={6} lg={6} 
                className="footer footer-bottom-end-faq">
                <Typography className="faq primary-font-normal-3"
                  onClick={()=>{navigate("/knowledgeBase/faq") ;localStorage.setItem("menuTitle", "Knowledge Base") }}
                >FAQ</Typography>
                <Typography className="faq primary-font-normal-3"
                 onClick={()=>{navigate("/security") }}
                >Security</Typography>
                <Typography className="faq primary-font-normal-3"
                   onClick={()=>{navigate("/privacy-policy") }} 
                  style={{ border: "none" }}>
                  Privacy Policy
                </Typography>
              </Grid>
              <Grid
                //  item xs={12} md={6} lg={6} 
                className="footer footer-bottom-end-copyrights">
                <Typography className="copyrights primary-font-normal-3" >
                  Copyrights © 2024 All Rights Reserved by{" "}
                  <b className="copyright-app primary-font-normal-3"
                   onClick={()=>{navigate("/home")  }}
                  >MYREDFOLDER®</b>
                </Typography>
              </Grid>

            </Grid>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Footer;

const ExpandableSection = ({ title, subtitle }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (

    <Grid container sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", borderBottom: expanded ? "1px solid red" : "1px solid #8B8B8B", py: 2 }} onClick={handleExpand}>
        <Typography sx={{ color: "#2B303E" }} className=' primary-font-medium-2'>
          {title}
        </Typography>

        <Box>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>

      <Collapse in={expanded} >
        {subtitle.map((subtitle, index) => (
          <Typography className="li-text-alignment primary-font-normal-1" key={index}
           onClick={()=>{navigate(subtitle.path)}}
          >
            {subtitle.text}
          </Typography>
        ))}
      </Collapse>

    </Grid>

  );
};