import React from 'react'
import ContentWrapper from '../../../../common/components/ContentWrapper';
import Blog from './Blog';
import FAQ from './faq';
import MRFTribe from './MyRedFolderTribe';


const KnowldgeBaseComponent = ({page=null}) => {
    let content= null;
    if(page === 'blog'){
        content = <Blog />
    } else if(page === 'faq'){
        content = <FAQ />
    } 
    else if(page === 'myredfolder-tribe'){
      content = <MRFTribe/>
    }
  return (
    <>
    <ContentWrapper contenview={content} />
    </>
  )
}

export default KnowldgeBaseComponent;