import * as React from "react";
import "./styles/plans.css"
import { useState ,useEffect, useRef} from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Button } from "@mui/material";
import { Box } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import { servicesCard } from "../data/PlanData";
import { useNavigate } from "react-router-dom";
import BasicPlan from "../../../../../src/assets/basic-plan.svg"
import PremiumPlan from "../../../../../src/assets/premium-plan.svg"

const Plans = (props) => {

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  return (
    <Grid container item className="services">
    <div className=" home-container-services ">
 
      <Grid item xs={12} md={12} lg={12}  >
            <div>
        <h2 style={{textAlign:'center'}} className="primary-font-bold-heading"> Choose Your Plan </h2>
      </div>
       
            <Grid className="pricing-plan-alignment">
              <img    onClick={()=>{ navigate("/pricing")}}  src={BasicPlan} className="basic-plan-img" alt="basic-plan" />   
              <img    onClick={()=>{ navigate("/pricing")}}  src={PremiumPlan}  className="premium-plan-img" alt="premium-plan"/>  
            </Grid>
          </Grid>
    </div>
    </Grid>
  );
};


// function ItemsResponsive(props){
//   const navigate = useNavigate();
//   return(
//     <Grid item xs={12} md={12} lg={12} sx={{ marginBottom:"20px" }}>
//         <Grid  sx={{minWidth:'240px',":hover":{boxShadow:4} ,boxShadow:2,borderRadius: "10px !important",opacity: 1,backgroundColor: "#ffffff !important"}}>
//           <Grid sx={{display:'flex', flexDirection:'column',p:'10px', }}>
//           <Typography textAlign="center" sx={{mb:"12px"}} className="primary-font-normal-8" > {props.singleCard.title} </Typography>
//             <Typography  marginBottom="8%" marginLeft="25%" textAlign="left" className="primary-font-bold4" color='#ff3535'>{props.singleCard.monthlyAmount}<br/>
//             {props.singleCard.annualAmount}</Typography>
//             {/* <TypographytextAlign="center" className="primary-font-bold4" color='#ff3535'></Typography> */}
//             <Typography sx={{textAlign:"center" ,color:"#8B8B8B"  }} >{props.singleCard.subscription}</Typography>
//             <Button  variant="outlined" sx={{p:'10px' ,marginX:'auto' , marginBottom:'10px'}} className='pricing-btn1'>
//             More Details
//             </Button>
//           </Grid>
//         </Grid>

//     </Grid>
//   )
// }
// function Item(props) {
//   const navigate = useNavigate();
//   return (
//     <Grid item xs={4} md={4} lg={4}  >
//     <Box   sx={{height: '340px', marginLeft:'20px' ,":hover":{boxShadow:4} ,boxShadow:2,borderRadius: "10px !important",opacity: 1,backgroundColor: "#ffffff !important"}}>
//    	  <Typography textAlign="center" className="primary-font-normal-8">
//           {props.singleCard.title}
//         </Typography>
//           <Box sx={{ display: "flex", flexDirection:"column" ,my:"30px"}}>
//           <Typography marginLeft="34%" textAlign="left" className="primary-font-bold2" color='#ff3535'>{props.singleCard.monthlyAmount}</Typography>
//             <Typography marginTop="5%" marginLeft="34%" textAlign="left" className="primary-font-bold2" color='#ff3535'>{props.singleCard.annualAmount}</Typography>
//             <Typography sx={{textAlign:"center" ,color:"#8B8B8B"  }} >{props.singleCard.subscription}</Typography>
//           </Box>
//           <Box sx={{ display:"flex",justifyContent:"center"}}  >
//             {/* <Button onClick={()=>{ navigate("/pricing") ; localStorage.setItem("menuTitle", "Pricing")}} variant="outlined" sx={{px:'40px'}} className='pricing-btn'>
//             More Details
//             </Button> */}
//             <Button  variant="outlined" sx={{px:'40px'}} className='pricing-btn'>
//             More Details
//             </Button>
//           </Box>
//     </Box>
//     </Grid>

//   );
// }

export default Plans;