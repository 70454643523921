import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Roles from "../../../../assets/aboutus-assets/aboutus-header-imges/our-values.png";
import { styled } from "@mui/material/styles";
import "../styles/Aboutus.css";

const OurRolesComponent = () => {
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });

  return (
    <div >
     
      <Grid container sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)",  }}>
      <div className='roles-header-container'>
        <Grid container item xs={12} md={12} lg={12} >
        
          <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>

            <Typography className='primary-font-bold7 '>WHY OUR ROLES MATTERS</Typography>

          </Grid>
          <Grid item xs={6} md={6} lg={6}  className='role-header-img' >
            <img src={Roles} alt="text" style={{width:"100%",height:"100%"}} />
          </Grid>
          
        </Grid>
        </div>
      </Grid>

        <div className="roles-header-container" >
            
              <Grid xs={12} md={12} lg={12} item >
              <p className="primary-font-normal-11 ourRolescontent ">
                With the ever-increasing rate of natural and man-made disasters,
                every human being needs to be and deserves to be prepared;
                additionally, they need the ability and assistance to recover
                from a disaster.
              </p>
              <p className="primary-font-normal-11 ourRolescontent">
                The best way to answer the question “Why our role matters?” is
                by asking the following questions (1-6) and providing an answer
                to each:
              </p>
              
              <ol
                className=".primary-font-normal-11"
                style={{ paddingLeft: "0", listStyle: "none" }}
              >
                <li className="li_overall">
                  <span className="li_numbering">1</span>
                  <span className="li_text">
                    How would you ensure your survival in the event of a
                    terrorist attack causing widespread panic and the closure of
                    all local shops and stores? Would you consider community
                    cooperation for resources or explore alternative options,
                    such as food distribution centers or emergency relief
                    programs?
                  </span>
                </li>
                <li className="li_overall">
                  <span className="li_numbering">2</span>
                  <span className="li_text">
                    Are you and your family prepared to weather hurricanes or
                    snowstorms that trigger panic-buying among your neighbors
                    and friends, potentially leading to food shortages that
                    could last for weeks? Have you considered long-term food
                    storage and contingency plans to maintain your household's
                    sustenance during such situations?
                  </span>
                </li>
                <li className="li_overall">
                  <span className="li_numbering">3</span>
                  <span className="li_text">
                    In the event of weeks of "rolling blackouts" that hinder
                    food storage and supply, what strategies would you employ to
                    adapt and ensure your family's nutrition and well-being?
                    Have you explored alternative energy sources or portable
                    food options that can withstand power disruptions?
                  </span>
                </li>
                <li className="li_overall">
                  <span className="li_numbering">4</span>
                  <span className="li_text">
                    How do you plan to navigate through the aftermath of floods
                    that wash out roads and disrupt food supplies for an
                    extended period? What provisions have you made for such
                    emergencies, especially if they occur while your children
                    are at school? Have you identified safe evacuation routes or
                    potential shelter options?
                  </span>
                </li>
                <li className="li_overall">
                  <span className="li_numbering">5</span>
                  <span className="li_text">
                    In the event of civil unrest and the formation of mobs in
                    your neighborhood while you're at work, what measures have
                    you put in place to protect yourself and your loved ones? Do
                    you have a plan for communication, evacuation, or fortifying
                    your home to ensure your safety during such situations?
                  </span>
                </li>
                <li className="li_overall">
                  <span className="li_numbering">6</span>
                  <span className="li_text">
                    If an earthquake strikes your area, disrupting the flow of
                    supplies in and out of your home, what steps would you take
                    to secure essential resources for your family? Have you
                    considered stockpiling emergency supplies or creating a
                    disaster preparedness kit to address immediate needs until
                    assistance becomes available?
                  </span>
                </li>
              </ol>
              <div style={{ marginBottom: "50px" }}>
                <p className="primary-font-normal-11 ourRolescontent ">
                  Our role is to prepare you for the above scenarios, to recover
                  from the above scenarios, and much more.
                </p>
                <p className="primary-font-normal-11 ourRolescontent ">
                  Now think of any disaster that you can imagine. Are you
                  prepared? How will you respond?
                </p>
                <p className="primary-font-normal-11 ourRolescontent ">
                  The above scenarios are a small sample of real-life
                  occurrences. Our role is to prepare you and your family for
                  any disaster/emergency that may occur.
                </p>
              </div>
            </Grid>
            
        </div>
  
    </div>
  );
};

export default OurRolesComponent;
