import axios from 'axios';
import constants from '../../../../config/config';

export const sendContactUsMessage = async (data) => {
    const endpoint = "v1/enquiry/contactUs/create"
    try {

        const response = await axios.post(`${constants.endPointUrl[constants.ENVIRONMENT]}${endpoint}`, data);
        return response.data;

      } catch (error) {
        return error;
      }
}



