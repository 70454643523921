import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';

import CustomPricing from './CustomisedPricing';

const CustomPricingComponent = () => {
  return (
        <>
            <ContentWrapper contenview={<CustomPricing />} />
        </>
  )
}

export default CustomPricingComponent;
