import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Box, Grid, Divider } from "@mui/material";
import hands from "../../../../assets/aboutus-assets/aboutus-header-imges/meet-team.png";
import Twitterred from "../../../../assets/icons/twitter-red-icon.svg";
import Facebookred from "../../../../assets/icons/facebook-red-icon.svg";
import Instagramred from "../../../../assets/icons/instagram-red-icon.svg";
import OurTeamComponentModal from "./ourTeamComponentModal";
import { data } from "../data/TeamComponentData";
import "../styles/Aboutus.css";

export default function OurTeamComponent() {


  return (
    <Grid container  sx={{ paddingBottom: "50px" }}>
      <Grid
        className="our-team-header" container item xs={12} md={12} >
        <div className="our-team-header-page">
          
        <Grid container item xs={12} md={12} lg={12} >
        
        <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>

          <Typography className='primary-font-bold7'>MEET THE TEAM</Typography>

        </Grid>
        <Grid item xs={6} md={6} lg={6}  className="team-header-img" >
          <img src={hands} alt="our-team" style={{width:"100%",height:"100%"}} />
        </Grid>
        
      </Grid>
        </div>
      </Grid>
      <div className="our-team-container">
        <Grid container item
      
          xs={12} md={12} sx={{ alignContent: 'center', justifyContent: "center", my:"30px" }}
        >
          <Typography className="primary-font-bold3" sx={{ width: '100%', textAlign: "center" }}>
            {" "}
            NEPTA’s mission is to create a secure, sustainable, and resilient
            world.
          </Typography>
          </Grid>

          {/* <Grid container spacing={2} item xs={12} md={12} lg={12}> */}
          {/* <Grid item xs={1} md={1} lg={1}></Grid>{" "} */}
          {/* <Grid
          container
          spacing={2}
          // rowGap={"20px"}
          // item
          xs={12}
          md={12}
          justifyContent="center"
        > */}
          <Grid     
          container sx={{justifyContent:'center'}}>

            {data.map((item, i) => (
              <Item key={i} item={item}  />
            ))}{" "}

          </Grid>
          {/* </Grid> */}
          {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
          {/* </Grid> */}
        
      </div>
    </Grid>
  );
}
function Item(props) {
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleClose = () => setOpen(false);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Grid  xs={12} sm={6} md={4} lg={3.5} sx={{display:"flex", justifyContent:"center"}}
      className="our-team-grid" >
      <Card className="our-team-card" sx={{ ':hover': { boxShadow: 7 } }}    >
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* <CardMedia
            className="our-team-image"
            component="img"
            // height="300"
            // width="300"
            image={props.item.profile}
            alt="image"
            onClick={() => { setOpen(!false) }}
            sx={{ cursor: "pointer" }}
          /> */}
          <img  className="our-team-image" src={props.item.profile}
            alt="image"
            onClick={() => { setOpen(!false) }}
            style={{ cursor: "pointer" }}/>
          <Divider
            sx={{ backgroundColor: isHovered ? "red" : "#0A1821", height: 3 }}
          />
          <CardContent className="team-card-content" >
            <Box sx={{ cursor: "pointer" }} onClick={() => { setOpen(!false) }}>


              <Typography
                className="our-team-profile-font"
                sx={{ textAlign: "center" }}
              >
                {props.item.profileName}
              </Typography>
              <Typography
                className="primary-font-medium-6 our-team-position"
              >
                {props.item.position}
              </Typography>
            </Box>

            <Box sx={{ cursor: "pointer", mr:'auto' , ml:'auto' }} >
              <Grid container >
                {/* <Grid item xs={3} md={3} onClick={() => { setOpen(!false) }}> </Grid> */}

                <Grid xs={12} md={12} sx={{ display: "flex", justifyContent: "center" }}>


                  <div className="team-social-icon">
                    <a
                      href={props.item.twitterlink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                      className="team-social-icon"
                  
                        src={isHovered ? Twitterred : props.item.twitter}
                        alt="twitter"
                      />
                    </a>
                  </div>
                  <div className="team-social-icon">
                    <a
                      href={props.item.facebooklink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        className="team-social-icon"
                  
                        src={isHovered ? Facebookred : props.item.facebook}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="team-social-icon">
                    <a
                      href={props.item.instagramlink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        className="team-social-icon"
                      
                        src={isHovered ? Instagramred : props.item.instagram}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </Grid>
                {/* <Grid xs={3} md={3} onClick={() => { setOpen(!false) }}></Grid> */}

              </Grid>
            </Box>

          </CardContent>
        </div>
      </Card>
      <OurTeamComponentModal
        onClose={handleClose}
        open={open}
        data={props.item}
      />
    </Grid>
  );
}
