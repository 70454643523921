import React from 'react'
import ContentWrapper from '../../../../common/components/ContentWrapper';
import RiskBasedOnLocationDetail from './RiskBasedOnLocationDetail';

export default function RiskBasedOnLocationDetailIndex() {
  return (
    <>
    <ContentWrapper contenview={<RiskBasedOnLocationDetail/>} />
    
</>
  )
}
