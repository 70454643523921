import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../../OurService/styles/RiskBasedOnLocation.css"
export default function PrivacyPolicy() {
    const Navigate = useNavigate();
    const listStyle = {
        paddingLeft: "24px",
        marginTop: "10px"
    };
    const Uliststyle = {
        paddingTop: "0px",
        marginBlockStart: '0px',
        marginBlockEnd: '0px',
        paddingLeft: "15px"
    }
    return (
        <Grid container >
            <Grid container item xs={12} md={12} className='ourservices-header-img' >

                <Grid item xs={12} md={12} lg={12} sx={{ marginY: 'auto' }}>
                    <div className='ourservices-header-container'>
                        <Typography className="primary-font-bold7 diaster-title" sx={{ textAlign: 'start' }}>
                            Privacy Policy
                        </Typography>

                        <div onClick={() => {
                            Navigate(-1)
                        }}
                        style={{ cursor: "pointer" }}className='back-icon-container'>
                        <ArrowBackIcon  className='back-icon'  />
                            <Typography className='primary-font-bold1'>Go Back</Typography>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Grid container className='privacy-middle-container' >
                <div className='ourservices-header-container'>
                    <Grid container item xs={12} md={12} lg={12}  >

                        <Box  >
                            <Typography className='primary-font-bold11 privacy-title' >Introduction</Typography>
                            <Typography className='primary-font-normal-2' >Welcome to MYREDFOLDER® website ("we," "us," or "our"). At "MYREDFOLDER®," we take your privacy and security very seriously. This Privacy Policy outlines how we collect, use, and protect your personal information, digital assets, financial information, and other critical data when you use our website or services.</Typography>
                            <Typography className='primary-font-normal-2 privacy-content' >By accessing or using the MYREDFOLDER®, you acknowledge that you have read, understood, and agreed to the terms of this Privacy Policy. If you do not agree with our practices, please do not use the MYREDFOLDER®.</Typography>

                        </Box>

                        <Box >
                            <Typography className='primary-font-bold11  privacy-title' > Information We Collect</Typography>

                            <Box>
                                <Typography className='primary-font-bold15 privacy-para '  >  Personal Information:</Typography>
                                <Typography className='primary-font-normal-2 ' >       When you sign up for MYREDFOLDER®, we collect personal information, such as your name, email address, contact details, and other necessary identifiers.</Typography>
                                <Typography className='primary-font-normal-2 privacy-content' >       To enhance security, we may use multi-factor authentication methods to verify your identity during login and access to sensitive information.</Typography>

                            </Box>

                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Digital Assets:</Typography>
                                <Typography className='primary-font-normal-2' >       The MYREDFOLDER® provides a secure platform to store and manage your digital assets, which may include usernames and passwords for cryptocurrencies, digital tokens, or other virtual assets.</Typography>
                                <Typography className='primary-font-normal-2 privacy-content' >       We use blockchain technology security to ensure the immutability and integrity of your digital assets.</Typography>

                            </Box>


                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Financial Information:</Typography>
                                <Typography className='primary-font-normal-2' >       To facilitate secure transactions and payments, we may collect your financial information, such as bank account details, credit/debit card information, or cryptocurrency wallet addresses.</Typography>
                                <Typography className='primary-font-normal-2 privacy-content'  >        All financial information is encrypted using the latest SSL security measures and stored in a secure, redundant server environment.</Typography>

                            </Box>

                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Usage Information:</Typography>
                                <Typography className='primary-font-normal-2' >       We may collect information about your interactions with MYREDFOLDER® and/or the www.myredfolder.com website, such as your browsing activities, login times, and IP addresses.</Typography>
                                <Typography className='primary-font-normal-2 privacy-content' >       This information is used for security monitoring, system analysis, and to improve the overall user experience.</Typography>

                            </Box>

                        </Box>


                        <Box>
                            <Typography className='primary-font-bold11  privacy-title' > How We Use Your Information</Typography>
                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Providing Services:</Typography>
                                <Typography className='primary-font-normal-2' >       We use your personal information and digital assets to provide secure access and manage your account on MYREDFOLDER® / www.myredfolder.com website.</Typography>
                                <Typography className='primary-font-normal-2 privacy-content'>       Your financial information is used to process transactions and facilitate secure payments such as product purchases, subscription renewals, and other authorized transactions.</Typography>
                            </Box>

                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Enhancing Security:</Typography>
                                <Typography className='primary-font-normal-2' >        Your personal information and digital assets are protected through our advanced security measures, including blockchain technology and quantum layer security.</Typography>
                                <Typography className='primary-font-normal-2 privacy-content' >        We continuously monitor our systems for potential vulnerabilities and promptly respond to any detected threats or breaches.</Typography>

                            </Box>
                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  >Improving User Experience:</Typography>
                                <Typography className='primary-font-normal-2' >        We may analyze usage data to understand user preferences, enhance website performance, and optimize user interface and experience.</Typography>

                            </Box>
                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Legal Compliance:</Typography>
                                <Typography className='primary-font-normal-2' >        We may use and disclose your information as required by applicable laws, regulations, or court orders.</Typography>

                            </Box>

                        </Box>

                        <Box>
                            <Typography className='primary-font-bold11  privacy-title' >  Security Measures</Typography>
                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Blockchain Technology Security:</Typography>
                                <Typography className='primary-font-normal-2' >        MYREDFOLDER® / www.myredfolder.com website utilizes blockchain technology to provide enhanced security and transparency for your digital assets.</Typography>

                            </Box>

                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Redundant Servers: </Typography>
                                <Typography className='primary-font-normal-2' >        We maintain and/or lease redundant servers and data backups to ensure uninterrupted service and data integrity.</Typography>

                            </Box>
                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > SSL Security Measures:</Typography>
                                <Typography className='primary-font-normal-2' >        All data transmitted between your device and the MYREDFOLDER® / www.myredfolder.com website  is encrypted using SSL protocols to prevent unauthorized access or interception.</Typography>

                            </Box>
                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  > Quantum Layer Security:</Typography>

                                <Typography className='primary-font-normal-2' >        The MYREDFOLDER® and/or www.myredfolder.com website employs quantum-resistant encryption methods to protect against potential quantum-based attacks.</Typography>

                            </Box>

                        </Box>

                        <Box>
                            <Typography className='primary-font-bold11  privacy-title' >  Unforeseen Occurrences</Typography>
                            <Box >
                                <Typography className='primary-font-bold15 privacy-para'  >System Breach:</Typography>

                                <Typography className='primary-font-normal-2' >        In the unlikely event of a system breach, we have incident response plans in place to promptly identify and mitigate any potential impact.</Typography>
                                <Typography className='primary-font-normal-2 privacy-content'  >        We will notify you and appropriate authorities as required by applicable laws and regulations.</Typography>

                            </Box>
                        </Box>

                        <Box>
                            <Typography className='primary-font-bold11  privacy-title' >  Third-Party Services</Typography>
                            <Typography className='primary-font-normal-2'> The MYREDFOLDER® may integrate with third-party services or websites, which have their own privacy policies. We are not responsible for their practices; please review their privacy policies separately.</Typography>
                        </Box>
                        <Box>
                            <Typography className='primary-font-bold11  privacy-title' >Accessing and Modifying Your Information</Typography>
                            <Box >
                                <Typography className='primary-font-normal-2'> You have the right to access, update, or delete your personal information stored on the MYREDFOLDER® / www.myredfolder.com website. </Typography>
                                <Typography className='primary-font-normal-2 privacy-content' > If you have any concerns or inquiries regarding your information, please contact our customer support.</Typography>

                            </Box>
                        </Box>
                        <Box>
                            <Typography className='primary-font-bold11  privacy-title' >  Changes to this Privacy Policy</Typography>
                            <Box >
                                <Typography className='primary-font-normal-2 ' > The MYREDFOLDER® management team may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws.</Typography>
                                <Typography className='primary-font-normal-2 privacy-content ' > We will notify you of any material changes through the website or email. Please review the Privacy Policy periodically for updates.</Typography>

                            </Box>
                        </Box>

                        <Box >
                            <Typography className='primary-font-bold11  privacy-title' > Contact Us</Typography>
                            <Typography className='primary-font-normal-2 privacy-para' sx={{ marginTop: "10px" }}> If you have any questions, concerns, or feedback about this Privacy Policy or the MYREDFOLDER® / www.myredfolder.com website please contact our support team at info@myredfolder.com
                                Thank you for entrusting the MYREDFOLDER®, MW Concepts and Marketing, LLC and the National Emergency Planning and Training Association, LLC, with your personal and digital assets. We are committed to safeguarding your privacy and security."</Typography>

                        </Box>


                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}
