import React, { useState } from "react";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import mission from "../../../../assets/knowledge-base-assets/knowledge-base-header-images/faq.png";
import { styled } from "@mui/material/styles";
import "../../KnowldgeBase/styles/knowledge.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const Faq = () => {
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });

  const faqsData = [
    {
      question: "Why do I need an emergency action plan?",
      answer:
        "An emergency action plan is essential as it helps you and your family prepare for, respond to, and recover from emergencies or disasters by outlining specific steps and procedures to ensure everyone’s safety.",
      panel: "panel1",
    },
    {
      question:
        "Why do emergency management agencies throughout the world recommend that every family have an emergency/disaster response and preparedness plan? ",
      answer:
        "Emergency management agencies emphasize the importance of individual and family preparedness to enhance overall community resilience. By having an emergency plan, families can effectively respond to emergencies, reduce panic, and increase their chances of survival.",
      panel: "panel2",
    },

    {
      question: "What should I include in my MYREDFOLDER® Family plan?",
      answer:
        "Some critical information to include in your MYREDFOLDER® Family plan includes important contact information, evacuation routes, designated meeting places, communication procedures, essential supplies, listed current medication, and specific actions to take during various emergency scenarios.",
      panel: "panel3",
    },

    {
      question: "How does having the MYREDFOLDER® plan benefit my family?",
      answer:
        " Having the MYREDFOLDER® provides a sense of security and confidence, allowing your family to respond quickly and effectively during emergencies. It helps ensure everyone's safety, minimizes confusion, and increases the likelihood of a successful outcome.",
      panel: "panel4",
    },

    {
      question:
        "Is the MYREDFOLDER® Family plan customized for specific hazards? ",
      answer:
        "Yes, the MYREDFOLDER® is tailored to address nearly all emergencies and disasters despite your location, such as hurricanes, earthquakes, civil unrest, floods, wildfires, active shooter situation, and other emergencies.",
      panel: "panel5",
    },
    {
      question: " How often should I update my MYREDFOLDER® Family plan?",
      answer:
        "It is recommended to review and update your emergency plan at least once a year or whenever there are significant changes in your family composition, contact information, or living situation.",
      panel: "panel6",
    },
    {
      question: "Are emergency plans only for natural disasters?",
      answer:
        "No, emergency plans are essential for all types of emergencies, including natural disasters, fires, medical emergencies, power outages, or civil disturbances. ",
      panel: "panel7",
    },
    {
      question: "What role does communication play in an emergency plan? ",
      answer:
        "Communication is crucial during emergencies. Your MYREDFOLDER® includes designated communication methods via the MYREDFOLDER® App to include and meeting points to ensure you can stay connected with family members and receive important updates.",
      panel: "panel8",
    },
    {
      question: "How can an emergency plan help businesses? ",
      answer:
        "Businesses can benefit from the MYREDFOLDER® Family plan by providing an active means of safeguarding their employees, minimizing downtime, and maintaining continuity of operations during and after emergencies.",
      panel: "panel9",
    },
    {
      question: "What resources should I include in my emergency kit? ",
      answer:
        "Your emergency kit should include essential supplies such as water, non-perishable food, a first aid kit, flashlights, batteries, blankets, and any necessary medications.",
      panel: "panel10",
    },
    {
      question:
        "How can children be involved in the MYREDFOLDER®  planning process? ",
      answer:
        "Involving children in the planning process helps them understand the importance of preparedness. Assign them age-appropriate tasks and explain emergency procedures in a calm and reassuring manner.",
      panel: "panel11",
    },
    {
      question: "What steps should I take during an evacuation? ",
      answer:
        "Your MYREDFOLDER® Family plan should include evacuation routes and procedures. Follow the instructions of local authorities, bring your emergency kit, and ensure everyone in your family is accounted for.",
      panel: "panel12",
    },
    {
      question: "Can I rely solely on emergency services during an emergency?",
      answer:
        "Emergency services may be overwhelmed during a large-scale disaster. Having the MYREDFOLDER® Family plan ensures you are self-reliant and can take immediate action while waiting for professional assistance.",
      panel: "panel13",
    },
    {
      question: "How can neighbors help each other during emergencies?",
      answer:
        " Establishing a network of neighbors who can support each other during emergencies fosters community resilience. Share your emergency plans, communicate regularly, and offer assistance when needed. Your MYREDFOLDER® has section dedicated to the neighbor-helps-neighbor recommendations.",
      panel: "panel14",
    },
    {
      question:
        " What are the key benefits of having the MYREDFOLDER® plan for my family?",
      answer:
        "Having the MYREDFOLDER® plan allows you to respond quickly, make informed decisions, and protect your loved ones during crises. It helps minimize confusion, ensures everyone knows their roles, and increases the chances of a safe and successful outcome.",
      panel: "panel15",
    },
  ];

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      <Grid>
        <Grid
          container
          sx={{
            background:
              "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)",
          }}
        >
          <div className="faq-header-container">
            <Grid container item xs={12} md={12} lg={12}>
              <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>
                <Typography className="primary-font-bold7 ">
                  {" "}
                  FREQUENTLY ASKED QUESTIONS
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} className="faq-header-img">
                <img
                  src={mission}
                  alt="complex"
                  style={{ width: "100%", height: "100%" }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid container>
          <div style={{ width: '100%' }} className='faq-top-container'>
            <Typography className='primary-font-bold13'>MYREDFOLDER© </Typography>
          </div>
        </Grid>
        <Grid container sx={{ background: "#FAFAFB" }}>
          <div className="faq-header-container">
            <Grid container spacing={1} >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ marginBottom: "60px" }}
              >
                {faqsData.map((faq, index) => (
                  <Accordion
                    elevation={0}
                    key={faq.panel}
                    expanded={expanded === faq.panel}
                    onChange={handleChange(faq.panel)}
                    sx={{
                      marginTop: "15px",
                      borderLeft: expanded === faq.panel ? "" : '3px solid #ff3535',
                      backgroundColor: "white",
                      "&:before": { display: "none" },
                      "&:hover": {
                        boxShadow: "0px 2px 8px #00000033",
                      },
                      "&.Mui-expanded": {
                        marginTop: "15px",
                        borderBottom: "1px solid #ff3535",
                        borderLeft: "3px solid #ff3535"
                      },
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{ cursor: "pointer" }}
                      expandIcon={
                        expanded === faq.panel ? (
                          <IconButton
                            sx={{
                              backgroundColor: '#ff3535 !important',
                              width: '18px',
                              height: '18px',
                              mr: "-24px",
                              ml: '20px'
                            }}
                          >
                            <RemoveIcon sx={{ color: 'white' }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            sx={{
                              backgroundColor: '#ff3535 !important',
                              width: '18px',
                              height: '18px',
                              mr: "-24px",
                              ml: '20px'
                            }}
                          >
                            <AddIcon sx={{ color: 'white' }} />
                          </IconButton>
                        )
                      }
                    >
                      <Typography className="primary-font-bold1" sx={{ paddingRight: '12px' }} >  {faq.question}{" "} </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ borderTop: '1px solid #00000033' }}>
                      <Typography className="primary-font-normal-9" > {faq.answer} </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Faq;
