import React, {  useState } from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, CardActions, Grid, useMediaQuery } from "@mui/material";
import calendar from '../../../../assets/icons/calendar-days.svg';
import location from '../../../../assets/icons/location-dot.svg';
import Tooltip from '@mui/material/Tooltip';

const OurEventsCards = ({filteredData}) =>{
return <>{
    filteredData.map((item, i) => {
        return (
          <Grid  item lg={4} xs={12} sm={6} md={4}>
          <Item key={i} singleCard={item} />
          </Grid>
        )
      })}
      </>
}

function Item({ key, singleCard }) {

    return (
    
        <Card key={key} className='event-card-1'  sx={{':hover': {
        boxShadow: 5
      },}}>
            <CardContent sx={{
              height: '320px',pb:"20px"
            }}>
              <Grid maxHeight={'62px'} minHeight={'62px'}>
{/* 
                <div className='tooltip smallsize'>
                <Tooltip 
                 interactive={ useMediaQuery('(max-width: 800px)') }
                 enterTouchDelay={0}
                //  leaveTouchDelay={8000}
                title= {singleCard.title} placement="bottom" arrow>
                <Typography className="primary-font-bold1 card-title" style={{ textAlign: 'left', paddingBottom: '10px', paddingTop: '10px' }} >
                {singleCard.tooltipTitle}
                </Typography>
                 </Tooltip>
                </div> */}
                <div className='tooltip '>
            
                <Typography className="primary-font-bold1 card-title" style={{ textAlign: 'left', paddingBottom: '10px', paddingTop: '10px' }} >
                {singleCard.title}
                </Typography>
                </div>
               
              </Grid>
              <hr style={{ marginBottom: "5px",marginTop:"10px" }} className="event-hr" />
              <Box
                component="ul"
                className="list-component"
                aria-labelledby="category-a"
                sx={{ pl: 2 }}
              >
               
                  <div style={{display:'flex'}}>
                    <img src={calendar} alt="calendar"  style={{marginBottom:'5px'}}/>

                    <Typography className="event-calender-font" sx={{paddingLeft:'18px'}}>
                  <span dangerouslySetInnerHTML={{ __html: singleCard.date ? singleCard.date : "" }} />
                  </Typography>

                  </div>
              
                
                  <div style={{display:'flex'}}>
                    <img src={location} alt="location" />

                    <Typography className="event-calender-font" sx={{paddingLeft:'18px'}}>
                    {singleCard.location}
                  </Typography>
                  </div>
                
                
              </Box>
              
              <div className="card-content-wrapper event-calender-font" >{singleCard.content}</div>
            </CardContent>
            <CardActions
              
              sx={{
                display:"flex",justifyContent:"center",
                height: '67px',
                background: '#FAFAFB 0% 0% no-repeat padding-box'
              }}
            >
              <a className="read-more primary-font-bold-read-more-1" href={singleCard.navlink} target="_blank">View Event Details</a>
            </CardActions>
        </Card>
    
    )
  }

export default OurEventsCards;
