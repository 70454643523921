import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';
import OurHistoryComponent from './OurHistoryComponent';
import OurTeamsComponent from './OurTeamsComponent';
import OurValuesComponents from './OurValuesComponents';
import OurRolesComponent from './OurRolesComponent';
import OurEventsComponent from './OurEventsComponent';
import ContactUsComponent from './ContactUsComponent';

const AboutUsComponent = ({page=null}) => {
    let content= null;
    if(page ==='history'){
        content = <OurHistoryComponent />
    } else if(page ==='team'){
        content = <OurTeamsComponent />
    }else if(page ==='values'){
        content = <OurValuesComponents />
    }else if(page ==='roles'){
        content = <OurRolesComponent />
    }else if(page ==='events'){
        content = <OurEventsComponent />
    }else if(page ==='contact-us'){
        content = <ContactUsComponent />
    }
  return (
        <>
            <ContentWrapper contenview={content} />
        </>
  )
}

export default AboutUsComponent;
