import React from 'react'
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import History from '../../../../assets/aboutus-assets/aboutus-header-imges/our-history.png';
import BriefHistory from '../../../../assets/our-history-image.png';
import Rmap1 from '../../../../assets/MRF_roadmap-mobile.svg';
import Rmap from '../../../../assets/MRF_roadmap-lap.svg';
import "../styles/Ourhistory.css"

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});


const OurHistoryComponent = () => {
  return (
    <Box>
      <Grid container sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)",  }}>
      <div className='ourhistory-header-container'>
        <Grid container item xs={12} md={12} lg={12} >
        
          <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>

            <Typography className='primary-font-bold7'>OUR HISTORY</Typography>

          </Grid>
          <Grid item xs={6} md={6} lg={6}  className='ourhistory-header-img' >
            <img src={History} alt="ourhistory" style={{width:"100%",height:"100%"}} />
          </Grid>
          
        </Grid>
        </div>
      </Grid>
      <Grid container className='ourhistory-header-container'>
        <div style={{ width: '100%',}}  className='ourhistory-top-container'>
          <Typography className='primary-font-bold13'>Brief History / Association Overview</Typography>
        </div>

        <Grid container item xs={12} md={12} lg={12} style={{ padding: 0 }}>
          
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <Img alt="complex" src={BriefHistory} height="100%" />
            </Grid>
            <Grid item xs={12} md={6}  className='briefhistory-content'>
              <Typography sx={{textAlign:"left",letterSpacing:"0.11px"}} className='primary-font-normal-11 ' >
                The National Emergency Planning and Training Assocation (NEPTA) is an association dedicated to the systematic process of preparation and recovery in the event of a disaster, emergency, or unforeseen occurence. With over a combined 65 years of emergency management experience to draw from, we provide consultation services and invaluable resources to individuals, families, organizations, and government agencies. We believe disaster preparation and response starts and the individual and family level.</Typography>
            </Grid>
        
        </Grid>




        <Grid item xs={12} md={12} lg={12} className='ourhistory-topmiddle-container'>

          <div style={{ width: '100%', textAlign: 'center'}}>
            <Typography className='primary-font-bold13'>MYREDFOLDER® plan</Typography>
          </div>
          <div style={{ width: '100%', textAlign: 'center'}} className='mfdplan-container'>
            <Typography sx={{textAlign:"left"}} className='primary-font-normal-11'>
              The MYREDFOLDER® concept was developed based on the physical red folders that Military personnel would give to their spouses when they left home to go on assignment. It contained everything the surviving spouse would need in case they didn’t return home; including emergency plan in case disaster struck while they were away. The MYREDFOLDER® Emergency Plan developed by NEPTA takes the concept to a whole new level. Now you don’t have to worry about the folder being lost, misplaced, destroyed, or stolen. and now it includes so much more. The MYREDFOLDER® Plan, which is secured in the cloud, contains such items as:
            </Typography>
          </div>

        </Grid>


        <Grid item xs={12} md={12} lg={12} style={{ width: '100%', overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: 20, width: 'fit-content' }}>
            <div  className='ourhistory-rmap-svg'>
              <Img alt="complex" src={Rmap} sx={{width:"100%"}} className='big-size ' />
              <Img alt="complex" src={Rmap1} sx={{width:"100%"}} className='small-size ' />
            </div>
          </div>
        </Grid>


        <Grid item xs={12} md={12} lg={12}  className='ourhistory-end-container'>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Typography  sx={{textAlign:"left"}} className='primary-font-normal-11 ourhistory-endbottom-content'>
              The MYREDFOLDER® contains an abundance of invaluable information that we are unable to fully cover here. However, we invite you to explore our website,
              instructional videos, and timely information all centered around disaster preparedness, response, resiliency, and recovery. We are confident that you will find
              this resource to be an indispensable tool for both you and your entire household.
            </Typography>
            <Typography  sx={{textAlign:"left"}} className='primary-font-normal-11 ourhistory-endbottom-content'>
              Our unwavering commitment has resulted in the development of an unparalleled emergency response and recovery plan. This MYREDFOLDER® empowers
              families to prepare well in advance of any potential disaster, ensuring readiness and enhancing safety during times of crisis. Moreover, it streamlines the
              recovery process when it's time to rebuild and restart your life and/or business. Importantly, the MYREDFOLDER® is designed to remain perpetually relevant. By
              harnessing the power of the internet, NEPTA ensures that updating your plan is a seamless, secure process, accessible within seconds to your entire family.
            </Typography>
            <Typography  sx={{textAlign:"left"}} className='primary-font-normal-11 '>
              In essence, NEPTA's mission is to deliver comprehensive preparedness and thorough disaster recovery solutions. While calamities may strike unexpectedly and
              devastate lives, NEPTA equips you with the means to provide the utmost care for your loved ones during what could be the most challenging period of their lives.
            </Typography>
          </div>
        </Grid>

      </Grid>
    </Box>
  )
}

export default OurHistoryComponent;
