import React  from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


const EventPagination = ({totalPosts, postPerPage, setCurrentPage,isMobile }) =>{

    let pages = [];
    
    for(let i=1; i<=Math.ceil(totalPosts/postPerPage); i++){
        pages.push(i);
    }

    const paginationStyle = {
        color: '#000',
      };
    
      const selectedPageStyle = {
        backgroundColor: '#ff3535', 
        color: 'white', 
      };
    
    return <>
    <Stack spacing={1}>
        <Pagination 
            count={pages.length} 
            shape="rounded" 
            size= {isMobile ? 'small': 'large' }            
            style={paginationStyle}
            renderItem={(item) => (
                <PaginationItem
                  component="div" 
                  slots={{ first :KeyboardDoubleArrowLeftIcon ,last : KeyboardDoubleArrowRightIcon }}
                  style={item.selected ? selectedPageStyle : null}
                  sx={{borderRadius:'4px', fontFamily:'Source Sans Pro', fontSize:'14px' }}
                  {...item}
                />
              )}
            siblingCount={1}
            defaultPage={1}
            showFirstButton 
            showLastButton
            onChange={ (event, pageNumber)=>{ setCurrentPage(pageNumber);  } }       
        />
    </Stack>
    </>
}

export default EventPagination;