import React from 'react';
import './styles/asSeenOn.css'; 

const AsSeenOn = () => {
  return (
    <>
    <div class="main_wrapper1" style={{marginBottom: 70, marginTop: 40}}>
        <div class="main_content">
            <div class="head_line"><h3>AS SEEN ON</h3></div>
            <div class="badges">
              <img src="https://storage.googleapis.com/msgsndr/linJJKxkNTQ3VC8ZDegR/media/663993ef142629334f61fa32.png" alt="badges" style={{maxWidth: 768}} />
              </div>
            <div class="sub_headline"><h4>AND OVER 200 NEWS SITES</h4></div>
            <div className="verified_action">
              <span className="icon_box_001">
                <svg
                  id="Icons"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <style>{'.cls-1 { fill: #636363; }'}</style>
                  </defs>
                  <path
                    className="cls-1"
                    d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm5.707,8.707-7,7a1,1,0,0,1-1.414,0l-3-3a1,1,0,0,1,1.414-1.414L10,14.586l6.293-6.293a1,1,0,0,1,1.414,1.414Z"
                  />
                </svg>
                &nbsp; Verified by{' '}
                <a href="https://www.fsdf.com" target="_blank" rel="noopener noreferrer">
                  AnotherZero.com
                </a>
              </span>
            </div>
        </div>
      </div>  
    </>
  )
}

export default AsSeenOn


