import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';

import Pricing from './Pricing';

const PricingComponent = () => {
  return (
        <>
            <ContentWrapper contenview={<Pricing />} />
        </>
  )
}

export default PricingComponent;
