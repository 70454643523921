
import React, { useState } from "react";
// import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Select, Button, MenuItem, FormControl, Box, Grid, Alert, Dialog, DialogContent, Typography, Modal } from "@mui/material";

//Internal Files
import twitter from "../../../../assets/icons/twitter-icon.svg";
import facebook from "../../../../assets/icons/facebook-icon.svg";
import instagram from "../../../../assets/icons/instagram-icon.svg";
import languageicon from "../../../../assets/icons/internet-icon.svg";
// Css Files
import "./Header.css";
import TopBar from "./TopBar";
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useNavigate, useLocation } from "react-router-dom";
import InitializeModal from "../../../../containers/Pages/Home/components/InitializeModal";
import close from "../../../../assets/close-icon-red.png"
import commingsoon from "../../../../assets/comming-soon.png"
import { useTranslation } from 'react-i18next';
const Input = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: "5px !important",
    position: 'relative',
    border: "1px solid #ff3535 !important",
    background: "#ffffff 0% 0% no-repeat padding-box",
    '&:focus': {
      border: "1px solid #ff3535 !important",
      background: "#ffffff 0% 0% no-repeat padding-box",
    },
  },
  opacity: "1",
  width: "auto",

}));

const Header = () => {
  const [language, setLanguage] = useState("English");
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation('common');
  const [isWalmart, setIsWalmart] = useState(location.pathname === "/walmart");

  const langs = {
    "en": "English",
    "fr": "French",
    "sp": "Spanish"
  }
  const handleChange = (e) => {

    i18n.changeLanguage(e.target.value)

    setLanguage(langs[e.target.value]);

  };


  return (
    <React.Fragment>
      <div style={{ position: 'sticky', top: '0', zIndex: 1200 }}>
        <div className="header-navbar-1">
          <Grid container   >
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
            <Box className="header-navbar-container">
              <Grid item xs={12} md={12} lg={12} className="header-nav-bar">
                <Box sx={{ display: "flex", justifyContent: "flex-start", pt: "6px", marginLeft: "0px" }}>
                  <div className="social-icon">
                    <a
                      href="https://twitter.com/neptanow"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={twitter} alt="twitter" className="social-icon-logo" />
                    </a>
                  </div>
                  <div className="social-icon">
                    <a
                      href="https://www.facebook.com/nepta.us"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={facebook} alt="facebook" className="social-icon-logo" />
                    </a>
                  </div>
                  <div className="social-icon">
                    <a
                      // href="https://www.instagram.com/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={instagram} alt="instagram" className="social-icon-logo" />
                    </a>{" "}
                  </div>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>


                  <Box>
                    <FormControl sx={{ margin: "8px 0px 8px 8px" }} variant="standard">
                      <Select

                        labelId="demo-simple-select-label"
                        value={language}
                        onChange={handleChange}
                        input={<Input />}
                        renderValue={() => (
                          <Box className=' language-container primary-font-medium-11'>
                            <img src={languageicon} alt="language" />
                            {language}
                          </Box>
                        )}
                      >
                        <MenuItem value={"en"}>English</MenuItem>
                        <MenuItem disabled value={"sp"}>Spanish</MenuItem>
                        <MenuItem disabled value={"fr"}>French</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {!isWalmart && (
                    <Box>
                      <Box className="sign-up-container">
                        <Button className="primary-font-medium-13 sign-up-btn" onClick={handleOpen}
                        // href="https://www.staging.myredfolder.kyybaapps.com/"
                        >
                          Login
                        </Button>
                      </Box>
                    </Box>
                  )}
                 


                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="content-dialog">
                      <Box sx={{ background: "#8a191b" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                          <img className="coming-soon" src={commingsoon} alt="comming  soon" />
                        </div>
                        <div onClick={() => { handleClose() }} className="coming-soon-close-container" >
                          <Box sx={{ background: "white", width: "40px", height: "40px", border: "2px solid #D13A3C", borderRadius: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={close} style={{ width: "15px", height: "15px" }} />
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              </Grid>
            </Box>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
          </Grid>
        </div>
        <TopBar />

      </div>

    </React.Fragment>
  );
};

export default Header;


