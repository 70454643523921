import React, { useEffect, useState,useRef  } from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import { Box, Grid, TextField, Divider, useMediaQuery,Input, Tab, Tabs, FormControl} from "@mui/material";
import searchIcon from "../../../../assets/icons/search-icon.svg";
import whiteSearchIcon from "../../../../assets/icons/white-search-icon.png";
import headerImage from "../../../../assets/aboutus-assets/aboutus-header-imges/events.png";
import filterIcon from "../../../../assets/icons/filter-icon.svg";
import { data } from "../data/OurEventsComponentsData";
import EventPagination from "../data/EventPagination.js";
import OurEventsCards from "../components/OurEventsCards"
import "../styles/Aboutus.css"


const OurEventsComponent = () => {

  const [filteredData, setFilteredData] = useState(data);
  const [findValue, setFindValue] = useState("");
  const [nearValue, setNearValue] = useState("");
  const [totalEventsValue, setTotalEventsValue] = useState(data.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(6);
  const [value, setValue] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef(null);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  let isMobile = useMediaQuery('(max-width: 767px)');
  let isTablet = useMediaQuery('(min-width: 768px) and (max-width:999px)')
  useEffect(() => {
   handleSearch();
    setPostPerPage(isTablet ? 4 : isMobile ? 2 :6);
  }, [isMobile,isTablet, findValue, nearValue]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);

  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{
          borderTop: 'none !important',
        }}
      >
        {value === index && (
          <Box sx={{ pt:'10px'  }}>
            <Grid >{children}</Grid>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // const handleFindValue = (value) =>{
  //   setFindValue(prevState=> value);
  // }
  // const handleNearValue = (value) =>{
  //   setNearValue(prevState=> value);
  // }
  

  const handleSearch = (event, value) => {

    let filteredResults = "";
    if(value ==="find"){
      setFindValue(event.target.value);
    }
    if(value ==="near"){
      setNearValue(event.target.value);
    }
    
    if(( findValue === '') && ( nearValue === '')) {
      setTotalEventsValue( prevState=> data.length);
      setFilteredData(prevState=> data);
      return;
    }

    if(!nearValue || !findValue){
      if (findValue) {
        filteredResults = data.filter((item) => {
          const eventfilter = item.title.toLowerCase().includes(findValue.toLowerCase());
          return eventfilter;
        }
        );
      }
       if (nearValue) {
        filteredResults = data.filter((item) => {
          const locationfilter = item.location.toLocaleLowerCase().includes(nearValue.toLowerCase());
          return locationfilter;
        }
        );
      }
    }
    else{
      filteredResults = data.filter((item) => {
        const filtered = item.title.toLowerCase().includes(findValue.toLowerCase()) || item.location.toLowerCase().includes(nearValue.toLowerCase())
        return filtered;
      });
    }

    setTotalEventsValue( prevState=>filteredResults.length);
    setFilteredData( prevState=>filteredResults);
    setCurrentPage( prevState=>1);
    console.log(findValue,"find");
    console.log(nearValue,"near");
  };

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div>
        <Grid  style={{
          background:
            "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%) 0% 0% no-repeat padding-box",
        }}>
          <div className='events-header-page'>
          <Grid container xs={12} md={12} lg={12} >
            <Grid item xs={6} md={6} lg={6} sx={{ marginY: "auto" }}>
              <Typography className='primary-font-bold7'>EVENTS CALENDER</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6} className="team-header-img" >
              <img src={headerImage} alt='event calender'style={{width:"100%",height:"100%"}} />
            </Grid>
            </Grid>
          </div>
        </Grid>
{/* search bar with mobile and laptop designs */}
      {isMobile ? <>
        <div className="our-team-container ">
          <Grid container xs={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'column', mt: '20px' }} >
            <Grid>
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                TabIndicatorProps={{ style: { background: "red" } }}
                aria-label="basic tabs"
              >
                <Tab
                  sx={{
                    width: "50%",
                    height: "20px",
                    color: value === 0 ? "#fff !important" : '#FF3535 !important',
                    backgroundColor: value === 0 ? '#ff3535 !important' : 'white ',
                    border: '1px solid #FF3535',
                    // borderRadius: '10px 10px 0px 0px',
                    // borderBottom: value === 0 ? 'none' : '1px solid #C7CBCF',
                    // mr: '10px',
                  }}
                  label={<Typography className='primary-font-bold5' sx={{ textAlign: "center", }} textTransform="none"> FIND </Typography>} />
                <Tab
                  sx={{
                    width: "50%",
                    height: "20px",
                    color: value === 1 ? "#fff !important" : '#FF3535 !important',
                    backgroundColor: value === 1 ? '#ff3535 !important' : 'white',
                    border: '1px solid #FF3535',
                    // borderRadius: '10px 10px 0px 0px',
                    // borderBottom: value === 0 ? 'none' : '1px solid #C7CBCF',
                    // mr: '10px',
                  }}
                  label={<Typography className='primary-font-bold5' sx={{ textAlign: "center", }} textTransform="none">NEAR </Typography>} />
              </Tabs>
            </Grid>
            <Grid>
              <CustomTabPanel value={value} index={0}>
                <Grid style={{ border: "1px solid #BCBCBC", display: 'flex', justifyContent: 'space-between' }}>
                  {/* <Input
                    allowClear
                    onChange={ handleInputChange }
                    value={findValue}
                    placeholder="Events & Programs"
                    variant="borderless"
                    ref={inputRef}
                  /> */}

                     <TextField
                      value={findValue}
                      fullWidth
                      autoFocus ={isFocused}
                      sx={{mx:'10%', my:'auto'}}
                      onChange={(e)=>{ handleFocus(); handleSearch(e,"find"); }}
                      onBlur={handleBlur}
                      InputProps={{ disableUnderline: true }} 
                      variant='standard' margin='normal' placeholder='Events & Programs' 
                      /> 
           
                   <div onClick={handleSearch} style={{ padding: "19px", width: '59px', height: '59px',  boxShadow:' -1px 0px 5px #88888a' }}>
                    <img src={searchIcon} alt='search icon' style={{ width: "20px", height: "22px" }} />
                  </div>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid style={{ border: "1px solid #BCBCBC", display: 'flex', justifyContent: 'space-between' }}>
                {/* <Input  allowClear  focus={true}  value={nearValue} placeholder="Enter City/Town " variant="borderless" onChange={handleNearChange} /> */}
                  
                  <TextField
                  value={nearValue}
                    sx={{mx:'10%',my:'auto'}}
                    fullWidth
                    autoFocus ={isFocused}
                    onChange={(e)=>{ handleFocus(); handleSearch(e,"near"); }}
                    onBlur={handleBlur}
                    InputProps={{ disableUnderline: true }} 
                    variant='standard' margin='normal' placeholder='Enter City/Town ' />
                
          <div onClick={handleSearch} style={{ padding: "19px", width: '59px', height: '59px', boxShadow:' -1px 0px 5px #88888a' }}>
                    <img src={searchIcon} alt='search icon' style={{ width: "20px", height: "22px" }} />
                  </div>
                </Grid>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </div>
    </> : <>
      <div className="our-team-container">
          <Grid sx={{ alignContent: 'center', justifyContent: "center" }} lg={12} md={12} xs={12} container>
            <Grid  sx={{ display: "flex", width:'100%',justifyContent:'space-between', height: "60px", marginTop: "25px", border: "1px solid #ff3535", borderRadius: "5px" }}>
              <Grid sx={{ display: "flex" }}>
                <Grid sx={{ display: "flex" }}>
                  <Grid sx={{ display: 'flex', justifyContent: 'space-between', margin: "0px 20px 0px 0px" }}  >
                    <Typography className='primary-font-bold4' sx={{ padding: "18px 20px 18px 20px", }}>FIND</Typography>
                    <TextField
                     fullWidth
                      value={findValue}
                      // sx={{width:'100%'}}
                      onChange={(e) => {  setFindValue(e.target.value);}}
                      InputProps={{ disableUnderline: true }} variant='standard' margin='normal' placeholder='Events & Programs' />
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: "space-between" }} >
                <Divider sx={{ backgroundColor: "#D6D6D6", width: '1px', height: '48px', marginTop: "5px" }} />
                <Grid sx={{ display: 'flex', margin: "0px 20px 0px 0px" }}>
                  <Typography className='primary-font-bold4' sx={{ padding: "18px 20px 18px 20px", }}   >NEAR</Typography>
                  <TextField
                   fullWidth
                    value={nearValue}
                    // sx={{width:'100%'}}
                    onChange={(e) => { setNearValue(e.target.value);}}
                    InputProps={{ disableUnderline: true }} variant='standard' margin='normal' placeholder='Enter City/Town ' />
                </Grid>
              </Grid>
              <Grid sx={{display:'flex', justifyContent:'end'}}>
                <div onClick={() => { handleSearch(); }} style={{ padding: '19px', backgroundColor: "#FF3535", borderBottomRightRadius: '4px', borderTopRightRadius: '4px', border: '1px solid #F85500', width: '59px', height: '59px' }}>
                  <img src={whiteSearchIcon} alt='search icon' style={{ width: "20px", height: "22px" }} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </> }
      {
  filteredData.slice(firstPostIndex, lastPostIndex).length !==0  ? 
     <div className="our-team-container">
        <Grid sx={{ alignContent: 'center', justifyContent: "left" }}  container lg={12} md={12} xs={12}>
          <Grid alignContent={'center'} marginTop={"15px"} display={'flex'} justifyContent={'space-between'} >
            <Grid container item xs={12} lg={12} md={12} >
              <Typography sx={{ marginTop: "5px" }} >Showing {totalEventsValue != 0 ? ( currentPage - 1) *postPerPage + 1 : 0} – {Math.min(currentPage * postPerPage, totalEventsValue)} of {totalEventsValue && totalEventsValue} results  </Typography>
            </Grid>
             {/* <div style={{ borderRadius: "8px", padding: "10px", display: 'flex', alignItems: "center", justifyContent: 'space-between', border: "1px solid", borderColor: '#FF3535', width: "107px", height: "40px" }}>
              <div style={{ fontWeight: 'bold', marginTop: "3px" }}>Filter</div>
              <div style={{ width: "24px", height: "24px", marginTop: "2px" }}><img src={filterIcon} alt="filter" /> </div>
            </div>  */}
          </Grid> 
        </Grid>
        <Grid sx={{display:'flex',alignContent:'center', justifyContent:'center'}} container lg={12} xs={12} md={12}>
        <Grid item lg={12} md={12} xs={12} marginTop={'15px'} marginBottom={'15px'}>
          <div>
            <Divider style={{ height: 2 }} />
          </div>
        </Grid>
        </Grid>
      </div> :<></>}
{
  filteredData.slice(firstPostIndex, lastPostIndex).length !==0  ? 

    <div>
      <div className="our-team-container" style={{display:'flex', justifyContent:'space-evenly'}}>
        <Grid sx={{ display:'flex',alignContent:'center', justifyContent:'center'}}  container  spacing={2} lg={12} md={12} xs={12}   > 
            <OurEventsCards filteredData={(filteredData.slice(firstPostIndex, lastPostIndex))} />
        </Grid>
      </div> 
      <div className='our-team-container'>
        <Grid container xs={12} lg={12} md={12} justifyContent={'center'}>
          <Grid sx={{ marginBottom: "60px",mt:'60px', justifyContent: 'center' }}  >
            <EventPagination totalPosts={totalEventsValue} postPerPage={postPerPage} setCurrentPage={setCurrentPage} isMobile={isMobile}  />
          </Grid>
        </Grid>
      </div> 
     </div> : <div className="our-team-container" style={{display:'flex', alignItems:'center',justifyContent:'center', height:isMobile ? "300px" : '680px'}}>NO SUCH DATA</div> }

    </div>
  )
}



export default OurEventsComponent
