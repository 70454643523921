import React, { useState } from 'react'
import { Alert, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";


import close from "../../../../assets/icons/close-icon.svg"
import { Scrollbars } from "react-custom-scrollbars-2";
import { businessform } from '../redux/actions';
export default function BusinessModalForm(props) {


    const organizationTypes = [

        {
            name: "School/Educational Institution"
        },
        {
            name: "Insurance Company"
        },
        {
            name: "Police Department"
        },
        {
            name: "Fire Department"
        },
        {
            name: "Hospital/Medical Facility"
        },
        {
            name: "Emergency Services"
        },
        {
            name: "Mortgage Corporation"
        },
        {
            name: "Real Estate Company"
        },
        {
            name: "Manufacturing Plant"
        },
        {
            name: "Retail Store/Chain"
        },
        {
            name: "Financial Institution"
        },
        {
            name: "IT/Technology Company"
        },
        {
            name: "Transportation/Logistics Company"
        },
        {
            name: "Energy/Utilities Company"
        },
        {
            name: "Hospitality/Hotel"
        },
        {
            name: "Non-Profit Organization"
        },
        {
            name: "Government Contractor"
        },
        {
            name: "Construction Company"
        },
        {
            name: "Legal Services"
        },
        {
            name: "Consulting Firm"
        },
        {
            name: "Other (please specify)"
        }

    ]
    const communication = [
        {
            name: "Email"
        },
        {
            name: "Phone"
        },
        {
            name: "In-Person Meeting"
        }
    ]
    const partnershipReason = [
        {
            name: "Enhance Workplace Safety"
        },
        {
            name: "Ensure Continuity of Operations"
        },
        {
            name: "Facilitate Disaster Recovery"
        },
        {
            name: "Improve Employee Preparedness"
        },
        {
            name: "Just Inquiring for now"
        },
        {
            name: "Other (please specify)"
        }
    ]
    const PartnershipDesired = [
        {
            name: "Full Integration with MYREDFOLDER®"
        },
        {
            name: "Access to MYREDFOLDER®  Resources and Protocols"
        },
        {
            name: "Customized Emergency Plan Development"
        },
        {
            name: "Training and Workshops"
        },
        {
            name: "Other (please specify)"
        }

    ]




    const [contactPersonError, setcontactPersonError] = useState("");

    const [numError, setNumError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [memberCountError, setMemberCountError] = useState("");
    const [industryError, setIndustryError] = useState("");
    const [partnershipLevelError, setPartnershipLevelError] = useState("");

    const [nameError, setnameError] = useState("")
    const [typeError, settypeError] = useState("")

    const [titleError, settitleError] = useState("")
    const [communicationMethodError, setcommunicationMethodError] = useState("")
    const [howDidYouHearError, sethowDidYouHearError] = useState("")
    const [contactError, setContactError] = useState("")
    const [consentContactFlag, setConsentContactFlag] = useState(false);
    const [consentContactFlagError, setConsentContactFlagError] = useState(false);
    // const [errorState, setErrorState ] = useState(false);

    const [otherType, setOtherType] = useState("");
    const [otherTypeValue, setOtherTypeValue] = useState("");
    const [showType, setShowType] = useState(false);
    const [otherTypeError, setOtherTypeError] = useState("");

    const [otherPartnership, setOtherPartnership] = useState("");
    const [partnershipLevelValue, setPartnershipLevelValue] = useState("");
    const [showPartnershipLevel, setShowPartnershipLevel] = useState(false);
    const [otherPartnershipLevelError, setOtherPartnershipLevelError] = useState("");


    const [otherPartnershipReason, setOtherPartnershipReason] = useState("");
    const [partnershipReasonValue, setPartnershipReasonLevelValue] = useState("");
    const [showPartnershipReason, setShowPartnershipReason] = useState(false);
    const [otherPartnershipReasonError, setOtherPartnershipReasonError] = useState("");


    const [formData, setFormData] = useState({
        name: "",
        type: "",
        contactPerson: "",
        title: "",
        contactEmail: '',
        contactPhone: "",


        memberCount: "",
        industry: "",

        partnershipReason: "",
        currentInitiatives: "",
        partnershipLevel: "",
        challenges: "",
        communicationMethod: "",
        howDidYouHear: "",
        isConsentContactFlag: "",
        subject: "test subject",
        message: "test msg",
        emailId: "umas@kyyba.com"

    });
    const validateName = (name) => {
        const nameRegex = /^[a-zA-Z\s']+$/;
        return nameRegex.test(name);
    };

    const validateNum = (num) => {
        const phonePattern = /^\d{10}$/;
        return phonePattern.test(num);
    };

    const validateNumber = (num) => {
        const numberPattern = /^-?\d+\.?\d*$/;
        return numberPattern.test(num);
    };

    const validateEmail = (email) => {
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(email);
    };


    const handleSubmit = (event) => {
        event.preventDefault();



        const isNumValid = validateNum(formData.contactPhone);
        const isEmailValid = validateEmail(formData.contactEmail);

        if (formData.name === "" || formData.type === "" || otherTypeValue === "" || formData.contactPerson === "" ||
            formData.memberCount === "" || formData.industry === "" || formData.title === "" ||
            formData.partnershipLevel === "" || partnershipLevelValue === "" || formData.contactPhone === "" ||
            formData.contactEmail === "" || formData.communicationMethod === "" || formData.howDidYouHear === "" ||
            !consentContactFlag) {
            if (formData.name === "") {
                setnameError("This is required");

            }
            if (formData.type === "") {
                settypeError("This is required");

            }
            if (otherTypeValue === "") {
                if (showType) {
                    setOtherTypeError('This is required');
                }
            }
            if (formData.contactPerson === "") {
                setcontactPersonError("This is required");

            }
            if (formData.memberCount === "") {
                setMemberCountError("This is required");

            }
            if (formData.industry === "") {
                setIndustryError("This is required");

            }
            if (formData.title === "") {
                settitleError("This is required");

            }
            if (formData.partnershipLevel === "") {
                setPartnershipLevelError("This is required");

            }
            if (partnershipLevelValue === "") {
                if (showPartnershipLevel) {
                    setOtherPartnershipLevelError('This is required');
                }
            }
            if (formData.contactPhone === "") {
                setContactError("This is required");


            } else if (!isNumValid) {
                setContactError("Please enter valid Phone Number");

            }
            if (formData.contactEmail === "") {
                setEmailError("This is required");

            }
            else if (!isEmailValid) {
                setEmailError("Please enter Valid Email ID");

            }
            if (formData.communicationMethod === "") {
                setcommunicationMethodError("This is required");

            }
            if (formData.howDidYouHear === "") {
                sethowDidYouHearError("This is required");

            }
            if (!consentContactFlag) {
                setConsentContactFlagError("This is required");
            }
            return;
        }


        // if(formData.name === ""){
        //     setnameError("This is required");
        // }
        // else if (!isname) {
        //     setnameError('Please enter a valid name.');
        //     return;
        // }
        if (formData.name === "") {
            setnameError("This is required");
            return;
        }
        if (formData.type === "") {

            settypeError("This is required");
            return;
        }
        if (otherTypeValue === "") {
            if (showType) {
                setOtherTypeError('This is required');
                return;
            }
        }
        if (formData.contactPerson === "") {
            setcontactPersonError("This is required");
            return;
        }
        if (formData.memberCount === "") {
            setMemberCountError("This is required");
            return;
        }
        if (formData.industry === "") {
            setIndustryError("This is required");
            return;
        }
        if (formData.title === "") {
            settitleError("This is required");
            return;
        }
        if (formData.partnershipLevel === "") {
            setPartnershipLevelError("This is required");
            return;
        }
        if (partnershipLevelValue === "") {
            if (showPartnershipLevel) {
                setOtherPartnershipLevelError('This is required');
                return;
            }
        }
        if (formData.contactPhone === "") {
            setContactError("This is required");
            return;

        } else if (!isNumValid) {
            setContactError("Please enter valid Phone Number");

        }
        if (formData.contactEmail === "") {
            setEmailError("This is required");
            return;
        }
        else if (!isEmailValid) {
            setEmailError("Please enter Valid Email ID");

        }
        if (formData.communicationMethod === "") {
            setcommunicationMethodError("This is required");
            return;
        }
        if (formData.howDidYouHear === "") {
            sethowDidYouHearError("This is required");
            return;
        }
        if (!consentContactFlag) {
            setConsentContactFlagError("This is required");
            return;
        }

        // if(showType){
        //     formData.type = otherTypeValue
        // }
        // if(showPartnershipLevel){
        //     formData.partnershipLevel = otherPartnership
        // }
        // if(showPartnershipReason){
        //     formData.partnershipReason = otherPartnershipReason
        // }

        // else if (!istitleValid) {
        //     settitleError('Please enter a valid name.');
        //     return;
        // }

        // else if (!isNameValid) {
        //     setcontactPersonError('Please enter a valid name.');
        //     return;
        // }
        // else if(!isNumberValid){
        //     setMemberCountError('please enter a valid number');
        //     return;
        // }
        // else if(!isIndustry){
        //     setIndustryError('please enter a valid name');
        //     return;
        // }
        // else if(!isPartnershipLevel){
        //     setPartnershipLevelError('please enter a valid partnership level');
        //     return;
        // }


        // else if (!isEmailValid) {
        //     setEmailError('Please enter a valid email address.');
        //     return;
        // }
        // else if (!isNumValid) {
        //     setNumError('Please enter a valid Number (10 digits).');
        //     return;
        // }

        // else if (!ishowDidYouHear) {
        //     sethowDidYouHearError('Please enter a valid name.');
        //     return;
        // }


        businessform(formData)
            .then((apiData) => {
                if (apiData && apiData.status === 200) {
                    setSuccessMessage("Thank you for taking the first step towards enhancing emergency preparedness in your community. Our team at MYREDFOLDER® looks forward to connecting with you soon.")
                    setTimeout(() => {
                        props.close();
                        setSuccessMessage("")
                        props.onClose();
                        // event.target.reset();
                        // props.close();
                    }, 4000);

                }
                if (apiData && apiData.status !== 200) {
                    setSuccessMessage('')
                    setErrorMessage(apiData.message);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });


        // setSuccessMessage("Thank you for taking the first step towards enhancing emergency preparedness for your organization. Our team at MYREDFOLDER® looks forward to connecting with you soon")
        // setTimeout(() => {
        //     props.close();
        //     setSuccessMessage("")
        //     props.onClose();
        //     // props.close();
        // }, 5000);        


        console.log(consentContactFlag, "11111111111");
        console.log(formData, "Business form data");

        setTimeout(() => {
            event.target.reset();
        }, 1000);


    };

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === 'name') {
            setnameError('')
        }

        else if (name === 'type') {
            settypeError('')
            if (otherType === "") {
                setOtherTypeError('')
            }
            setOtherType(event.target.value);
            setShowType(event.target.value === "Other (please specify)");
        }

        else if (name === "contactPerson") {
            setcontactPersonError("")
        }
        else if (name === "title") {
            settitleError("")
        }
        else if (name === 'contactPhone') {
            setContactError('')
        }
        else if (name === 'memberCount') {
            setMemberCountError('');
        }
        else if (name === 'industry') {
            setIndustryError('')
        }
        else if (name === "partnershipReason") {
            setOtherPartnershipReason(event.target.value);
            setShowPartnershipReason(event.target.value === "Other (please specify)")
        }

        else if (name === 'partnershipLevel') {
            setPartnershipLevelError("")
            if (otherPartnership === "") {
                setOtherPartnershipLevelError('')
            }
            setOtherPartnership(event.target.value);
            setShowPartnershipLevel(event.target.value === "Other (please specify)");
        }

        else if (name === 'contactEmail') {
            setEmailError('')
        }
        else if (name === "communicationMethod") {
            setcommunicationMethodError("")
        }
        else if (name === "howDidYouHear") {
            sethowDidYouHearError("")
        }
        else if (name === "isConsentContactFlag") {

            setConsentContactFlag(!consentContactFlag)
            setConsentContactFlagError("")
        }
        else if (showType || showPartnershipLevel) {
            if (showType) {
                setOtherTypeError('')
            }
            if (showPartnershipLevel) {
                setOtherPartnershipLevelError('')
            }
        }
        // if (value === 'Other (please specify)') {
        //     setOpenPopup(true);
        //   }


        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            isConsentContactFlag: prevFormData.isConsentContactFlag === "on" ? "true" : "false",
        }));


        console.log(formData.isConsentContactFlag, "11111111111");
    };

    const style = {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 605,
        height: 630,
        bgcolor: 'background.paper',
        border: "none",
        borderRadius: "5px",
        p: 2,
    };
    const styleone = {
        // title: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,

        bgcolor: 'background.paper',
        border: "none",
        borderRadius: "5px",
        p: 1,
    };
    return <>
        <Modal
            open={props.onOpen} onClose={false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        // style={{ zIndex: 10000 }}
        >

            <Box >
                {successMessage ? (
                    <Box className="home-goverment-form-alert-popup">
                        <Alert variant="filled" severity="success">
                            <Typography className='primary-font-normal-7'> {successMessage}</Typography>
                        </Alert>
                    </Box>
                ) :
                    <Box className="Home home-govermrnt-form-popup">
                        <Box>
                            <Box>
                                <div style={{ float: "right", cursor: "pointer" }} onClick={props.onClose}>
                                    <img
                                        src={close}
                                        height={20}
                                        width={20}
                                        alt="close"

                                    />
                                </div>
                                <Box >
                                    <Typography sx={{ textAlign: "left", ml: "2% !important" }} className="primary-font-bold14"> Partnership Inquiry Form</Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ height: "2px", mt: "10px" }} />

                        </Box>


                        <Box className='laptop-home-popup-content'>
                            <form onSubmit={handleSubmit}>
                                <Scrollbars style={{ height: "410px" }}>
                                    <Typography className='primary-font-normal-7' sx={{ paddingY: "20px", ml: "2%" }}> Thank you for expressing interest in
                                        partnering with MYREDFOLDER® to enhance emergency preparedness for your organization.
                                        Please complete the form below, and our team will get in touch with you promptly.</Typography>

                                    <Box>

                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: '5px' }}> Contact Information</Typography>

                                            <Typography className='font-normal-small error-message'>{nameError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Name of Organization<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={
                                                    handleChange}
                                                // handleChange();
                                                // setOtherType(e.target.value);
                                                // setShowType(e.target.value === "Other (please specify)")
                                                // setDesignation1(e.target.value);
                                                // setShowOption1(e.target.value === "Others");

                                                // placeholder="(Required Field)"
                                                className={`formStyles ${nameError !== '' ? 'err-input' : ''}`}
                                            // onChange={}
                                            />


                                            <br />
                                            <div>
                                                <Typography className='font-normal-small error-message'>{typeError}</Typography>
                                                <label className='primary-font-normal-7' htmlFor="type" style={{ marginLeft: "2%" }}> Type of Organization <span style={{ color: "red" }}>{" *"}</span></label>
                                                <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, width: '100%' }}>
                                                    <Select

                                                        id="type"
                                                        name="type"
                                                        // sx={{border:"0px solid #d8d8d8 "}}
                                                        className={` drop-down-menu  formStyles ${typeError !== '' ? 'err-input' : ''}`}
                                                        value={showType ? "Other (please specify)" : formData.type}
                                                        onChange={
                                                            handleChange
                                                        }
                                                       
                                                        
                                                        MenuProps={{

                                                            sx:{
                                                                height:'280px !important'
                                                            }
                                                        }}
                                                       
                                                    >
                                                        {/* <MenuItem value="" disabled hidden></MenuItem> */}
                                                        {organizationTypes?.map((key) => (
                                                            <MenuItem className="option-select" key={key.id} value={key.name}>
                                                                {key.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>
                                            </div>
                                            {showType && (

                                                <div className="field">
                                                    <Typography className='font-normal-small error-message'>{otherTypeError}</Typography>
                                                    <label className='primary-font-normal-7' htmlFor="type" style={{ marginLeft: "2%" }}> Other Organization <span style={{ color: "red" }}>{" *"}</span></label>
                                                    <Input
                                                        type="type"
                                                        name="otherType"
                                                        className={`formStyles ${otherTypeError !== '' ? 'err-input' : ''}`}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setOtherTypeValue(e.target.value);
                                                            setOtherType(e.target.value);
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )}


                                            <Typography className='font-normal-small error-message'>{contactPersonError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Contact Person<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="contactPerson"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${contactPersonError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />
                                            <Typography className='font-normal-small error-message'>{titleError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Position/Title<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="title"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${titleError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />



                                            <div>
                                                <Typography className='font-normal-small error-message'>{emailError}</Typography>
                                                <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                    Contact Email<span className="required-asterisk">*</span>
                                                </label>
                                                <Input
                                                    type="text"
                                                    name="contactEmail"
                                                    // placeholder="(Required Field)"
                                                    className={`formStyles ${emailError !== '' ? 'err-input' : ''}`}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <Typography className='font-normal-small error-message'>{contactError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Contact Phone<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                maxLength={10}
                                                name="contactPhone"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${contactError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />

                                        </Box>


                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: '5px' }}> Organization Information</Typography>

                                            <Typography className='font-normal-small error-message'>{memberCountError}</Typography>

                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Number of Employees/Members <span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="memberCount"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${memberCountError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />
                                            <Typography className='font-normal-small error-message'>{industryError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Industry/Field <span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="industry"
                                                className={`formStyles ${industryError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />

                                        </Box>

                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: '5px' }}>Emergency Preparedness Goals </Typography>

                                            <div>

                                                <label className='primary-font-normal-7' htmlFor="partnershipReason" style={{ marginLeft: "2%" }}>Reason for Seeking Partnership  </label>
                                                <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, width: '100%' }}>
                                                    <Select
                                                        id="partnershipReason"
                                                        name="partnershipReason"
                                                        // sx={{border:"0px solid #d8d8d8 !important"}}
                                                        className=" drop-down-menu formStyles"
                                                        value={formData.partnershipReason}
                                                        onChange={handleChange}

                                                    >
                                                        {/* <MenuItem value="" disabled hidden></MenuItem> */}
                                                        {partnershipReason?.map((key) => (
                                                            <MenuItem className="option-select" key={key.id} value={key.name}>
                                                                {key.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {
                                                showPartnershipReason && (<div>

                                                    <label className='primary-font-normal-7' htmlFor="type" style={{ marginLeft: "2%" }}> Other Reason for Seeking Partnership</label>
                                                    <Input
                                                        type="partnershipReason"
                                                        name="otherPartnershipReason"
                                                        className="formStyles"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setPartnershipReasonLevelValue(e.target.value);
                                                            setOtherPartnershipReason(e.target.value);
                                                        }
                                                        }
                                                    />

                                                </div>)
                                            }

                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}> Current Emergency Preparedness Initiatives

                                            </label>
                                            <TextField
                                                type="text"
                                                name="currentInitiatives"
                                                sx={{
                                                    p: "0px !important",
                                                    height: "100px", justifyContent: "space-evenly",
                                                    border: "1px solid #d8d8d8"
                                                }}
                                                // placeholder="(Required Field)"
                                                multiline
                                                rows={3}
                                                className="formStyles"

                                                onChange={handleChange}
                                            />

                                            <div>
                                                <Typography className='font-normal-small error-message'>{partnershipLevelError}</Typography>
                                                <label className='primary-font-normal-7' htmlFor="partnershipLevel" style={{ marginLeft: "2%" }}> Desired Level of Partnership
                                                    <span className="required-asterisk">*</span></label>
                                                <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, width: '100%' }}>
                                                    <Select
                                                        id="partnershipLevel"
                                                        name="partnershipLevel"

                                                        // sx={{border:"0px solid #d8d8d8 !important"}}
                                                        value={showPartnershipLevel ? "Other (please specify)" : formData.partnershipLevel}
                                                        onChange={handleChange}
                                                        className={`drop-down-menu formStyles ${partnershipLevelError !== '' ? 'err-input' : ''}`}

                                                    >
                                                        {/* <MenuItem value="" disabled hidden></MenuItem> */}
                                                        {PartnershipDesired?.map((key) => (
                                                            <MenuItem className="option-select" key={key.id} value={key.name}>
                                                                {key.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {
                                                showPartnershipLevel && (<div>
                                                    <Typography className='font-normal-small error-message'>{otherPartnershipLevelError}</Typography>
                                                    <label className='primary-font-normal-7' htmlFor="type" style={{ marginLeft: "2%" }}> Other Desired Level of Partnership <span style={{ color: "red" }}>{" *"}</span></label>
                                                    <Input
                                                        type="type"
                                                        name="otherPartnershipLevel"
                                                        className={`formStyles ${otherPartnershipLevelError !== '' ? 'err-input' : ''}`}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setPartnershipLevelValue(e.target.value);
                                                            setOtherPartnership(e.target.value);
                                                        }
                                                        }
                                                    />

                                                </div>)
                                            }
                                        </Box>


                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: '5px' }}>Additional Information </Typography>

                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Any Specific Challenges or Concerns
                                            </label>
                                            <TextField
                                                type="text"
                                                name="challenges"
                                                sx={{
                                                    p: "0px !important",
                                                    height: "100px", justifyContent: "space-evenly",
                                                    border: "1px solid #d8d8d8"
                                                }}
                                                // placeholder="(Required Field)"
                                                multiline
                                                rows={3}
                                                className="formStyles"

                                                onChange={handleChange}
                                            />
                                            <div>
                                                <Typography className='font-normal-small error-message'>{communicationMethodError}</Typography>
                                                <label className='primary-font-normal-7' htmlFor="communicationMethod" style={{ marginLeft: "2%" }}>  Preferred Method of Communication<span className="required-asterisk">*</span></label>
                                                <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, width: '100%' }}>
                                                    <Select
                                                        id="communicationMethod"
                                                        name="communicationMethod"
                                                        // sx={{border:"0px solid #d8d8d8 !important"}}
                                                        className={`drop-down-menu formStyles ${communicationMethodError !== '' ? 'err-input' : ''}`}
                                                        value={formData.communicationMethod}
                                                        onChange={handleChange}

                                                    >
                                                        {/* <MenuItem value="" disabled hidden></MenuItem> */}
                                                        {communication?.map((key) => (
                                                            <MenuItem className="option-select" key={key.id} value={key.name}>
                                                                {key.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <Typography className='font-normal-small error-message'>{howDidYouHearError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}> How Did You Hear About MYREDFOLDER®
                                                <span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="howDidYouHear"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${howDidYouHearError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: '5px' }}>Submission </Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Consent to Contact<span className="required-asterisk">*</span>
                                            </label>
                                            <Typography className='font-normal-small error-message'> {consentContactFlagError}</Typography>
                                            <Box sx={{ display: "flex", flexDirection: "row", ml: "2%" }}>

                                                <Checkbox
                                                    name="isConsentContactFlag"
                                                    checked={consentContactFlag}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "red !important",
                                                        mt: "-25px",
                                                        ml: "-10px"
                                                    }}
                                                />

                                                <Typography className='primary-font-normal-7' >I consent to being contacted by MYREDFOLDER®/NEPTA regarding my inquiry.</Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Scrollbars>
                                <Divider sx={{ height: "2px", mb: "15px" }} />

                                <Box sx={{ display: "flex", justifyContent: "center", mr: "30px" }}>

                                    <Button variant="outlined" onClick={props.onClose} sx={{
                                        mr: "40px",
                                        width: '150px',
                                        height: '50px'
                                    }} className='pricing-btn' type="submit">
                                        Cancel
                                    </Button>

                                    <Button variant="outlined" style={{
                                        width: '150px',
                                        height: '50px'
                                    }} className='pricing-btn' type="submit">
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                        <Box className='mobile-home-popup-content'>
                            <form onSubmit={handleSubmit}>
                                <Scrollbars style={{ height: "300px", overflowY: "visible" }}>
                                    <Typography className='primary-font-normal-7' sx={{ paddingY: "20px", ml: "2%" }}> Thank you for expressing interest in
                                        partnering with MYREDFOLDER® to enhance emergency preparedness for your organization.
                                        Please complete the form below, and our team will get in touch with you promptly.</Typography>

                                    <Box>


                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: "0px" }}> Contact Information</Typography>

                                            <Typography className='font-normal-small error-message'>{nameError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Name of Organization<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={
                                                    handleChange}
                                                // handleChange();
                                                // setOtherType(e.target.value);
                                                // setShowType(e.target.value === "Other (please specify)")
                                                // setDesignation1(e.target.value);
                                                // setShowOption1(e.target.value === "Others");

                                                // placeholder="(Required Field)"
                                                className={`formStyles ${nameError !== '' ? 'err-input' : ''}`}
                                            // onChange={}
                                            />


                                            <br />
                                            <div>
                                                <Typography className='font-normal-small error-message'>{typeError}</Typography>
                                                <label className='primary-font-normal-7' htmlFor="type" style={{ marginLeft: "2%" }}> Type of Organization <span style={{ color: "red" }}>{" *"}</span></label>

                                                <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, width: '100%' }}>
                                                    <Select
                                                        id="type"
                                                        name="type"
                                                        // sx={{border:"0px solid #d8d8d8"}}
                                                        // sx={{
                                                        //     '&:focus': {
                                                        //         borderColor: '#d8d8d8',
                                                        //         boxShadow :'0px'
                                                        //     }
                                                        // }}
                                                        className={`drop-down-menu formStyles ${typeError !== '' ? 'err-input' : ''}`}
                                                        value={showType ? "Other (please specify)" : formData.type}
                                                        onChange={
                                                            handleChange
                                                        }
                                                        MenuProps={{
                                                            sx: {
                                                                height: '200px !important'
                                                            }
                                                        }}
                                                    >
                                                        {/* <MenuItem value="" disabled hidden></MenuItem> */}
                                                        {organizationTypes?.map((key) => (
                                                            <MenuItem className="option-select" key={key.id} value={key.name}>
                                                                {key.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {showType && (

                                                <div className="field">
                                                    <Typography className='font-normal-small error-message'>{otherTypeError}</Typography>
                                                    <label className='primary-font-normal-7' htmlFor="type" style={{ marginLeft: "2%" }}> Other Organization <span style={{ color: "red" }}>{" *"}</span></label>
                                                    <Input
                                                        type="type"
                                                        name="otherType"
                                                        className={`formStyles ${otherTypeError !== '' ? 'err-input' : ''}`}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setOtherTypeValue(e.target.value);
                                                            setOtherType(e.target.value);
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )}


                                            <Typography className='font-normal-small error-message'>{contactPersonError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Contact Person<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="contactPerson"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${contactPersonError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />
                                            <Typography className='font-normal-small error-message'>{titleError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Position/Title<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="title"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${titleError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />



                                            <div>
                                                <Typography className='font-normal-small error-message'>{emailError}</Typography>
                                                <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                    Contact Email<span className="required-asterisk">*</span>
                                                </label>
                                                <Input
                                                    type="text"
                                                    name="contactEmail"
                                                    // placeholder="(Required Field)"
                                                    className={`formStyles ${emailError !== '' ? 'err-input' : ''}`}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <Typography className='font-normal-small error-message'>{contactError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Contact Phone<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                maxLength={10}
                                                name="contactPhone"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${contactError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />

                                        </Box>


                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: "0px" }}> Organization Information</Typography>

                                            <Typography className='font-normal-small error-message'>{memberCountError}</Typography>

                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Number of Employees/Members <span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="memberCount"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${memberCountError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />
                                            <Typography className='font-normal-small error-message'>{industryError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Industry/Field <span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="industry"
                                                className={`formStyles ${industryError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />

                                        </Box>

                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: "0px" }}>Emergency Preparedness Goals </Typography>

                                            <div>

                                                <label className='primary-font-normal-7' htmlFor="partnershipReason" style={{ marginLeft: "2%" }}>Reason for Seeking Partnership  </label>
                                                <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, width: '100%' }}>
                                                    <Select
                                                        id="partnershipReason"
                                                        name="partnershipReason"
                                                        // sx={{border:"0px solid #d8d8d8 !important"}}
                                                        className=" drop-down-menu formStyles"
                                                        value={formData.partnershipReason}
                                                        onChange={handleChange}

                                                    >
                                                        {/* <MenuItem value="" disabled hidden></MenuItem> */}
                                                        {partnershipReason?.map((key) => (
                                                            <MenuItem className="option-select" key={key.id} value={key.name}>
                                                                {key.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {
                                                showPartnershipReason && (<div>

                                                    <label className='primary-font-normal-7' htmlFor="type" style={{ marginLeft: "2%" }}> Other Reason for Seeking Partnership</label>
                                                    <Input
                                                        type="partnershipReason"
                                                        name="otherPartnershipReason"
                                                        className="formStyles"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setPartnershipReasonLevelValue(e.target.value);
                                                            setOtherPartnershipReason(e.target.value);
                                                        }
                                                        }
                                                    />

                                                </div>)
                                            }

                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}> Current Emergency Preparedness Initiatives

                                            </label>
                                            <TextField
                                                type="text"
                                                name="currentInitiatives"
                                                sx={{
                                                    p: "0px !important",
                                                    height: "100px", justifyContent: "space-evenly",
                                                    border: "1px solid #d8d8d8"
                                                }}
                                                // placeholder="(Required Field)"
                                                multiline
                                                rows={3}
                                                className="formStyles"

                                                onChange={handleChange}
                                            />

                                            <div>
                                                <Typography className='font-normal-small error-message'>{partnershipLevelError}</Typography>
                                                <label className='primary-font-normal-7' htmlFor="partnershipLevel" style={{ marginLeft: "2%" }}> Desired Level of Partnership
                                                    <span className="required-asterisk">*</span></label>
                                                <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, width: '100%' }}>
                                                    <Select
                                                        id="partnershipLevel"
                                                        name="partnershipLevel"
                                                        // sx={{border:"0px solid #d8d8d8"}}

                                                        value={showPartnershipLevel ? "Other (please specify)" : formData.partnershipLevel}
                                                        onChange={handleChange}
                                                        className={` drop-down-menu formStyles ${partnershipLevelError !== '' ? 'err-input' : ''}`}

                                                    >
                                                        {/* <MenuItem value="" disabled hidden></MenuItem> */}
                                                        {PartnershipDesired?.map((key) => (
                                                            <MenuItem className="option-select" key={key.id} value={key.name}>
                                                                {key.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {
                                                showPartnershipLevel && (<div>
                                                    <Typography className='font-normal-small error-message'>{otherPartnershipLevelError}</Typography>
                                                    <label className='primary-font-normal-7' htmlFor="type" style={{ marginLeft: "2%" }}> Other Desired Level of Partnership <span style={{ color: "red" }}>{" *"}</span></label>
                                                    <Input
                                                        type="type"
                                                        name="otherPartnershipLevel"
                                                        className={`formStyles ${otherPartnershipLevelError !== '' ? 'err-input' : ''}`}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setPartnershipLevelValue(e.target.value);
                                                            setOtherPartnership(e.target.value);
                                                        }
                                                        }
                                                    />

                                                </div>)
                                            }
                                        </Box>


                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: "0px" }}>Additional Information </Typography>

                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Any Specific Challenges or Concerns
                                            </label>
                                            <TextField
                                                type="text"
                                                name="challenges"
                                                sx={{
                                                    p: "0px !important", width: "95% !important",
                                                    height: "100px", justifyContent: "space-evenly",
                                                    border: "1px solid #d8d8d8"
                                                }}
                                                // placeholder="(Required Field)"
                                                multiline
                                                rows={3}
                                                className="formStyles"

                                                onChange={handleChange}
                                            />
                                            <div>
                                                <Typography className='font-normal-small error-message'>{communicationMethodError}</Typography>
                                                <label className='primary-font-normal-7' htmlFor="communicationMethod" style={{ marginLeft: "2%" }}>  Preferred Method of Communication<span className="required-asterisk">*</span></label>
                                                <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, width: '100%' }}>
                                                    <Select
                                                        id="communicationMethod"
                                                        name="communicationMethod"
                                                        // sx={{border:"0px solid #d8d8d8 !important"}}
                                                        className={` drop-down-menu formStyles ${communicationMethodError !== '' ? 'err-input' : ''}`}
                                                        value={formData.communicationMethod}
                                                        onChange={handleChange}

                                                    >
                                                        {/* <MenuItem value="" disabled hidden></MenuItem> */}
                                                        {communication?.map((key) => (
                                                            <MenuItem className="option-select" key={key.id} value={key.name}>
                                                                {key.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <Typography className='font-normal-small error-message'>{howDidYouHearError}</Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}> How Did You Hear About MYREDFOLDER®
                                                <span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="howDidYouHear"
                                                // placeholder="(Required Field)"
                                                className={`formStyles ${howDidYouHearError !== '' ? 'err-input' : ''}`}
                                                onChange={handleChange}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography className="primary-font-bold4" sx={{ ml: "2%", mb: "0px" }}>Submission </Typography>
                                            <label className='primary-font-normal-7' style={{ marginLeft: "2%" }}>
                                                Consent to Contact<span className="required-asterisk">*</span>
                                            </label>
                                            <Typography className='font-normal-small error-message'> {consentContactFlagError}</Typography>
                                            <Box sx={{ display: "flex", flexDirection: "row", ml: "2%" }}>

                                                <Checkbox
                                                    name="isConsentContactFlag"
                                                    checked={consentContactFlag}
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: "red !important",
                                                        mt: "-20px",
                                                        ml: "-10px"
                                                    }}
                                                />

                                                <Typography className='primary-font-normal-7' >I consent to being contacted by MYREDFOLDER®/NEPTA regarding my inquiry.</Typography>
                                            </Box>
                                        </Box>


                                    </Box>
                                </Scrollbars>
                                <Divider sx={{ height: "2px", mb: "15px" }} />

                                <Box sx={{ display: "flex", justifyContent: "center", }}>

                                    <Button variant="outlined" onClick={props.onClose} sx={{
                                        mr: "40px",
                                        width: '130px',
                                        height: '40px'
                                    }} className='pricing-btn' type="submit">
                                        Cancel
                                    </Button>

                                    <Button variant="outlined" style={{
                                        width: '130px',
                                        height: '40px'
                                    }} className='pricing-btn' type="submit">
                                        Submit
                                    </Button>
                                </Box>

                            </form>
                        </Box>

                    </Box>}
            </Box>
        </Modal>
    </>
}

