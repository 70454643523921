import React from 'react'
import ContentWrapper from '../../../../common/components/ContentWrapper';
import Security from './Security';
export default function SecurityIndex() {
  return (
    <>
    <ContentWrapper contenview={<Security/>} />
    
</>
  )
}
