import React ,{useEffect} from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';

import HomeComponent from './HomeComponent';

const IndexComponent = () => {
  useEffect(() => {  localStorage.setItem("menuTitle","Home")}, []);
  return (
        <>
            <ContentWrapper contenview={<HomeComponent />} />
            
        </>
  )
}

export default IndexComponent;
