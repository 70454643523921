const constants = {
    BASE_URL: 'https://testapi.myredfolder.kyybaapps.com/myredfolder/api/',
    REDIRECTION_URL: 'https://www.myredfolder.kyybaapps.com/',

    //ENVIRONMENT: "LOCAL",
    //  ENVIRONMENT: "DEV",
    ENVIRONMENT: "STAGING",
    endPointUrl: {
        "LOCAL": "https://testapi.myredfolder.kyybaapps.com/myredfolder/api/",
        // "DEV":"https://testapi.myredfolder.kyybaapps.com/myredfolder/api/",
        "DEV": "https://developmentapi.myredfolder.kyybaapps.com/myredfolder/api/",
        // "STAGING":"https://www.staging.myredfolder.kyybaapps.com/myredfolder/api/",
        "STAGING": "https://stagingapi.myredfolder.kyybaapps.com/myredfolder/api/",


    }
}
export default constants;