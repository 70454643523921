import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Box, Grid } from '@mui/material';
import mission from "../../../../assets/aboutus-assets/aboutus-header-imges/our-values.png";
import "../styles/Aboutus.css";
import "../styles/Ourhistory.css";
import { data } from '../data/ValuesComponentData';
export default function OurValuesComponents() {
  return (
    <Grid container sx={{ marginBottom: "0px" }}>
      <Grid container sx={{ background: "transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%)" }} >
        <div className='ourhistory-header-container'>
          <Grid container item xs={12} md={12} lg={12} sx={{ my: "auto", justifyContent: "space-between", display: 'flex', flexDirection: "row" }}>
            <Grid item xs={6} md={6} lg={6} sx={{ my: "auto" }}>
              <Typography className='primary-font-bold7'>OUR VALUES & MISSION</Typography>

            </Grid>
            <Grid item xs={6} md={6} lg={6} className='ourhistory-header-img' >
              <img src={mission} alt="ourhistory" style={{ width: "100%", height: "100%" }} />
            </Grid>

          </Grid>
        </div>
      </Grid>
      <Grid container item xs={12} md={12} lg={12}>
        <div className='ourhistory-header-container'>
          <Grid container item xs={12} md={12} lg={12} className='ourhistory-mission-title-container'>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className='primary-font-bold-heading' sx={{ textAlign: "center", marginBottom: "30px" }}>Our Mission</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box>
                <Typography className='primary-font-normal-11' style={{ textAlign: "left", letterSpacing: '0.11px' }}>Together with our customers, we design immediate and long-term emergency and disaster preparedness/response strategies with the ultimate goal of keeping businesses operational and families together in the event of a disaster. We are dedicated to safeguarding the welfare of individuals and families, empowering them with the tools and resources needed to face adversity and rebuild their lives.</Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} sx={{ background: "#FAFAFB" }}>
        <div className='ourhistory-header-container'>
          <Grid item xs={12} md={12} lg={12}  className='ourhistory-values-title-container' >
            <Typography className='primary-font-bold-heading' sx={{ textAlign: "center" }}>
              Our Values
            </Typography>
          </Grid>
          <Grid container spacing={2} item xs={12} md={12} lg={12} >

            <Grid container spacing={5} item xs={12} md={12} lg={12} >
              {data.map((item, i) => (
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Item key={i} item={item} />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ marginY: "40px" }}>
              <Typography className='primary-font-normal-11' style={{ letterSpacing: '0.11px' }}> These values guide us in fulfilling our mission and drive our commitment to making a positive difference in the lives of individuals, families, and communities affected by disasters.</Typography>
            </Box>
          </Grid>
          </Grid>
        </div>
        
      </Grid>
    </Grid>
  )
}

function Item(props) {
  return (


    <Card sx={{ ":hover": { boxShadow: 4 } }}
 className='Ourvalues-middle-top-container'
    >
      <Box   className='Ourvalues-middle-values-img-container'>
        <CardMedia sx={{ height: "80px", width: "80px" }}
          component="img"
          image={props.item.image}
          alt="image"
        />
      </Box>
      <CardContent>
        <Typography className='primary-font-bold5' sx={{ textAlign: "center" }}>
          {props.item.title}
        </Typography>
        <Typography sx={{ color: "#000000", opacity: "60%", mt: "20px", px: "10px" }} className='primary-font-normal-19'>
          {props.item.content}
        </Typography>
      </CardContent>
    </Card>

  );
}

