import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../styles/knowledge.css"

export default function MRFTribe() {
    const Navigate = useNavigate();
    // const listStyle = {
    //     paddingLeft: "24px",

    //     marginTop: "10px"
    // };
    // const Uliststyle = {
    //     listStyleType: 'disc',
    //     marginBlockStart: '0px',
    //     marginBlockEnd: '0px',

    //     paddingLeft: "15px"
    // }
    const registeredTrademark = String.fromCharCode(174);
    return (
        <Grid container  >
            <Grid container item xs={12} md={12} sx={{  }} className='Tribe-header-img' >
               

                <Grid item xs={12} md={12} lg={12} sx={{ marginY: 'auto' }}>
                    <div className='knowledgeBase-header-container'>
                        <Typography className="primary-font-bold7" sx={{ textAlign: 'start',display:"flex" }}>
                        MYREDFOLDER <Typography className="primary-font-bold23">® </Typography> TRIBE
                        </Typography>
                    </div>
                </Grid>

            </Grid>

            <Grid container className='Tribe-container' >
                <div className='knowledgeBase-header-container'>
                    <Grid container item xs={12} md={12} lg={12}  >
                        <Box >


                            <Box>
                                <Typography className='primary-font-bold11 Tribe-top-container' > Join the MYREDFOLDER® Tribe and Empower Your Disaster Preparedness Journey!</Typography>
                                <Typography className='primary-font-normal-2 ' sx={{ marginTop: "10px" }}>Welcome to the MYREDFOLDER® Tribe, where preparedness meets community, and resilience takes root. By becoming a member of our tribe, you'll unlock a world of benefits and resources designed to keep you and your loved ones safe in times of crisis. Here's what you gain by joining: </Typography>
                            </Box>


                            <Box>



                                <Box >
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }}>Exclusive Blog Posts Delivered to Your Inbox:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'> Stay informed with the latest disaster preparedness insights, expert advice, and real-life stories shared by our community of members.</Typography>
                                    {/* </ul> */}
                                </Box>

                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} > Special Tips for Your Family's Preparedness:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'>Receive personalized tips and actionable steps tailored to your family's unique needs and location to enhance your preparedness.</Typography>
                                    {/* </ul> */}
                                </Box>

                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} >Discounts on Emergency Supplies:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'>Enjoy exclusive discounts and access to quality emergency supplies, making it easier and more affordable to build your emergency kit</Typography>
                                    {/* </ul> */}
                                </Box>
                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} >Educational Videos and Tutorials:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'> Access a library of educational videos covering a wide range of topics, from creating a family emergency plan to essential first-aid skills.</Typography>
                                    {/* </ul> */}
                                </Box>
                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} >Submit Your Own Blogs:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'>Showcase your expertise and experiences by submitting your own preparedness blogs. Your content may be featured on our site, sharing your knowledge with the MYREDFOLDER® community.</Typography>
                                    {/* </ul> */}
                                </Box>
                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} > Community Support:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'> Connect with like-minded individuals who share your commitment to preparedness. Share stories, ask questions, and support one another on your preparedness journeys.</Typography>
                                    {/* </ul> */}
                                </Box>
                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} >Early Access to New Features:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'> Be the first to experience and provide feedback on new MYREDFOLDER® features and updates, helping us improve the platform for everyone.</Typography>
                                    {/* </ul> */}
                                </Box>
                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} >Monthly Giveaways and Contests:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'>Participate in exclusive giveaways and contests for a chance to win valuable preparedness resources and prizes.</Typography>
                                    {/* </ul> */}
                                </Box>
                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} > Personalized Alerts and Notifications:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'> Stay informed about critical updates, weather alerts, and local emergency information relevant to your area.</Typography>
                                    {/* </ul> */}
                                </Box>
                                <Box>
                                    <Typography className='primary-font-bold11 ' sx={{ marginTop: "15px" }} >Member-Only Webinars and Workshops:</Typography>
                                    {/* <ul style={Uliststyle}> */}
                                        <Typography className='primary-font-normal-2 Tribe-para'>Gain access to interactive webinars and workshops conducted by disaster preparedness experts, expanding your knowledge and skills.</Typography>
                                    {/* </ul> */}
                                </Box>
                            </Box>
                            <Box className="Tribe-end-container" >
                                <Typography className='primary-font-normal-2 ' sx={{ marginTop: "10px" }}> Joining the MYREDFOLDER® Tribe is your gateway to a supportive community, valuable resources, and a more prepared future. Don't miss out on this opportunity to strengthen your family's resilience and connect with others who share your passion for preparedness. </Typography>
                                <Typography className='primary-font-normal-2 ' sx={{ marginTop: "10px" }}> Sign up today at no cost and take the first step towards a safer, more secure tomorrow.</Typography>

                            </Box>

                        </Box>

                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}
