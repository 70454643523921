import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';
import SupplyKits from './SupplyKits';
import MyRedFolder from './MYREDFOLDER';
import MyRedFolderPlan from "./MyRedFolderPlan"
import RiskBasedOnMyLocation from './RiskBasedOnMyLocation';
import SaftyOfFamilyMembers from './SaftyOfFamilyMembers';
import LossOfProperty from './LossOfProperty';
import LossOfSupply from './LossOfSupply';

const OurServicesPageComponent = ({page=null}) => {
    let content= null;
    if(page === 'supply-kits'){
        content = <SupplyKits />
    } else if(page === 'my-mrf-app'){
        content = <MyRedFolder />
    } else if(page ==='my-mrf-plan'){
        content = <MyRedFolderPlan />
    } 
    // else if(page ==='safty'){
    //     content = <SaftyOfFamilyMembers />
    // } 
    // else if(page ==='loss-of-property'){
    //     content = <LossOfProperty />
    // } 
    // else if(page ==='loss-of-supply'){
    //     content = <LossOfSupply />
    // } 
  return (
        <>
            <ContentWrapper contenview={content} />

        </>
  )
}

export default OurServicesPageComponent;