import React, { useEffect, useState } from "react";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import InitializeModal from "../../containers/Pages/Home/components/InitializeModal";
import { useLocation } from "react-router-dom";

const { innerHeight: height } = window;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const ContentWrapper = (props) => {
  const { contenview } = props;
  const [open, setOpen]=useState(false)
  const location = useLocation();
  const [isWalmart, setIsWalmart] = useState(location.pathname === "/walmart");

  const offervalue = localStorage.getItem("offerpopup");
  useEffect(()=>{
  setTimeout(()=>{
    if(offervalue==="true"){
      setOpen(true);
    }
  }, isWalmart ? 100 : 30000)
  },[offervalue])
  const handleClose = () => setOpen(false);
  
  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <Header />
      </ElevationScroll>
      <InitializeModal open={open} onClose={handleClose}/>
      <div style={{ backgroundColor: "white", minHeight: height - 250 + "px" }}>
        {contenview}
      </div>
      <Footer />
    </>
  );
};

export default ContentWrapper;
